import React, { useEffect, useState } from 'react'
import AnimationRevealPage from 'helpers/AnimationRevealPage.js'
import { Container, ContentWithPaddingXl } from 'components/misc/Layouts'
import tw from 'twin.macro'
import styled from 'styled-components'
import { css } from 'styled-components/macro'
import Footer from 'components/footers/MiniCenteredFooter.js'
import { SectionHeading } from 'components/misc/Headings'
import { PrimaryButton } from 'components/misc/Buttons'
import Header, { NavLink, NavLinks, PrimaryLink as PrimaryLinkBase, LogoLink, NavToggle, DesktopNavLinks } from "../../../components/headers/light.js";
import { useParams } from 'react-router-dom';
import icon from "../../../images/lra.svg"
import supabase from "helpers/SupabaseClient.js";
import SpecialEdu from "components/cards/Videos.js";
import MaterialList from 'components/cards/MaterialList.js'

const HeadingRow = tw.div`flex justify-between items-center sm:flex-col md:flex-row`;
const Heading = tw.h2`text-2xl sm:text-4xl font-black tracking-wide text-center text-gray-900`;
const Posts = tw.div`mt-6 sm:-mr-8 flex flex-wrap justify-center`
const PostContainer = styled.div`
  ${tw`mt-2 w-3/4 sm:w-1/2 lg:w-1/3 sm:pr-8`}
  ${(props) =>
    props.featured &&
    css`
      ${tw`w-full!`}
      ${Post} {
        ${tw`sm:flex-row! h-full sm:pr-4`}
      }
      ${Image} {
        ${tw`sm:h-96 sm:min-h-full sm:w-1/2 lg:w-2/3 sm:rounded-t-none sm:rounded-l-lg`}
      }
      ${Info} {
        ${tw`sm:-mr-4 sm:pl-8 sm:flex-1 sm:rounded-none sm:rounded-r-lg sm:border-t-2 sm:border-l-2`}
      }
      ${Description} {
        ${tw`text-sm mt-3 leading-loose text-gray-600 font-medium`}
      }
    `}
`
const Post = tw.div`cursor-pointer flex flex-col bg-gray-100`
const Image = styled.div`
  ${(props) =>
    css`
      background-image: url('${props.imageSrc}');
    `}
  ${tw`h-64 w-full bg-cover bg-center rounded-t-lg`}
`
const Info = tw.div`p-2 border-2 rounded-t-lg rounded-l-lg`
const Category = tw.div`uppercase text-primary-500 text-xs font-bold tracking-widest leading-loose after:content after:block after:border-b-2 after:border-primary-500 after:w-8`
const CreationDate = tw.div`mt-4 uppercase text-gray-600 italic font-semibold text-xs`
const Title = tw.div`mt-1 font-bold text-xl text-gray-900 group-hover:text-primary-500 transition duration-300`
const Description = tw.div``

const PaginationContainer = tw.div`flex justify-center`
const ButtonContainer = tw.div`flex justify-end`
const LoadMoreButton = tw(PrimaryButton)``
const StyledHeader = styled(Header)`
  ${tw`p-0 max-w-none w-full`}
  ${LogoLink} {
    ${tw`text-black hover:border-gray-300 hover:text-gray-300`}
  }
`;
const PrimaryLink = tw(PrimaryLinkBase)`rounded-full`
const HighlightedText = tw.span`bg-primary-500 text-gray-100 px-4 transform -skew-x-12 inline-block`;


function toTitleCase(str) {
    return str.replace(/\b\w/g, function(char) {
        return char.toUpperCase();
    });
}

export default ({
  navLinks = [],
  logoLink = (
    <LogoLink href="/home">
      <img src={icon} alt="logo" />
      LightRainAir
    </LogoLink>
  ),
  headingText = "Internal Affairs",
}) => {
  const [visible, setVisible] = useState(6)
  const onLoadMoreClick = () => {
    setVisible((v) => v + 6)
  }
  const { url } = useParams();
  const [material, setMaterial] = useState([]);
  const [vid, setVid] = useState([]);
  const [pages, setPages] = useState({});
  const [hasVid, setHasVid] = useState(false);
  const [hasText, setHasText] = useState(false);
  const [hasCollection, setHasCollection] = useState(false);
  const [text, setText] = useState("");

  useEffect(() => {
    async function fetchData() {
      const content = {
        "12 Tribes Faith Maintenance Counselling": ["faith_maintenance_counselling_textbook(t)", "internal_affairs_training_video(v)"],
        "Cell Leaders Duty": ["cell_leader_duty_handbook(t)", "cell_leaders_duty_videos(v)"],
        "Faith Management And Counselling Case Study": ["mens_group_case_studies(t)", "womens_group_case_studies(t)", "youth_group_case_studies(t)"],
        "The Importance Of Reporting And Announcements": ["the_importance_of_reporting_and_announcements(v)"],
        "Consultation Guideline For WOD : The Acts Of Jesus Christ": ["actsbook_guideline(c)"]
      };
      setPages(content);
      console.log(toTitleCase(url.replaceAll("-", " ")));
      const table = url.includes("wod") ? toTitleCase(url.replaceAll("-", " ")).replace("Wod", "WOD") : toTitleCase(url.replaceAll("-", " "));
      console.log(table);
      console.log(content)
      const page = content[table];
      console.log(page);
      setText(table);
      page.forEach(async (item) => {
        console.log(item);
        if (item.includes("(t)")) { 
          setHasText(true);
          material.push(item.replaceAll("(t)", ""));
        }
        if (item.includes("(v)")) {
          setHasVid(true);
          console.log(`setting ${item} to vid`);
          vid.push(item.replaceAll("(v)", ""));
        }
        if (item.includes("(c)")){
          setHasCollection(true);
          material.push(item.replaceAll("(c)", ""));
          // const { data, error } = await supabase
            // .from(item.replaceAll("(c)", ""))
            // .select('*')
          // console.log(data);
          // const words = data.filter(item => item.status === 'published');
          // console.log(words);
          // material.length = 0;
          // words.forEach((word) => {
            // material.push(word);
          // });
        }
        setMaterial(material);
        setVid(vid);
        console.log(material);
        console.log(vid);
      });
    }
    fetchData();
  }, []);

  const formattedDate = (date) => {
    const parts = date.split("-");
    return `${parseInt(parts[0]) - 1983}${parts[1]}${parts[2]}`;
  };

  return (
    (hasCollection === true) ? <MaterialList headingText={text} table={material[0]}/> : <>
    <AnimationRevealPage>
      <StyledHeader links={navLinks}  logoLink={logoLink}/>
      <Container>
        <ContentWithPaddingXl>
          { hasVid ?
            <SpecialEdu heading={text} table={vid[0]}/> : ""
          }
          { hasText ? (
            <>
            { (!hasVid) ? (
              <>
              <HeadingRow>
                <Heading>
                  <HighlightedText>{text}</HighlightedText>
                </Heading>
              </HeadingRow>
              </>
            ) : ""}
            <Posts>
              {console.log(material)}
              {material.slice(0, visible).map((post, index) => (
                <PostContainer key={index} featured={true}>
                  <Post className='group' as='a' href={`/internal-affairs/${url}/${post.replaceAll("_", "-")}`}>
                    <Info>
                      {/* <Category>{"Text Material"}</Category> */}
                      <Title>{toTitleCase(post.replaceAll("_", " "))}</Title>
                    </Info>
                  </Post>
                </PostContainer>
              ))}
            </Posts>
            </>
          ) : "" }
        </ContentWithPaddingXl>
      </Container>
    </AnimationRevealPage></>
  )
}