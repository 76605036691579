import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import tw from 'twin.macro';
import AnimationRevealPage from 'helpers/AnimationRevealPage';
import Header, { LogoLink } from 'components/headers/light';
import GradContainer from './GradContainer';
import icon from '../../images/lra.svg';
import GroupCard from './GroupCard';
import trophy from '../../images/Trophy.svg'

const StyledHeader = styled(Header)`
  ${tw`p-0 max-w-none w-full`}
  ${LogoLink} {
    ${tw`text-black hover:border-gray-300 hover:text-gray-300`}
  }
`;

const ButtonContainer = styled.div`
  ${tw`flex flex-col items-center mt-0 justify-center`}
`;

const ButtonContent = styled.div`
  ${tw`border-primary-500 border-4 border-t-2 border-l-2 border-double border-b-8 border-r-8 text-center p-4`}
  width: 250px; /* Example fixed width */
  height: 70px; /* Example fixed height */
  box-sizing: border-box; /* Include padding and border in element's total width and height */
`;

const TextContent = styled.div`
  ${tw`block text-lg font-bold text-center`}
`

const IconButton = styled.div`
  ${tw`flex flex-row w-auto -ml-4 md:m-auto`}
`

const ButtonIcon = styled.img`
    ${tw`h-[4.5rem] -mr-[7rem] mt-[3rem]`} 
`



const Button = styled(Link)`
  ${tw`p-0 text-primary-500 px-4 transform -skew-x-12 inline-block text-4xl font-bold m-12 mb-16`} 
`;

const HighlightedText = tw.span`bg-primary-500 text-gray-100 px-4 transform -skew-x-12 inline-block text-lg`;

export default () => {

  return (
    <AnimationRevealPage>
      <StyledHeader links={[]} logoIcon={icon} />
      <GradContainer heading="Let's Go Together"/>

      <ButtonContainer>
        
        <IconButton>
          <ButtonIcon src={trophy} />
          <Button to="/united-grad-competition/evangelism">
          <ButtonContent>
            <TextContent>Evangelism</TextContent>
          </ButtonContent>
          </Button>
        </IconButton>

        <IconButton>
        <ButtonIcon src={trophy} />
        <Button to="/united-grad-competition/pastors-evangelism">
          <ButtonContent>
            <TextContent>Pastor Evangelism</TextContent>
          </ButtonContent>
        </Button>
        </IconButton>
      </ButtonContainer>

    </AnimationRevealPage>
  )
}
