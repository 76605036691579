import React, { useEffect, useState } from 'react'
import AnimationRevealPage from 'helpers/AnimationRevealPage.js'
import { Container, ContentWithPaddingXl } from 'components/misc/Layouts'
import tw from 'twin.macro'
import styled from 'styled-components'
import { css } from 'styled-components/macro'
import Footer from 'components/footers/MiniCenteredFooter.js'
import { SectionHeading } from 'components/misc/Headings'
import { PrimaryButton } from 'components/misc/Buttons'
import Header, { NavLink, NavLinks, PrimaryLink as PrimaryLinkBase, LogoLink, NavToggle, DesktopNavLinks } from "../../../components/headers/light.js";
import icon from "../../../images/lra.svg"
import supabase from "helpers/SupabaseClient";
import MaterialList from 'components/cards/MaterialList.js'

const HeadingRow = tw.div`flex`
const Heading = tw(SectionHeading)`text-primary-900`
const Posts = tw.div`mt-6 sm:-mr-8 flex flex-wrap`
const PostContainer = styled.div`
  ${tw`mt-10 w-full sm:w-1/2 lg:w-1/3 sm:pr-8`}
  ${(props) =>
    props.featured &&
    css`
      ${tw`w-full!`}
      ${Post} {
        ${tw`sm:flex-row! h-full sm:pr-4`}
      }
      ${Image} {
        ${tw`sm:h-96 sm:min-h-full sm:w-1/2 lg:w-2/3 sm:rounded-t-none sm:rounded-l-lg`}
      }
      ${Info} {
        ${tw`sm:-mr-4 sm:pl-8 sm:flex-1 sm:rounded-none sm:rounded-r-lg sm:border-t-2 sm:border-l-0`}
      }
      ${Description} {
        ${tw`text-sm mt-3 leading-loose text-gray-600 font-medium`}
      }
    `}
`
const Post = tw.div`cursor-pointer flex flex-col bg-gray-100 rounded-lg`
const Image = styled.div`
  ${(props) =>
    css`
      background-image: url('${props.imageSrc}');
    `}
  ${tw`h-64 w-full bg-cover bg-center rounded-t-lg`}
`
const Info = tw.div`p-8 border-2 border-t-0 rounded-lg`
const Category = tw.div`uppercase text-primary-500 text-xs font-bold tracking-widest leading-loose after:content after:block after:border-b-2 after:border-primary-500 after:w-8`
const CreationDate = tw.div`mt-4 uppercase text-gray-600 italic font-semibold text-xs`
const Title = tw.div`mt-1 font-black text-2xl text-gray-900 group-hover:text-primary-500 transition duration-300`
const Description = tw.div``

const ButtonContainer = tw.div`flex justify-center`
const LoadMoreButton = tw(PrimaryButton)`mt-16 mx-auto`
const StyledHeader = styled(Header)`
  ${tw`p-0 max-w-none w-full`}
  ${LogoLink} {
    ${tw`text-black hover:border-gray-300 hover:text-gray-300`}
  }
`;
const PrimaryLink = tw(PrimaryLinkBase)`rounded-full`
const HighlightedText = tw.span`bg-primary-500 text-gray-100 px-4 transform -skew-x-12 inline-block`;

export default ({
  navLinks = [],
  logoLink = (
    <LogoLink href="/home">
      <img src={icon} alt="logo" />
      LightRainAir
    </LogoLink>
  ),
  headingText = "Study Material from Previous Exams",
  imageSrc = 'https://images.unsplash.com/photo-1499678329028-101435549a4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1024&q=80',
}) => {
  const [visible, setVisible] = useState(6)
  const onLoadMoreClick = () => {
    setVisible((v) => v + 6)
  }
  const [material, setMaterial] = useState([]);

  useEffect(() => {
    async function fetchData() {
      try {
        const { data, error } = await supabase
          .from('Study_Material_from_Previous_Exams')
          .select('*')
          .order('index', {ascending: false})
        console.log(data);
        const _words = data.filter(item => item.status === 'published');
        const words = [_words.pop(), ..._words];

        console.log(words);
        setMaterial(words);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }
    fetchData();
  }, []);

  const formattedDate = (date) => {
    const parts = date.split("-");
    return `${parseInt(parts[0]) - 1983}${parts[1]}${parts[2]}`;
  };

  return (
    <MaterialList table='Study_Material_from_Previous_Exams' headingText='Study Material from Previous Exams'/>
    // <AnimationRevealPage>
    //   <StyledHeader links={navLinks}  logoLink={logoLink}/>
    //   <Container>
    //     <ContentWithPaddingXl>
    //       <HeadingRow>
    //         <Heading>{<><HighlightedText>{headingText}</HighlightedText></>}</Heading>
    //       </HeadingRow>
    //       <Posts>
    //         {/* First nust be a PDF */}
    //         {material.slice(0, visible).map((post, index) => (
    //           <PostContainer key={index} featured={false}>
    //             <Post className='group' as='a' href={`prev-exams-study-material/${post.url}`}>
    //               <Info>
    //                 {/* <Category>{formattedDate(post.date)}</Category> */}
    //                 {/* <CreationDate>{formattedDate(post.date)}</CreationDate> */}
    //                 <Title>{post.title}</Title>
    //               </Info>
    //             </Post>
    //           </PostContainer>
    //         ))}
    //       </Posts>
    //       <ButtonContainer>
    //       {visible < material.length && (
    //           <LoadMoreButton onClick={onLoadMoreClick}>
    //             Load More
    //           </LoadMoreButton>
    //       )}
    //       </ButtonContainer>
    //     </ContentWithPaddingXl>
    //   </Container>
    // </AnimationRevealPage>
  )
}