import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { ListItemButton } from '@mui/material';
import tw from 'twin.macro';
import logo from "images/lra_pink.svg";

const LogoLink = tw.a``;
const LogoImage = tw.img`h-12 mx-auto`;
const MainContent = tw.div`mt-12 flex flex-col items-center`;
const Heading = tw.h1`text-2xl xl:text-3xl font-extrabold`;

const ContentsDialog = ({ open, handleClose, contents, onSelect, render=true }) => {
    return (
        <Dialog onClose={handleClose} open={open}>
            <DialogTitle>
                <MainContent>
                    <LogoLink>
                        <LogoImage src={logo} />
                    </LogoLink>
                    <Heading>{"Table of Contents"}</Heading>
                </MainContent>
            </DialogTitle>
            <DialogContent>
                <List>
                    {contents && render && contents.map((content, index) => (
                        <ListItemButton key={index} 
                            onClick={() => {
                                onSelect(content.page);
                                handleClose();
                            }}>
                            <ListItemText primary={content.segment} />
                        </ListItemButton>
                    ))}
                </List>
            </DialogContent>
        </Dialog>
    );
};

export default ContentsDialog;