import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import Header, { NavLink, NavLinks, PrimaryLink as PrimaryLinkBase, LogoLink, NavToggle, DesktopNavLinks } from "../../components/headers/light.js";
import icon from "../../images/lra.svg"
import { PrimaryButton } from "../../components/misc/Buttons.js"
import Watermark from "helpers/Watermark.js";

const HeadingRow = tw.div`flex justify-between items-center sm:flex-col md:flex-row`;
const Heading = tw.h2`text-2xl sm:text-4xl font-black tracking-wide text-center text-gray-900`;
const Text = styled.div`
  ${tw`text-lg  text-gray-800`}
  p {
    ${tw`mt-2 leading-loose`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ol {
    ${tw`list-decimal list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
  cursor: grab;
  user-select: none;
`;

const ButtonContainer = tw.div`flex justify-end`
const LoadMoreButton = tw(PrimaryButton)``
const StyledHeader = styled(Header)`
  ${tw`p-0 max-w-none w-full`}
  ${LogoLink} {
    ${tw`text-black hover:border-gray-300 hover:text-gray-300`}
  }
`;


const Testimonial = tw.div`flex! flex-col items-center md:items-stretch md:flex-row md:justify-center outline-none`;
const ImageContainer = styled.div`
  ${tw`md:mx-3 lg:mx-6 w-2/3 md:w-4/12 rounded flex items-center max-w-xs md:max-w-none`}
  img {
    ${tw`rounded`}
  }
`;
const TextContainer = tw.div`md:mx-3 lg:mx-6 md:w-6/12 py-4 flex flex-col justify-between`;
const QuoteContainer = tw.div`relative p-6 md:p-8 lg:p-10 mt-4 md:mt-0`;
const Quote = tw.blockquote`text-center md:text-left font-medium text-xl lg:text-2xl xl:text-3xl`;
const CustomerInfo = tw.div`px-5 lg:px-10 text-center md:text-left mt-4 md:mt-0`;
const CustomerName = tw.h5`font-bold text-lg lg:text-xl xl:text-2xl text-primary-500`;
const CustomerTitle = tw.p`font-medium text-sm`;
const HighlightedText = tw.span`bg-primary-500 text-gray-100 px-4 transform -skew-x-12 inline-block`;

export default ({ 
  headingText = "Chapter 1",
  navLinks = [],
}) => {
  return (
    <AnimationRevealPage>
      <StyledHeader links={navLinks} logoIcon={icon} />
      <Container>
        <ContentWithPaddingXl>
          <HeadingRow>
            <Heading>{<><HighlightedText>{headingText}</HighlightedText></>}</Heading>
          </HeadingRow>
          <Text>
            <Watermark></Watermark>
            <h1>The headquarters of the General Assembly</h1>
            <section id="tableOfContents">
              <h2>Table of Contents</h2>
              <br></br>
              <ol >
                <li><a href="#gm">The Duty of the General Manager</a></li>
                <li><a href="#chiefEducators">The Duty of Seven Chief Educators</a></li>
                <li><a href="#adminGa">The Duty of the Head of the Ministry of Administration and General Affairs</a></li>
                <li><a href="#internalAffairs">The Duty of the Head of the Ministry of Internal Affairs</a></li>
                <li><a href="#planning">The Duty of the Head of the Ministry of Planning</a></li>
                <li><a href="#finance">The Duty of the Head of the Ministry of Finance</a></li>
                <li><a href="#education">The Duty of the Head of the Ministry of Education</a></li>
                <li><a href="#theology">The Duty of the Head of the Ministry of Theology</a></li>
                <li><a href="#intMission">The Duty of the Head of the Ministry of International Missions</a></li>
                <li><a href="#intDept">The Duty of the Head of the International Department</a></li>
                <li><a href="#ev">The Duty of the Head of the Ministry of Evangelism</a></li>
                <li><a href="#culture">The Duty of the Head of the Ministry of Culture</a></li>
                <li><a href="#publishing">The Duty of the Head of the Ministry of Publishing</a></li>
                <li><a href="#infoComs">The Duty of the Head of the Ministry of Information & Communication</a></li>
                <li><a href="#praiseWorship">The Duty of the Head of the Ministry of Praise & Worship</a></li>
                <li><a href="#liaison">The Duty of the Head of the Ministry of Liaison</a></li>
                <li><a href="#domesticMission">The Duty of the Head of the Ministry of Domestic Missions</a></li>
                <li><a href="#publicity">The Duty of the Head of the Ministry of Publicity</a></li>
                <li><a href="#judicalAffairs">The Duty of the Head of the Ministry of Judicial Affairs</a></li>
                <li><a href="#auditing">The Duty of the Head of the Ministry of Auditing</a></li>
                <li><a href="#construction">The Duty of the Head of the Ministry of Construction</a></li>
                <li><a href="#sports">The Duty of the Head of the Ministry of Sports</a></li>
                <li><a href="#enterprise">The Duty of the Head of the Ministry of Enterprise</a></li>
                <li><a href="#healthWelfare">The Duty of the Head of the Ministry of Health & Welfare</a></li>
                <li><a href="#transportation">The Duty of the Head of the Ministry of Service & Transportation</a></li>
                <li><a href="#diplomaticPolicy">The Duty of the Head of the Ministry of Diplomatic Policy</a></li>
              </ol>
            </section>
              
              <h2>The Headquarters of the General Assembly</h2>
              <br></br>
              <p>The heads of the 24 ministries of the tribe and the church shall carry out their duty based on the “Duty Guidelines for the 24 ministries” of the general assembly and refer to it.</p>
              <br></br>
              <section id="gm">
                <h2>1. The Duty of the General Manager</h2>
                <br></br>
                <p>1. The general manager of the General Assembly shall oversee the administrative affairs of the workers of the General Assembly (24 ministries), work together in administration with the general manager of each tribe and church and work to improve overall cooperation and development.</p>
                <p>2. The general manager of the General Assembly is in solidarity with the general manager of the tribe, breathe together for the development of Shincheonji and should do his best in his work so that he can fulfill a world governed by God.</p>
                <p>3. The general manager keeps in mind that God and the congregation members are always watching and always discusses for establishing the kingdom of God with the heads of the 24 ministries who are the pillars and strives for completing the duty in a better way.</p>
                <p>4. The general manager of the General Assembly oversees the administrative order throughout the country and swiftly and completely obeys the instructions and orders of the Chairman.</p>
                <p>5. The general manager shall establish an administrative order in accordance with the duty guidelines for each position within Shincheonji and is responsible for all administrative work such as monitoring, pointing out, and correcting the work of each ministry.</p>
                <p>6. The general manager of the General Assembly shall issue the instructions and orders from the Chairman to the relevant institutions (ministries) or the relevant person. He reports results and keep relevant documents. He monitors each worker and ensures they work accordingly. He always asks the Chairman about administrative affairs and reports results.</p>
                <p>7. The general manager keeps a record of the work log of the church, organizes and keeps all documents related to the church and checks the document storage status of each ministry.</p>
                <p>8. The general manager shall summon workers if necessary and shall propose replacement of workers who are unable to do the work or are not faithful to their duties.</p>
                <p>9. The general manager should be a person who loves and cares for workers of duty who belong to the 24 ministries and imitates the activities and spirit of Jesus and the Chairman.</p>
              </section>
              <section id="chiefEducators">
                <h2>2. The Duty of Seven Chief Educators</h2>
                <br></br>
                <p>1. The seven chief educators of Shincheonji were created on this land in the shape of the seven spirits in front of God’s throne and were responsible for the duty of the seven lamps and seven eyes (Rv 4:5, Rv 5:6).</p>
                <p>2. The seven chief educators shall proclaim the Chairmans doctrine, that is, the words, as if shining a light, and are responsible for the production of Shincheonji textbooks, various teaching materials, and all education.</p>
                <p>3. The seven chief educators, as seven eyes, at the will of the Chairman, shall inspect all the 12 tribes, and report the results to the</p>
                <p>4. The seven chief educators will conduct the 12 tribes Bible test to check the faith of all workers of duty and congregation members of the 12 tribes and report the results to the Chairman.</p>
                <p>5. The seven chief educators must make textbooks for all positions of the 12 tribes and always educate them to complete their respective duties. They must clearly educate the workers of duty to prevent dropouts from the churches, centers, and BB.</p>
                <p>6. The seven chief educators train instructors from the 12 tribes (preliminary church head, instructors, instructor candidates, evangelists, evangelist candidates, theology workers, etc.) and ensure that well-educated instructors and theology workers of duty are always in place.</p>
                <p>7. The seven chief educators produce the guidelines for each person’s duty material, the material for the head of the group, Supervisor and cell leader, members’ faith management material, theology material which is the textbook of the mission center, and BB material, and send the produced materials to each tribe for conducting the education. If there is anything wrong with the material, it is corrected and supplemented by identifying through each tribe. It is frequently checked whether each tribe educates based on the material which is delivered from the General Assembly and Report any problems to the Chairman.</p>
                <p>8. Just as the LORD loves the Chairman, the Chairman loves the seven chief educators, so the seven chief educators have to cherish, protect, and love each other.</p>
              </section>
              <section id="adminGa">
                <h2>3. The Duty of the Head of the Ministry of Administration and General Affairs</h2>
                <br></br>
                <p>1. The head of the ministry of administration and the general affairs of the general assembly oversees the administrative affairs of the heads of the ministry of administration and general affairs of the 12 tribes. The head of the ministry of administration and general affairs receives, reviews, and screens the various administrative documents from the 24 ministries of the general assembly and the tribe or church and reports the results.</p>
                <p>2. The Shincheonji Administrative Practice Guidelines are delivered to all administrative departments of Shincheonji to be aware of them, and when there is an opinion on administrative practice, he shall collect opinions and make a report with a recommendation to the Chairman (ministry of Administration and General affairs of general assembly).</p>
                <p>3. Facts are strictly reported in accordance with the guidelines and inquire or report to the General Assembly (tribe) for unclear or false reports (The order of the Chairman).</p>
                <p>4. Various official documents, instructions, and notices of the general assembly and tribes (church) shall be delivered, stored, and managed.</p>
                <p>5. The head of the ministry of Administration and General affairs manages the details of personnel changes and the status of reward and punishment, and the ministry of Administration and General affairs of the tribe (church) shall report various recommendations and requests to the general assembly (tribe).</p>
                <p>6. Various implementation forms are reviewed and managed, and web programs such as groupware and church registration are managed.</p>
                <p>7. The ministry of Administration and General affairs of each branch church shall always interact with the ministry of Administration and General affairs of the general assembly (tribe) for the development of Shincheonji administration and shall be responsible for administrative management according to the guidelines.</p>
                <p>8. Three roles of the general secretary which the ministry of Administration and General affairs has to remember are responsibility, Truth and checking (the Chairmans word about three roles of general secretary).</p>
              </section>
              <section id="internalAffairs">
                <h2>4. The Duty of the Head Ministry of Internal Affairs</h2>
                <br></br>
                  <p>1. General Assembly head of the ministry of internal affairs must be one mind with the head of the ministry of internal affairs in 12 tribes and follow the will of the Chairman. One needs to protect and keep the saints and the property of the groups belonging to the ministry of internal affairs (Elder, Senior, Men, Women, Youth, Student, and Children together with each head of the group and check the status of the group all the time and report it to the Chairman (Tribe leader, Church head).</p>
                  <p>2. The head of the ministry of internal affairs takes the lead in maintaining the faith of the saints that belong to the ministry. One always needs to discuss with the group leaders about service absentees and make them visit them. And for the saints who want to give up on their faith, one needs to maintain them together with the group. One needs to manage and direct faith walk maintenance of saints in the front line.</p>
                  <p>3. The head of the ministry of internal affairs needs to make education for faith walk maintenance for saints.</p>
                  <p>4. The head of the ministry of internal affairs needs to make education to prevent illegal actions like divorce, unhealthy relationship between opposite gender, money transaction, and pyramid schemes and check this all the time.</p>
                  <p>5. The head of the ministry of internal affairs needs to check the growth of the 12 tribes and drop out of saints and share good cases with group leaders or correct problems immediately.</p>
                  <p>6. The head of the ministry of internal affairs needs to gather the successful cases of faith walk maintenance to share. And he needs to educate groups and analyze the faith walk maintenance statistics of each group (attendance rate, dropout and long-term absentee rate, actual growth rate, etc.) to give to each group and discuss the things they can improve with the group leader.</p>
                  <p>7. The head of the ministry of internal affairs needs to check the group leaders and department leaders frequently. If there is any group leader or department leader who is not taking up on his/her duty well enough, then one needs to report it to the Tribe leader (Church leader) and replace that person.</p>
                  <p>8. The head of the ministry of internal affairs need to check any difficulties from saints while they are taking their faith and needs to improve guidelines for the group to run their group well.</p>
              </section>
              <section id="planning">
                <h2>5. The Duty of the Head Ministry of Planning</h2>
                <br></br>
                  <p>1. The General Assembly Ministry of planning must be one in heart and mind with the ministries of planning in 12 tribes to plan heavenly culture and show the light of heaven, plant the Heavenly culture to the world to shine it, and show the glory of God.</p>
                  <p>2. The ministry of planning only must focus on the Heavenly plan. The ministry of planning must be a walking encyclopedia, knowing everything and having skills. It is up to planning whether a country will be developed and a shining light, or not.</p>
                  <p>3. Planning is very crucial work for a general manager. Planning is a work that gives direction to all the work and events from study and wisdom. So, the head of the ministry of planning must discuss with the General manager all the time and run all of it with wisdom and intelligence. </p>
                  <p>4. The ministry of planning must help and plan with all the wisdom for all the work of the 24 ministries. The ministry of planning is not just doing its work only but planning better on developing a plan for each ministry to work efficiently.</p>
                  <p>5. The ministry of planning must suggest the best strategy based on the analysis of data and show the current status of saints of the 12 tribes to fix the wrong thing to be better.</p>
                  <p>6. The ministry of planning is like a laboratory. So, it must focus on swiftly creating and improving everything to be better than before. At this time, it must plan all of it based on the religion, the Bible. So, they can make it with spiritual wisdom according to God’s will. It cannot be the planning from a worldly perspective but the planning Biblically and spiritually for the glory of God and the development of God’s kingdom.</p>
                  <p>7. The planning is like a design to build the house. Constantly planning, improving and seeking new ways in all things. And it must be a plan to the extent that each ministry can act. It also must be a plan to the benefit of the church. Good planning is always making a more efficient way.    </p>
              </section>
              <section id="finance">
                <h2>6. The Duty of the Head of the Ministry of Finance</h2>
                <br></br>
                  <p>1. Shincheonji, the head of the ministry of finance is a worker of duty that the Chairman specified and chose to protect and manage the wealth of God in Shincheonji.</p>
                  <p>2. The general assembly head of the ministry of finance must educate the tribe ministry of finance about finance and accounting. Also, he/she must keep pace with the tribe’s ministry of finance.</p>
                  <p>3. The head of the ministry of finance must put a finance secretary to manage financial and administrative work and cooperate with each center and group to have a finance secretary to report.</p>
                  <p>4. All the property and banking are under the name of Shincheonji. So, for the one who takes up this duty and the responsibility of the bank account, one must fulfill that duty</p>
                  <p>5. The head of the ministry of finance in each tribe and church are absolute managers in charge of finances. The accounts of the General assembly, each tribe, and church are under the name of Shincheonji. The ministry of finance can send funds under the responsibility (permission) of the Chairman for the general assembly and the Tribe leader for the tribe. Fixed expenditures and funds that got permission already can be sent out. However, a property or a large amount of funds must be approved in advance. Also, one cannot lend church funds to someone personally.</p>
                  <p>6. No funds can be used without the permission of the head of the ministry of finance. Also, there must be a receipt for all payments for proper auditing. General assembly funds will be paid when there is permission from the chairman, and for the tribe, there must be permission from the tribe leader.</p>
                  <p>7. Any fund that has been given ignoring the finance guideline, whether that was by intention or not, is the responsibility of the head of the ministry of finance. The tribe leader, church head, and the head of the ministry of finance cannot misuse, give away, or use funds carelessly without the permission from the Chairman of the general assembly, that is wrong. There mustn’t be any of these things happening.</p>
                  <p>8. A finance secretary needs to get permission from the head of the ministry of finance even if the Chairman or tribe leader submits a claim form. This system is to prevent those with authority from misusing funds.</p>
                  <p>9. The ministry of finance must not believe that all the funds are church funds. The reason why is that there might be personal funds as well. Only funds that are recorded as Church funds in the accounting book are church funds, and one cannot count personal funds added to the church fund. Split the private and public funds and the ministry of finance must deal with the public fund as an offering (For property, the Ministry of construction manages it)</p>
                  <p>10. The head of the ministry of finance must report to the chairman and ask for auditing if any finance-related issues are from the General assembly, tribe, or for events.</p>
                  <p>11. All the heads of the ministry of finance and finance secretaries must keep their special duty and deal fairly and with integrity, as ordered by the Chairman.</p>
              </section>
              <section id="education">
                <h2>7. The Duty of the Head of the Ministry of Education</h2>
                <br></br>
                  <p>1. Shincheonji Ministry of Education is an organization that educates heavenly culture.</p>
                  <p>2. The head of the ministry of education is the one who is a worker of duty that was chosen specially to educate heavenly culture to the whole world.</p>
                  <p>3. The head of the Ministry of Education needs to have enough workers to take up duty and needs to investigate full-time heavenly education.</p>
                  <p>4. The head of the Ministry of Education needs to take up on the will of God and the Chairman, establish the Shincheonji ministry of education in all churches, raise educators and check their skills and help them improve their skills, plant heavenly culture so the whole world can be changed to God’s kingdom of heaven.</p>
                  <p>5. The ministry of education needs to oversee, prepare and review enough materials and videos for all education and set a step-by-step teaching method and implement it.</p>
                  <p>6. He/she needs to establish a training centre where recruits that passed the entrance exam, gathered by recommendation by the tribe leader, can be interviewed and trained as educators.</p>
                  <p>7. Those to be educated are each tribe and church pillars and heads of ministries, Theology workers, heads of the centers, instructors, instructors in training, evangelists, evangelist in trainings, each group leader, department leaders and cell leaders, special forces, BB teachers, and the one who is doing evangelism. This education is for helping them to understand their duty and act according to that.</p>
                  <p>8. The general assembly ministry of education must keep pace with each tribe’s ministry of education and give direction to them and get the report from them. Also, he/she must do an annual education audit to check the issues from education and report it to the Chairman.</p>
                  <p>9. Heavenly culture education is education that shows the invisible word of God to visible writing and teaches to keep it.</p>
                  <p>10. This education not only engraves the word of God in the hearts of the Shincheonji people but also in the hearts of all nations so they can be walking Bible. And it ensures that everyone knows God. It is an education to heal all nations. There are many religions with words of the world, but there is no word of God. Also, there is no church or a person that teaches this.</p>
                  <p>11. Heavenly culture education is the seed of God, life, and the light. A person without this Heavenly culture education doesn’t possess light or a life and is not a child who is born with the seed of God and thus lives in the darkness.</p>
                  <p>12. The ministry of education in heaven is a living educator that plants the seed of God and creates life and light in many people. So, we cannot say one’s duty and work are small. And this person is a very precious worker of duty that connects the lifeline which was cut between God and man. </p>
              </section>
              <section id="theology">
                <h2>8. The Duty of the Head of the Ministry of Theology</h2>
                <br></br>
                  <p>1. The general assembly head of the Ministry of Theology needs to be one with the 12 tribes’ ministry of Theology. And he/she needs to manage all the mission centres work and direct the heads of the centre, instructors, and evangelists.</p>
                  <p>2. The tribe (church) head of the ministry of theology needs to take up on the will of the Tribe leader (Church head) and govern each head of each centre. Also, He or she takes full responsibility in recruiting mission centre students and runs the centre. The head of the centre exists for the mission centre, Tribe leader exists for the tribe. One cannot make a life of faith centred in man but need to centre in the church.</p>
                  <p>3. The mission centre is the gate to seal. It is a place that chooses people of God and teaches them a new song. Improvement of the mission centre is up to the ministry of Theology. When the mission centre grows, it will be a big school. One must overcome all the current suffering with endure and keep what one holds onto in the beginning till the end and work hard.</p>
                  <p>4. The head of the ministry of theology must gather heads of centres and check the status frequently. Also, he/she must visit the centres often to check the issues and report them. One needs to check the faith and performance of the heads of the centres and the teaching of instructors and evangelists, and through feedback correct what is wrong and make improvements.</p>
                  <p>5. The head of the ministry of theology needs to report the status of each mission centre clearly in the pillars’ meeting every week and deliver the instruction to each mission centre. If the head of the centre is not taking up his/her duty well, one needs to put the head of the centre to be head of BB and search for a new person who can take up the duty of the head of the centre. This applies to instructors and evangelists.</p>
                  <p>6. If the head of the ministry of theology doesn’t know the status of the workers or their situation well, that mission centre will not function properly. Also, workers will lose their hearts for working. It is due to the lack of governing from the head of the ministry of theology.</p>
                  <p>7. The head of the ministry of theology needs to help all the workers to keep their duty like their lives and love each other. The True faith is giving up on oneself and keeping one’s duty. It is the path to the way of eternity.</p>
              </section>
              <section id="intMission">
                <h2>9. The Duty of the Head of the Ministry of International Mission</h2>
                <br></br>
                  <p>1. The head of the ministry of international missions needs to take up the will of the Chairman (Tribe leader) and flow down the instruction to overseas churches and manage all the work related to overseas churches and protect the overseas members and the wealth of the churches and report any concerns to the Chairman (Tribe leader).</p>
                  <p>2. The head of the ministry of international mission needs to establish churches in each overseas country to heal the nations. And one needs to create a great multitude in white and put all one’s effort into the work of evangelism to develop it.</p>
                  <p>3. The head of the ministry of international missions needs to discuss with the Tribe head of the Ministry of International mission and the church head of the overseas church and appoint instructors, evangelists, heads of the centres, group leaders, head supervisors, cell leaders and each ministry leader. Also, he/she needs to educate them to take up their duty fully.</p>
                  <p>4. The general assembly (Tribe) head of the ministry of international mission needs to check all the areas of governance, sermons, education and gathering management, admin, and finance to see that all overseas churches are running well according to the instruction of the general assembly and tribe.</p>
                  <p>5. The head of the ministry of international mission needs to find unprecedented methods of faith walk maintenance and methods to help overseas saints be transformed into true believers across language, culture, and race and check this regularly.</p>
                  <p>6. The head of the ministry of international mission needs to gather overseas workers via online video format and check faith and evangelism as the main check-up and be ready for gatherings or events.</p>
                  <p>7. Each tribe ministry of international mission needs to find and raise workers to send overseas for the growth of the overseas church. For this, the General assembly (tribe) will support with education so the overseas church can be independent through its native workers in the overseas church.</p>
                  <p>8. The Ministry of Domestic Missions needs to cooperate with the Ministry of International Missions, and the international department to make peace and heavenly culture via building good relationships with religious people, politicians, and media people domestically and overseas to fulfil the goal. The tribe ministry of international mission needs to work together and communicate with the domestic church and overseas church of other tribes so each one can improve.</p>
                  <p>9. All the Shincheonji family are heavenly soldiers. Overseas saints must declare the gospel of the reality of Revelation which is the last trumpet sound (Shincheonji year 38. 10.24. from the article)</p>
              </section>
              <section id="intDept">
                <h2>10. The Duty of the Head of the Ministry of International Department</h2>
                <br></br>
                  <p>1. The general assembly (tribe) head of the international department needs to manage evangelism and faith of foreigners under the international department by following the direction of the general assembly Ministry of International Missions.</p>
                  <p>2. The tribe head of the international department needs to discuss with the Tribe leader (tribe general manager) regarding its work and cooperate with the overseas church when it is required.</p>
                  <p>3. The tribe head of the international department needs to support the number of students for the centre opening by networking and evangelizing students of the tribe’s international group mission centre. Also, one needs to check the status of centre workers, students, and finance and discuss it together with the head of the centre and head instructor all the time.</p>
                  <p>4. The international department needs to help all members of the international department to bear fruit since they are the family of God and the messenger of God. +. …. A person who does not have fruit cannot take up the duty of the head of the Ministry of International Department.</p>
                  <p>5. The international department needs to prepare services according to languages and translate and interpret all types of education and material that tribes and church use to contribute to the growth of the faith walk of saints.</p>
                  <p>6. The international department needs to send out letters of Shincheonji news all over the world with interpretation and translation. Also, it needs to report to the General assembly when there is a response from other countries.</p>
                  <p>7. The international department needs to liaise and manage guests of each country with each church when there is a trip of peace messengers like the Chairman.</p>
              </section>
              <section id="ev">
                <h2>11. The Duty of the Head of the Ministry of Evangelism</h2>
                <br></br>
                  <p>1. Obeying the will of the Chairman (or Tribe Leader), the head of the ministry of Evangelism should stand in solidarity with the church and the Ministry of Evangelism of each group and devote all his might for evangelism for the development of the group. He/she must be one with all the IWNs and the members of the group. The responsibility of evangelism is on the general manager and the head of the Ministry of Evangelism of each church.</p>
                  <p>2. The life of ‘faith’ is Word, and the life of the church and center is evangelism. If the head of the Ministry of Evangelism is complacent or indifferent, the church and center will be paralyzed leaving no space for expectation on development.</p>
                  <p>3. The head of the Ministry of Evangelism should lead JJNs, Cell leaders, and all IWNs to evangelize making them the workers of evangelism and be responsible for evangelism education and the evangelism of the saints.</p>
                  <p>4. The head of the Ministry of Evangelism should organize evangelism groups like the special forces, regional evangelism team, and so on, for the work of evangelism. The head of the Ministry of Evangelism should give enough evangelism education and present methods of evangelism depending on the circumstances and environments of the saints. Furthermore, the head of the Ministry of Evangelism should always investigate and report the evangelism status</p>
                  <p>5. It’s not too much to say that the responsibility of the development of the church and the center lies on the Ministry of Evangelism. Therefore, the head of the Ministry of Evangelism should make all the saints evangelize through researching the fields and the methods of evangelism in which all the members can evangelize. The head of the Ministry of Evangelism should put his effort in making all the saints have one heart and love each other for evangelism and completing even a day sooner by sealing 12,000 from each Tribe.</p>
                  <p>6. Today, the purpose of the work of completing the 144,000 and creating the great multitude in white are for God to redeem the world, which He has lost for 6,000 years, and reign over it as God’s kingdom. If these two things are completed, there will be nothing more to do for salvation (From JSS article Y38.04.20). Therefore, today, we must create enough numbers to redeem what’s lost. The head of the Ministry of Evangelism must perceive that evangelism is the most precious work, let the saints know this, and educate them so that saints can run for evangelism in whichever way.</p>
              </section>
              <section id="culture">
                <h2>12. The Duty of the Head of the Ministry of Culture</h2>
                <br></br>
                  <p>1. The duty of the head of the Ministry of Culture is to heal the corrupt world with heavenly culture and completing the world God reigns over by creating the world of peace where there’s no war. Therefore, the head of the Ministry of Culture must reveal the glory of God through heavenly culture, the eternal gospel of heaven, and promote heavenly culture of Shincheonji widely.</p>
                  <p>2. The Ministry of Culture of the General Assembly should create heavenly culture for the healing of all the nations. Especially, to promote the reality of fulfillment of Revelation all over the world, with the highest level of heavenly culture, the ministry should conduct the event and performance inside and outside Shincheonji, and plan and produce various publicity materials. This is because, the culture of Revelation, the highest knowledge of mankind, is to create the world of God’s reign by bringing an end to the various cargoes of Babylon in Rev 18 completely and capturing the dragon. To achieve this, the head of the Ministry of Culture of the General Assembly should create the best heavenly culture by standing in solidarity with the Ministry of Culture of the 12 Tribes</p>
                  <p>3. By standing in unity with the Ministry of Culture of the 12 Tribes, the Ministry of Culture of the General Assembly should make all the world sympathize with heavenly culture and become one with it so that the light of Shincheonji could be the light of the world.</p>
                  <p>4. The Ministry of Culture should create the most advanced culture in the world, impress people and engrave the culture in their eyes and hearts, always keeping in mind that the heavenly culture itself is the wisdom. <br/>Thus, it must be the heavenly culture acknowledged by the whole world. </p>
                  <p>5. To reveal the glory of God, the Ministry of Culture should work with wisdom and the brightness of heaven and shine the creative light that makes the world born again with heavenly culture.</p>
                  <p>6. The head of the Ministry of Culture should lead the mindset of all the workers, who belong to him, to connect with heavenly culture. </p>
                  <p>7. As the chief manager of all the departments of the Ministry of Culture, the head of the Ministry of Culture should conduct the education for cultivating the workers with talents in the field of planning heavenly culture, broadcasting, arts, performance, photography, fine arts, exhibition and promotion, and, by extension, devote himself to create the workers. The head of ministry should always check the maintenance status of all the materials, equipment, gear, and various things of Ministry of Culture as well.</p>
              </section>
              <section id="publishing">
                <h2>13. The Duty of the Head of the Ministry of Publishing</h2>
                <br></br>
                  <p>1. The Ministry of Publishing of the General Assembly, by standing in unity with the Ministry of Publishing of the 12 Tribes, becomes the heavenly publisher that conveys God’s will.</p>
                  <p>2. As they reveal the reality of the fulfillment of prophecy of the New Testament, which has never been revealed in any era of the 6,000 years of the Bible, all the books of Shincheonji are the hidden manna, the eternal gospel. So, the value of the books of Shincheonji is like life. Therefore, the head of Ministry of Publishing should make the saints read or educate by distributing the books for the sound faith of the members.</p>
                  <p>3. The Ministry of Publishing of the General Assembly should inspect the copyright registration (Registering and managing copyright for all books published by Shincheonji Publishing Group), and the contents of various publications and printed material produced and distributed by the 12 Tribes.</p>
                  <p>4. No education can be conducted with anything other than material officially recognized by Shincheonji. And the head of Ministry of Publishing should protect the church and the saints by preventing the sale of books made by individuals to the saints of Shincheonji.</p>
                  <p>5. It’s not allowed for the saints to arbitrarily copy and bind the contents of the books published by the Ministry of Publishing of General Assembly</p>
                  <p>6. In the case of re-publishing (reprinting) a previously published book, it is necessary to check whether there is something to be corrected or supplemented, and to publish it after correcting and supplementing it.</p>
                  <p>7. The Ministry of Publishing of the General Assembly should proofread and copy-edit the Chairmans manuscript and the drafts from various Ministries of the General Assembly. In terms of the drafts of the Tribe and the branch church, the Ministry of Publishing of the Tribe or the branch church should proofread and copy-edit autonomously.</p>
              </section>
              <section id="infoComs">
                <h2>14. The Duty of the Head of the Ministry of Information and Communication</h2>
                <br></br>
                  <p>1. The duty of the head of the Ministry of Information and Communication of Shincheonji is to repel all the forces of evil through information, communication, and promotion so that God can reign. It’s the era of computers. The Ministry of Information and Communication should be equipped with the capacity to govern over the whole world through the rapid acquiring of information, and the Ministry should surpass the level of communication of the world.</p>
                  <p>2. The Ministry of Information and Communication is a front-line combatant in the field of information and communication. The Ministry should do its best to win in fighting with the information and communication. The head of the Ministry of Information and Communication should establish the workers of great skill in each field and make them demonstrate their skills to overcome the persecutors and the world through faith and light, so that God’s kingdom can be completed even a day sooner. Especially, standing in unity with the Ministry of Information and Communication of the 12 Tribes, the head of the Ministry of Information and Communication of the General Assembly should post introductions and promotions of Shincheonji on various sites and make many people in the world see them with their computers or cell phones. This is so the saints of Shincheonji all over the country can be at an advantageous position in the field of information and communication.</p>
                  <p>3. The Ministry of Information and Communication has the greatest duty of this era to publicize God’s kingdom Shincheonji through promotion in the field of information and communication. The Ministry must make all the saints of Shincheonji become the seventh trumpet and the trumpet of victory so that the news of God’s kingdom Shincheonji resonate into every nook and cranny of the world.</p>
                  <p>4. The Ministry of Information and Communication should let people know that the traditional church pastors are deceitful and corrupt, that the false broadcasts are the actions of the devil, and that the highest truth of Shincheonji and the fact that it’s sealed according to the Bible is the work of God. The Ministry should let the whole world perceive that Shincheonji has overcome the world through the truth and biblical actions</p>
                  <p>5. The Ministry of Information and Communication should discuss with the worker in departments based on the direction of each church so that online evangelism guidelines can proceed without problems. The Ministry should prevent the problems in advance with reference to risk factors of online evangelism, and supervise it at all times</p>
              </section>
              <section id="praiseWorship">
                <h2>15. The Duty of the Head of the Ministry of Praise & Worship</h2>
                <br></br>
                  <p>1. Shincheonji hymns must first be sung to bring glory and joy to God, and then they must be sung for the joy of oneself and the saints.</p>
                  <p>2. The only hymn that can give life to the mindset of Shincheonji is the hymn of the new song. The Ministry of Praise and Worship should establish the right plan to set the mindset of the saints of Shincheonji straight through the Word and praise and practice it. It is said that there are youth members who enjoy singing songs with secular melodies which is not according to God’s will. You can do things right when your mind is right.</p>
                  <p>3. For us, singing the hymn of the new song or not is not optional. In times of war like this, we must sing the hymn of fulfillment a lot. To raise the morale of the heavenly army, we must create and sing the heavenly military song.</p>
                  <p>4. The themes of new hymns suitable for this era are the military songs of heaven, the Word, Revelation, harvest, the 12 Tribes of the promised kingdom, God’s kingdom and priests, etc.</p>
                  <p>5. The head of the Ministry of Praise and Worship of the General Assembly should get the Ministry of Praise and Worship of each Tribe and branch church to cooperate with and practice all the guidelines, official documents, and various matters issued by the Ministry of Praise and Worship of the General Assembly, proactively.</p>
                  <p>6. The head of the Ministry of Praise and Worship of the General Assembly should direct and supervise so that the saints can actively participate in all the praise activities of all the services like Shincheonji Establishment Day service, the service for the 3 Feasts, etc.</p>
              </section>
              <section id="liaison">
                <h2>16. The Duty of the Head of the Ministry of Liaison</h2>
                <br></br>
                  <p>1. The Ministry of Liaison is the ministry that promotes and present God and Shincheonji by publicizing Shincheonji to the whole world. The head of the Ministry of Liaison should have extensive knowledge and experience in the field of religion and society, have a wide acquaintance network and be a worker of virtue in the church. He should be aware of all the important work and activities of the leaders of the church.</p>
                  <p>2. When the church is to do something, the Ministry of Liaison should find out in all directions and make it happen. Those who cannot say a word because they are afraid that their name will be revealed as a member of Shincheonji would be acknowledged neither in Shincheonji nor in heaven, the spiritual realm. The Ministry of Liaison should be filled with the mindset of the martyrs for God, God’s kingdom, and His people. Whether or not the church develops depends on the Ministry of Liaison.</p>
                  <p>3. The head of the Ministry of Liaison should do necessary activities such as social and religious interactions for large and small events of the church. The head of the Ministry has the same duty as a link between the church and society both internally and externally. He should liaise social figures to participate in the event, promote the honor of the church, and cultivate the virtue of the church and the saints.</p>
                  <p>4. The head of the Ministry of Liaison should promote friendly relationship with other denominations as well as your own denomination and build the communication with each other. The head of the Ministry should build the relationship with various figures of society and become a person who the people can trust and cooperate with. This must be built on love, kindness, communication, interaction, cooperation, and volunteerism. Besides this social relationship, the Ministry of Liaison should do what the Ministry must do for the things that the church need such real estate and purchasing land for the construction work of the church.</p>
                  <p>5. One who’s afraid that his name will be revealed to each denomination and society cannot become the head of the Ministry of Liaison. The work of liaison requires the relationship from every church, society, and even to political circles in the world. The person who can do this in every way should be the head of the Ministry of Liaison. Through the intimate interaction with social, political, and religious circles, even building a church can be made possible. This is the duty of the head of Ministry of Liaison that cultivates the virtue of the church.</p>
                  <p>6. The Ministry of Liaison should understand the behavior, troubles, and complaints of the members and check every corner of the church and report any concerns at the meeting. Emergency situations should be reported immediately.</p>
              </section>
              <section id="domesticMission">
                <h2>17. The Duty of the Head of the Ministry of Domestic Missions</h2>
                <br></br>
                  <p>1. The duty of the Ministry of Domestic Missions is about missionary activities domestically and foreign and the operation of mission churches. The Ministry is in charge of education and evangelism through the mission church. The duty of a mission church is to communicate and speak on behalf of Shincheonji between Shincheonji and Gentile churches. The mission church is a place that connects the churches of the world and Shincheonji and it is a place that works for public relations with social churches.</p>
                  <p>2. The mission churches should recover the great multitude in white that will come out of the great tribulation. The mission churches should be the places to contain them.</p>
                  <p>3. Education according to the level of understanding of the congregation should take place at the mission church. The contents to be educated are: ‘The necessity to know the Word of promise of God the absolute’, ‘History, Instruction, Prophecy, and Fulfillment’, ‘God and the devil’, ‘Heaven and hell’, ‘The 4 types of pastors’, ‘Betrayal, destruction and salvation’, ‘Love and grace’, ‘The food at the proper time’, ‘Promise’, and ‘The purpose of the covenant’. The most difficult place to educate is the mission church. You must always check (this is the request of the Chairman).</p>
                  <p>4. You should appoint competent people as the head instructor and the evangelists of the mission church. Do not have too many mission church managers. The family members of mission church should evangelize</p>
                  <p>5. Former pastors, who belong to the Ministry of Domestic Missions, should go to a new religious denomination, and get acquainted with the pastors and religious leaders there and open the way for exchange. The Ministry of Domestic Missions should report the trends of each denomination in the country, missions work and status (newspaper, broadcasting), and the status of mission churches to the general manager of each Tribe, and the Tribe report to the General Assembly.</p>
                  <p>6. Former pastors might lack in skill a bit, but because they were from Babylon, they must go there and have conversations. Through the conversation with the domestic pastors, we must open the way to interact with the pastors in the world.</p>
                  <p>7. Former pastors should also interact with each denomination on a global level. The Ministry of Domestic Missions should carry out these activities systematically through the internet (Shincheonji Internet Café, Home Page)</p>
              </section>
              <section id="publicity">
                <h2>18. The Duty of the Head of the Ministry of Publicity</h2>
                <br></br>
                  <p>1. The Ministry of Publicity of Shincheonji is the ministry that works on the frontline to promote and publicize Shincheonji to the whole world. The book of Revelation is an aggregation of 6,000 years of God’s work, and the Revelation is being fulfilled today. The key point of Shincheonji’s promotion is to make it known to the ends of the earth that Shincheonji appeared because of the fulfillment of the book of Revelation, that Shincheonji is testifying the prophecy and the reality of the fulfillment of Revelation, that Shincheonji has overcome the world with the revealed Word, and that God will reign the world.</p>
                  <p>2. The head of the Ministry of Publicity should spontaneously proceed, having the Chairman’s instruction as standard and report the result daily to the general manager of the General Assembly.</p>
                  <p>3. Press and publicity are the bowls poured out into the air (Rev 16:17). For effective publicity, each tribe and branch church should form an organization having the organization of the General Assembly as the standard, and with the instruction of the General Assembly, the Ministry of Publicity of the 12 tribes proceed in unity</p>
                  <p>4. The head of the Ministry of Publicity should think what he must do to publicize, establish an internal and external publicity plan on the policies and projects, and develop promotional techniques so that the promotional materials can be delivered to many people</p>
                  <p>5. The Ministry of Publicity should plan internal and external promotion that the 12 tribes need, publicize through various promotional materials, and figure out the status quo. Internally, the Ministry should nurture workers for promotion and educate on promotions. Externally, the Ministry should interact with broadcasting stations and press companies and journalists and maintain friendly relations with all various sectors of society for efficient publicity. The Ministry should promote the publicity through media (Broadcasting and newspaper), social media and establish and manage promotion.</p>
              </section>
              <section id="judicalAffairs">
                <h2>19. The Duty of the Head of the Ministry of Judicial Affairs</h2>
                <br></br>
                  <p>1. The Ministry of Judicial Affairs should legally protect the property of the saints and the church and be in charge of various litigation matters.</p>
                  <p>2. The head of the Ministry of Judicial Affairs of the General Assembly and each tribe should identify legal experts and related people to form the legal team to be a standing advisory body. The legal team should examine and advise on legal matters.</p>
                  <p>3. The Ministry of Judicial Affairs should oversee taking legal action on false reports by analyzing Shincheonji related press releases.</p>
                  <p>4. The Ministry of Judicial Affairs should provide legal review and advice on the major operational issues of the General Assembly and each tribe. The Ministry oversees providing legal review on various external contracts, construction-related matters, and legal advice on the textbooks and materials produced, copied and distributed, civil complaints related to religion, social matters, laws and accounting.</p>
                  <p>5. The Ministry of Judicial Affairs should systemically store and manage documents that constitute legal material, handouts, articles, photos, videos and recordings.</p>
                  <p>6. The Ministry of Judicial Affairs should inspect Shincheonji’s internal regulations and guidelines and establish and operate a disciplinary committee so that it can serve as an internal disciplinary, retrial and relief function, and appeals.</p>
              </section>
              <section id="auditing">
                <h2>20. The Duty of the Head of the Ministry of Auditing</h2>
                <br></br>
                  <p>1. The head of the Ministry of Auditing in Shincheonji conducts regular and occasional audits on the implementation of the guidelines of the 12 tribes (churches) and each ministry for the sake of the correct faith of Shincheonji members. </p>
                  <p>2. The head of the Ministry of Auditing of the General Assembly observes the intention of the Chairman and performs the unique function of auditing, identifying weaknesses in overall church operations and providing necessary information for the church to operate efficiently.</p>
                  <p>3. The regular audit of the General Assembly is conducted with the seven heads of education (or General Assembly leaders), and the responsible person for each field is selected to audit.</p>
                  <p>4. The subject of the audit includes the request for confirmation sent by the General Assembly headquarters to confirm the implementation of the Chairman’s intentions and the corrections and amendments suggested during the previous year’s audit.</p>
                  <p>5. The head of the Ministry of Auditing audits the completion of church duty, the development of faith, and the transparency of income and expenditure in the field of financial accounting.</p>
                  <p>6. According to the instruction given, the head of the Ministry of Auditing investigates the management of church members, any irregularity in the way the church operates, misconduct in duties, the truth, and circumstances of specific matters, and suggesting suitable policies.</p>
              </section>
              <section id="construction">
                <h2>21. The Duty of the Head of the Ministry of Construction</h2>
                <br></br>
                  <p>1. The head of the Ministry of Construction must not only have faith and knowledge but must also have full knowledge and skills related to construction. Only by appointing a competent person as the head of the Ministry of Construction and preparing a good Temple can the members of the Church worship God with a stable and peaceful mind and build the Temple of their hearts. Through the construction of the Temple of the heart and the body the 12 tribes of Shincheonji can become the perfect kingdom and people of God. Keep this in mind. Therefore, the incompetent head of the Ministry of Construction must be replaced.</p>
                  <p>2. The head of the Ministry of Construction of the General Assembly must always monitor and manage/supervise all construction administration of the 12 tribes (securing a temple site, temple construction, temple purchases, real estate sales, etc.), and take necessary measures immediately by following instruction.</p>
                  <p>3. The head of the Ministry of Construction must report carefully and strictly according to procedure, as real estate contracts are a matter of large expenditures. Even if each Tribe has received verbal approval from the Chairman regarding construction, it must proceed with the contract after obtaining approval from the General Assembly through a planning report. Not reporting to the General Assembly, even for security reasons, is an act of disregard for order and should never happen.</p>
                  <p>4. Regarding construction matters that are carried out in accordance with the Chairmans special instructions, the Ministry of Construction must notify the General Assembly before the process begins.</p>
                  <p>5. Construction finances are construction offerings given to God by the saints for the purpose of building the temple. Therefore, they cannot be used for any other purpose.</p>
                  <p>6. The head of the Ministry of Construction of the Tribe can have any member of the congregation come and serve when there is work in the Tribe. Jesus gave us his life, blood, and flesh, but if a believer cannot do a single service by devoting his/her physical efforts, how can he be called a believer in Jesus, who purchased him with blood? The Church is not a company or a factory. Knowing that the church is a non-profit service organization, members should practice voluntary service.</p>
              </section>
              <section id="sports">
                <h2>22. The Duty of the Head of the Ministry of Sports</h2>
                <br></br>
                  <p>1. The Ministry of Sports of the General Assembly must unite with the 12 Tribes’ Ministry of Sports to heal the world with the heavenly sports culture and display the glory of God.</p>
                  <p>2. The heads of the Ministry of Sports of the General Assembly and Tribe fulfil their duty–seek the direction of Shincheonji sports activities, create/develop the heavenly sports culture, train the spirit and body of believers through sports activities, achieve harmony, and publicize Shincheonji–to spread its status throughout the world. </p>
                  <p>3. The Tribe’s Ministry of Sports conducts the duty in cooperation with the Ministry of Sports of the General Assembly. It is allowable to organize necessary departments–such as Public Relations Department, Game Management Department, Referee Department, Planning Department, Life Sports Department, Culture Public Relations Department, and Medical Health Department, etc.–and hire and train skilled people in charge so that they can be faithful to their duties.</p>
                  <p>4. The heads of the Ministry of Sports of the General Assembly and Tribe set the yearly direction and goal of sports activities through regular meetings and discussion/announcement of detailed action plans to ensure that activities are carried out efficiently.</p>
                  <p>5. The head of the Ministry of Sports of the branch church cooperates/interacts with the head of the Tribe’s or other branch churches’ Ministry of Sports for the development of the Tribe’s (church) Ministry of Sports and takes up on the intentions of the Chairman (tribe leader, church head), following his mindset and action.</p>
                  <p>6. The heads of the General Assembly and Tribe’s Ministry of Sports secure the finances of the Ministry of Sports, rent/reserve venues to be used for all sports events and event-specific training in advance, and protect athletes.</p>
              </section>
              <section id="enterprise">
                <h2>23. The Duty of the Head of the Ministry of Enterprise</h2>
                <br></br>
                  <p>1. The Ministry of Enterprise protects the property of the church and its members, provides convenience to members within the church, discovers/plans enterprise beneficial to Shincheonji, and oversees enterprise operations. In addition, all goods and services in the church must be legally distributed, and the income must be managed so that they are used to support the lives of the saints in need and help the church’s finances.</p>
                  <p>2. The head of the Ministry of Enterprise of the 12 Tribes must establish an annual business plan for the Tribe’s or branch church’s Ministry of Enterprise no later than 20 days before the beginning of each year, receive approval from the Tribe’s secretary and Tribe leader, and report it to the Ministry of General Assembly.</p>
                  <p>3. The Ministry of Enterprise of the General Assembly (Tribe) may adjust or request changes to the yearly plan submitted by the Tribe (Church) if it is deemed necessary and the requested Tribe (Church) must comply with the request.</p>
                  <p>4. In the 12 tribes, no other business registration certificate can be used except for the Shincheonji Church of Jesus business registration certificate reported to the General Assembly. The head of the Ministry of Enterprise must take responsibility and manage whether the above matters regarding the business registration certificate are being observed in the relevant Tribe and its affiliated branch church.</p>
                  <p>5. To prevent disadvantages or damages during the implementation of the project, the head of the Ministry of Enterprise must go through, review and consultation with the relevant ministry such as the Ministry of Justice of the relevant Tribe, and, if necessary, conclude contracts or take up on important obligations. The guidelines of the Ministry of Finance must be followed to plan, execute, manage, and settle the budget.</p>
                  <p>6. The head of Ministry of Enterprise strictly manages/supervises the enterprise’s property from loss by complying with the bankbook management guidelines of the Ministry of Finance. He must thoroughly manage church property so that it may not be damaged, using a personal bankbook or check card instead of ones that belong to the Church.</p>
                  <p>7. The head of the tribes Ministry of Enterprise faithfully reports/pays various public charges related to all profits generated from the enterprise under the jurisdiction of the tribe’s enterprise and reports them to the Ministry of Enterprise of the General Assembly. All disadvantages arising from unfaithful payment, etc., are the responsibility of the Tribe Leader, secretary of the Tribe, and the head of the Ministry of Enterprise for the relevant period.</p>
                  <p>8. The head of the Ministry of Enterprise of the church should check the monthly financial gains and losses the enterprise has gained through enterprise operations to ensure that the enterprise is operating legally and not negligently and report it to the Ministry of Enterprise of the Tribe.</p>
                  <p>9. The head of the Ministry of Enterprise of the Tribe/Church must thoroughly manage/supervise the operation of the enterprise of the Tribe/Church to ensure that illegal transactions between members, immoral sales, and unfair profiteering do not occur. If one finds such a situation, one must immediately report it to the relevant Ministry head, secretary, Tribe/Church leader, and take appropriate measures to prevent such an act.</p>
              </section>
              <section id="healthWelfare">
                <h2>24. The Duty of the Head of the Ministry of Health and Welfare</h2>
                <br></br>
                  <p>1. The head of the Ministry of Health and Welfare works for the development of Shincheonji by exchanging/communicating with the Ministry of Health and Welfare of the 12 tribes, examining the living situation of the people of duty and the congregation, and establishing a goal to ensure their health and welfare by creating solutions to the problems they face.</p>
                  <p>2. The Ministry of Health and Welfare can be divided into the health sector and the welfare sector. It oversees the safety and health of members, the welfare of people of duty, and the affairs and administration of the welfare of members.</p>
                  <p>3. The head of the Ministry of Health and Welfare oversees the work and administration in the health field so that members can lead a safe and healthy life of faith.</p>
                  <p>4. The head of the Ministry of Health and Welfare oversees affairs and administration for the welfare of the people of duty so that the people of duty of Shincheonji can focus more on the completion of God’s Work. Moreover, he must grasp the reality of people of duty and believers who are suffering from difficulties, discuss countermeasures, and seek solutions to solve them.</p>
                  <p>5. The head of the Ministry of Health and Welfare oversees welfare-related tasks and administration to ensure that people of duty and members do not experience difficulties in their life of faith through researching welfare-related specialists and connecting/improving welfare services.</p>
                  <p>6. The head of the Ministry of Health and Welfare must love his neighbors as himself through obedience, love, and forgiveness, and help build God’s new kingdom, the kingdom of heaven, with righteousness.</p>
              </section>
              <section id="transportation">
                <h2>25. The Duty of the Head of the Ministry of Service and Transportation</h2>
                <br></br>
                  <p>1. The Ministry of Service and Transportation plays a role in revitalizing social contribution projects through external volunteer activities, correcting society’s distorted views on Shincheonji, and creating a communication channel between Shincheonji and society transcending race, religion, and national boundaries.</p>
                  <p>2. The Ministry of Service and Transportation establishes/organizes each team according to the guidelines and manages/supervises the work strategy and planning of each team to produce mutually comprehensive effects within the Shincheonji law and order.</p>
                  <p>3. The Tribe/church Ministry of Service and Transportation establishes the annual plan for the Tribe/church based on the annual work plan of the General Assembly, and the Ministry of Service and Transportation of the Tribe headquarters holds a monthly meeting with those in charge of the work in branch churches to strengthen the capacity of people of duty in branch churches.</p>
                  <p>4. The Ministry of Service and Transportation establishes/reports the transportation and parking plans for internal and external guests and congregation during General Assembly events, and actively and cooperatively follows the General Assembly’s instructions.</p>
                  <p>5. The Ministry of Service and Transportation convenes the necessary workers from each Tribe to establish the Ministry of Protocol and Transportation and carry out the work if it is necessary to establish a plan for the movement of external guests during the General Assembly event.</p>
                  <p>6. Since the vehicle is church property, the Ministry of Service and Transportation must report it to the General Assembly through the Tribe in case of purchase, disposal, transfer, or donation, so that it can be processed after receiving approval from the Chairman.</p>
                  <p>7. The head of the Ministry of Service and Transportation thoroughly manages/supervises property such as vehicles and equipment managed by the Ministry of Service and Transportation, as well as videos, photos, and records so that they are not damaged/lost.</p>
              </section>
              <section id="diplomaticPolicy">
                <h2>26. The Duty of the Head of the Ministry for Foreign Policy</h2>
                <br></br>
                  <p>1. The Ministry of Foreign Policy sends workers for undisclosed external cooperation with Shincheonji and conducts various external activities.</p>
                  <p>2. The Ministry of Foreign Policy meets with political/religious and social figures to introduce Shincheonji, and to exchange cooperation.</p>
              </section>
          </Text>
        </ContentWithPaddingXl>
      </Container>
    </AnimationRevealPage>
  );
};