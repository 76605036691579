import * as React from 'react';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';

export default ({ totalLength, pageSize, onChange}) => {
    const count = Math.ceil(totalLength/pageSize)

    return (
        <div>
            <Stack spacing={2}>
                <br />
                <Pagination color={'standard'} count={count} onChange={onChange}/>
            </Stack>
        </div>
    );
};