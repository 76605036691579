import React, { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import tw from "twin.macro";
import styled from "styled-components";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Playlists from "components/cards/Videos.js";
import { PrimaryButton } from "../../../components/misc/Buttons.js";
import Header, { NavLink, NavLinks, PrimaryLink as PrimaryLinkBase, LogoLink, NavToggle, DesktopNavLinks } from "../../../components/headers/light.js";
import icon from "../../../images/lra.svg";

const StyledHeader = styled(Header)`
  ${tw`p-0 max-w-none w-full`}
  ${LogoLink} {
    ${tw`text-black hover:border-gray-300 hover:text-gray-300`}
  }
`;

export default (
  playlist=""
) => {
  const { url } = useParams();
  playlist = url;
  const tables = ["Instructor Training (SCJ Year 39, Steel White Horse Unit Eduacation)", "12 Tribes Church Head, Instructor, Evangelist Special Education"]
  return (
    <AnimationRevealPage>
      <StyledHeader links={[]} logoIcon={icon} />
      <Playlists heading={playlist.startsWith("instructor") ? tables[0] : tables[1]} table={playlist}/>
    </AnimationRevealPage>
  )
}
