import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line

import { SectionHeading } from "components/misc/Headings";
import Header, { NavLink, NavLinks, PrimaryLink as PrimaryLinkBase, LogoLink, NavToggle, DesktopNavLinks } from "../../components/headers/light.js";
import icon from "../../images/lra.svg"
import { PrimaryButton } from "../../components/misc/Buttons.js"
import Watermark from "helpers/Watermark.js";

const HeadingRow = tw.div`flex justify-between items-center sm:flex-col md:flex-row`;
const Heading = tw.h2`text-2xl sm:text-4xl font-black tracking-wide text-center text-gray-900`;
const Text = styled.div`
  ${tw`text-lg  text-gray-800`}
  p {
    ${tw`mt-2 leading-loose`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
  cursor: grab;
  user-select: none;
`;

const ButtonContainer = tw.div`flex justify-end`
const LoadMoreButton = tw(PrimaryButton)``
const StyledHeader = styled(Header)`
  ${tw`p-0 max-w-none w-full`}
  ${LogoLink} {
    ${tw`text-black hover:border-gray-300 hover:text-gray-300`}
  }
`;
const PrimaryLink = tw(PrimaryLinkBase)`rounded-full`
const HighlightedText = tw.span`bg-primary-500 text-gray-100 px-4 transform -skew-x-12 inline-block`;

export default ({ 
  headingText = "The Mindset of the worker of duty of Shincheonji",
  navLinks = [],
}) => {
  return (
    <AnimationRevealPage>
      <StyledHeader links={navLinks} logoIcon={icon} />
      <Container>
        <ContentWithPaddingXl>
          <HeadingRow>
            <Heading>{<><HighlightedText>{headingText}</HighlightedText></>}</Heading>
          </HeadingRow>
          <Text>
            <Watermark></Watermark>
            <p>
              The Shincheonji worker of duty thinks and thinks about the appointed work to fulfill the duty faithfully whenever awake, eating, or walking in the street, drawing up scenarios about the work, whether sitting or standing.
            </p>
            <p>
              When I look at Creation, when I look at everything in the world, when I look at the sky and the earth, and when I look at everything on Television, I must gain wisdom about my work.
            </p>
            <p>
              Making it shine, better, newer, is what you believe, and it is to repay God's grace even if it is ten thousandth of a percent, and it is to honor God. If it goes wrong even a thousand times, it must be corrected.
            </p>
            <p>
              I have to go and see if there's anything that can help me to do my work better and we must learn from the whole creation. This is because it was created with the bright wisdom of God. If the work of my duty is poor, the light of God becomes dark.
            </p>
            <p>
              My duty must be created such that God will be pleased more than anything, the best. This is my life. I have to make it more precious than my life. If I have ears and a heart, I will do it like this.
            </p>
            <br></br>
            <p>
              <i>15th of Nov SCJ 32 - Chairman's special instruction</i>
            </p>
          </Text>
        </ContentWithPaddingXl>
      </Container>
    </AnimationRevealPage>
  );
};
