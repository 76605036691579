import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import tw from "twin.macro";
import styled from "styled-components";
import supabase from "helpers/SupabaseClient";

const Container = tw.div`relative`;
const Content = tw.div`max-w-screen-xl mx-auto`;
const HeadingRow = tw.div`flex justify-between items-center sm:flex-col md:flex-row`;
const Heading = tw.h2`text-2xl sm:text-4xl font-black tracking-wide text-center text-gray-900`;
const HighlightedText = tw.span`bg-primary-500 text-gray-100 px-4 transform -skew-x-12 inline-block`;
const SearchContainer = tw.div`flex justify-start`
const Input = tw.input`w-full px-6 py-3 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-5 first:mt-0`;



export default ({heading}) => {

    const [logs, setLogs] = useState([])

    useEffect(() => {

    }, [])

    return (
        <Container>
            <Content>
                <HeadingRow>
                  <Heading>
                    <HighlightedText>{heading}</HighlightedText>
                  </Heading>

                </HeadingRow>
            </Content>
        </Container>
    )
}