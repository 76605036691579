import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import Footer from "components/footers/MiniCenteredFooter.js";
import { SectionHeading } from "components/misc/Headings";
import Header, { NavLink, NavLinks, PrimaryLink as PrimaryLinkBase, LogoLink, NavToggle, DesktopNavLinks } from "../components/headers/light.js";
import icon from "../images/lra.svg"
import { PrimaryButton } from "../components/misc/Buttons.js"

const HeadingRow = tw.div`flex justify-between items-center sm:flex-col md:flex-row`;
const Heading = tw(SectionHeading)`text-gray-900 mb-10`;
const Text = styled.div`
  ${tw`text-lg  text-gray-800`}
  p {
    ${tw`mt-2 leading-loose`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;

const StyledHeader = styled(Header)`
  ${tw`p-0 max-w-none w-full`}
  ${LogoLink} {
    ${tw`text-black hover:border-gray-300 hover:text-gray-300`}
  }
`;
const PrimaryLink = tw(PrimaryLinkBase)`rounded-full`
const HighlightedText = tw.span`bg-primary-500 text-gray-100 px-4 transform -skew-x-12 inline-block`;

export default ({ 
  headingText = "Disclaimer",
  navLinks = [],
}) => {
  return (
    <AnimationRevealPage>
      <StyledHeader links={navLinks} logoIcon={icon} />
      <Container>
        <ContentWithPaddingXl>
          <HeadingRow>
            <Heading>{<><HighlightedText>{headingText}</HighlightedText></>}</Heading>
          </HeadingRow>
          <Text>
            <p>
              All content and data including, but not limited to, know-how, text, graphics, icons, hyperlinks, private information, designs, publication, process, or ideas contained on this website may be the subject of other rights, including other intellectual property rights, which are the property of <strong>Shincheonji Church of Jesus, the Temple of the Tabernacle of the Testimony</strong>.
            </p>
            <p>
              Access to the aforementioned content and data, belonging to <strong>Shincheonji Church of Jesus</strong>, through this website is limited/restricted to the registered congregation members of <strong>Shincheonji Church of Jesus</strong>, and shall be viewed, accessed, applied or used by no other unauthorised persons for any reason.
            </p>
            <p>
              All content and data on this website is therefore protected from infringement by South African legislation.
            </p>
          </Text>
        </ContentWithPaddingXl>
      </Container>
    </AnimationRevealPage>
  );
};
