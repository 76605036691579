import { Session, User } from "@supabase/supabase-js";
import { useContext, useState, useEffect, createContext } from "react";
import supabase from "./SupabaseClient.js";
import * as SupabaseAPI from "helpers/SupabaseAPI.js"

const AuthContext = createContext({ session: null, user: null, signOut: () => {} });

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [session, setSession] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const setData = async () => {
            const { data: { session }, error } = await supabase.auth.getSession();
            if (error) throw error;
            setSession(session)
            setUser(session?.user)
            setLoading(false);
        };

        const { data: listener } = supabase.auth.onAuthStateChange((_event, session) => {
            setSession(session);
            setUser(session?.user)
            setLoading(false);
            logUser(_event, session);
        });

        setData();

        return () => {
            listener?.subscription.unsubscribe();
        };
    }, []);

    const value = {
        session,
        user,
        signOut: () => supabase.auth.signOut(),
    };

    // use a provider to pass down the value
    return (
        <AuthContext.Provider value={value}>
            {!loading && children}
        </AuthContext.Provider>
    );
};

// export the useAuth hook
export const useAuth = () => {
    return useContext(AuthContext);
};

export const SignOut = async () => {
    await supabase.auth.signOut();
}

export const Home = async () => {
    window.location.href = "/home";
}

export const Finance = async () => {
    window.location.href = "/tithes-and-offerings";
}

export const HeavenlyInbox = async () => {
    window.location.href = "/heavenly-inbox";
}

export const Profile = async () => {
    window.location.href = "/profile";
}

export const  Login = async (scj_id, password) => {
    try {

        localStorage.setItem('scj_id', scj_id);

        const { data, err } = await supabase
            .from("access_report")
            .select("*")
            .eq('registration_number', scj_id);

        if (data.length === 0){
            return [{
                result: "Login Failure",
                message: "User doesn't exist.",
            }];
        } else if (data[0].Access === false){
            return [{
                result: "Technical Error",
                message: `The Registration Number '${scj_id}' is Invalid.\n\nIf you believe this is an error, please inform your structure.`,
            }];
        } else {

            const email = data[0].email;
            
            const res = await supabase.auth.signInWithPassword({
                email,
                password
            })

            if (!!res.data.user){
                return [{
                    result: "Success",
                    message: "Successfully Logged in. Redirecting to Home page.",
                }];
            } 
            else 
            {
                return [{
                    result: "Login Failure", 
                    message: res.error.message,
                }];
            }
        }

   } catch (error) {
        console.error(error);

    }
}

export const getUserInfo = async (email) => {
    try {
        const { data, err } = await supabase
            .from("access_report")
            .select("*")
            .eq('email', email);
        
        return data[0];
    } catch (error) { }
}

export const getUserData = async (scj_id) => {
    try {
        const { data, err } = await supabase
            .from("access_report")
            .select("*")
            .eq('registration_number', scj_id);
        
        return data[0];
    } catch (error) { }
}

export const Register = async (userdata) => {
    const user = await getUserData(userdata.scj_id)
    const userExists = user != undefined ? true : false;
    if (userExists == false){
        return [ {
            result: "Failure",
            message: "User Doesn't Exist. Contact Structure to Register.",
        }];
    }
    try {
        const { data, error } = await supabase.auth.signUp({
          email: userdata.email,
          password: userdata.password,
        }).catch((err) => {
            return [ {
                result: "Failed to capture registration.",
                message: `User registration failed with message: ${err.message}`,
            }];
        });

        if (!!user.email){
            return [ {
                result: "Failure",
                message: "User Already Registered. Please Login.",
            }];
        }
        
        if (data.user !== null){
           const { data, error } = await supabase
            .from('access_report')
            .update({ email: userdata.email })
            .eq('registration_number', userdata.scj_id)
            .select()


            window.location.href = "/verify-email";

            return [{
                result: "Success",
                message: "Successfully Registered.",
            }];
        } else {
            return [{
                result: "Failure", 
                message: "Incorrect ID. Please Check Your ID Number",
            }];

        }
    } catch (error) { }
}

export const ResetPassword = async (id) => {
    try {
        const { data, err } = await supabase
            .from("access_report")
            .select("*")
            .eq('registration_number', id);
        
        
        if (data.length > 0){

            const email = data[0].email;
            const { data: reset, error } = await supabase.auth
                .resetPasswordForEmail(email, {
                    redirectTo: `${process.env.REACT_APP_PROD_URL}/reset-password`
                });

            return true;
        }
            
    } catch (error) {
        return false;
     }
}

export const RecoverPassword = async (newPassword) => {

    const { data, error } = await supabase.auth
        .updateUser({ password: newPassword })

    if (data.user != null) {
        return { state: true, error: null }
    }

    if (error != null) {
        return { state: false, error: error.message }
    }
}

export const checkWodAccess = async (email) => {
    try {
        const { data, err } = await supabase
            .from("access_report")
            .select("WOD_page_access")
            .eq('email', email);
        return data[0];
    } catch (error) { }
}

export const hasSession = async () => {
    const { data, error } = await supabase.auth.getSession();
    if (data) return true;
    if (error) return false;
}

export const allowWodAccess = async (page, password) => {
    const pages = {
        "admin-and-general-affairs": "Administration and General Affairs",
        "ministry-of-theology": "Ministry of Theology",
        "internal-affairs": "Internal Affairs"
    }

    try {
        const { data, error } = await supabase
            .from("wod_passwords")
            .select("*")
            .eq('page', pages[page]);

        if (data[0].password === password){
            switch (pages[page]) {
                case "Administration and General Affairs":
                    window.location.href = "/admin-and-general-affairs/";
                    break;
                case "Ministry of Theology":
                    window.location.href = "/ministry-of-theology/";
                    break;
                case "Internal Affairs":
                    window.location.href = "/internal-affairs/";
                    break;
                default:
                    break;
            }
            return [{
                result: "Successful", 
                message: "Redirecting to Page",
            }];
        }
        return [{
            result: "Unsuccessful", 
            message: "Incorrect Password, Please try again.",
        }];
        
    } catch (error) {
        
    }
}

export const logUser = async(_event, session) => {
    if (_event === 'SIGNED_IN') {
        const res = await supabase.functions.invoke("log_user_location", {body: {session}})
        console.log(res)
    } 
}