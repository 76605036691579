import supabase from "helpers/SupabaseClient";

export const firstRegistrationData = [
    { index: 1, region: 'PE', percentage: Math.floor(77) },
    { index: 2, region: 'JHB', percentage: Math.floor(70) },
    { index: 3, region: 'CPT', percentage: Math.floor(65) },
    { index: 4, region: 'DBN', percentage: Math.floor(62) },
    { index: 5, region: 'PMB', percentage: Math.floor(60) },
    { index: 6, region: 'RTB', percentage: Math.floor(59) },
    { index: 7, region: 'ZIM', percentage: Math.floor(55) },
    { index: 8, region: 'NAM', percentage: Math.floor(52) },
    { index: 9, region: 'LLW', percentage: Math.floor(44) },
    { index: 10, region: 'BLZ', percentage: Math.floor(43) },
];

export const secondRegistrationData = [
    { index: 1, region: 'DBN', percentage: Math.floor(85) },
    { index: 2, region: 'CPT', percentage: Math.floor(84) },
    { index: 3, region: 'JHB', percentage: Math.floor(80) },
    { index: 4, region: 'PMB', percentage: Math.floor(77) },
    { index: 5, region: 'PE', percentage: Math.floor(60) },
    { index: 6, region: 'RTB', percentage: Math.floor(59) },
    { index: 7, region: 'ZIM', percentage: Math.floor(55) },
    { index: 8, region: 'NAM', percentage: Math.floor(52) },
    { index: 9, region: 'LLW', percentage: Math.floor(44) },
    { index: 10, region: 'BLZ', percentage: Math.floor(43) },
];

export const ebttQualifiedData = [
    { index: 1, region: 'JHB', percentage: Math.floor(77) },
    { index: 2, region: 'PE', percentage: Math.floor(70) },
    { index: 3, region: 'CPT', percentage: Math.floor(65) },
    { index: 4, region: 'DBN', percentage: Math.floor(62) },
    { index: 5, region: 'PMB', percentage: Math.floor(60) },
    { index: 6, region: 'RTB', percentage: Math.floor(59) },
    { index: 7, region: 'ZIM', percentage: Math.floor(55) },
    { index: 8, region: 'NAM', percentage: Math.floor(52) },
    { index: 9, region: 'LLW', percentage: Math.floor(44) },
    { index: 10, region: 'BLZ', percentage: Math.floor(43) },
];

export const dummyTableData = [
    {group: "Men's Group    ", results: [
        {region: "NAM", fruits: 5},
        {region: "CPT", fruits: 5},
        {region: "DUR", fruits: 5}
    ]},
    {group: "Women's Group", results: [
        {region: "BFK", fruits: 5},
        {region: "JHB", fruits: 5},
        {region: "PE", fruits: 5}
    ]},
    {group: "Youth Group", results: [
        {region: "CPT", fruits: 5},
        {region: "BFK", fruits: 5},
        {region: "JHB", fruits: 5}
    ]},
    {group: "Seniors' Group", results: [
        {region: "DUR", fruits: 5},
        {region: "LLW", fruits: 5},
        {region: "NAM", fruits: 5}
    ]}
]

export default async function fruitWhispererData() {
    var result = {
        conventional: null,
        pastors: null
    }

    try {
        const data = await supabase.from('conventional_best_networker').select('*');
        console.log(data);
        result.conventional = data;

        const data2 = await supabase.from('pastor_best_networker').select('*');
        console.log(data2);
        result.pastors = data2;
    } catch (error) {
        console.error('Error fetching data:', error);
    }

    return result;
}
