import styled from 'styled-components';
import tw from 'twin.macro'; // Import the tw utility class from twin.macro

const SquareContainer = styled.div`
  ${tw`w-16 h-16 bg-white text-primary-500 flex items-center justify-center rounded-md mr-4 border-2 border-primary-500`}
  transform: skewX(-10deg);
    border-radius: 0.2rem;

`;

const NumberText = styled.p`
  ${tw`text-2xl font-bold`}
`;

const NumberComponent = ({ number }) => {
  return (
    <SquareContainer>
      <NumberText>{number}</NumberText>
    </SquareContainer>
  );
};

export default NumberComponent;