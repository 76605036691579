import React, { useEffect, useState } from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import Footer from "components/footers/MiniCenteredFooter.js";
import { SectionHeading } from "components/misc/Headings";
import Header, { NavLink, NavLinks, PrimaryLink as PrimaryLinkBase, LogoLink, NavToggle, DesktopNavLinks } from "../components/headers/light.js";
import icon from "../images/lra.svg"
import { PrimaryButton } from "../components/misc/Buttons.js"
import Watermark from "helpers/Watermark.js";
import { useParams } from 'react-router-dom';
import supabase from "helpers/SupabaseClient";
import ReactMarkdown from 'react-markdown';
import FAQ from "components/faqs/FAQ.js";
import { getUserInfo, useAuth } from "helpers/Auth";


const HeadingRow = tw.div`flex justify-between items-center sm:flex-col md:flex-row`;
const Heading = tw(SectionHeading)`text-gray-900 mb-5`;
const Text = styled.div`
  ${tw`text-lg  text-gray-800`}
  p {
    ${tw`mt-2 leading-loose`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;

const StyledHeader = styled(Header)`
  ${tw`p-0 max-w-none w-full`}
  ${LogoLink} {
    ${tw`text-black hover:border-gray-300 hover:text-gray-300`}
  }
`;
const PrimaryLink = tw(PrimaryLinkBase)`rounded-full`
const HighlightedText = tw.span`bg-primary-500 text-gray-100 px-4 transform -skew-x-12 inline-block`;

export default ({ 
  navLinks = [],
}) => {
  const { user } = useAuth();
  const [stats, setStats] = useState([]);

  useEffect(() => {
    async function fetchData() {
      try {
        const userdata = await getUserInfo(user.email);
        const { data, error } = await supabase
          .from('offering_status')
          .select('*')
          .eq('registration_number', userdata.registration_number)
          .order('id', {ascending: true});
        console.log(data);
        
        const excludeFields = ['id', 'registration_number', 'region'];
        var fin_data = [];
        Object.keys(data[0]).forEach((key) => {
          console.log(key);
          if (!excludeFields.includes(key) && data[0][key] !== null) {
            fin_data.push({ month: sentenceCase(key), offerings: data[0][key].split(',') });
          }
        })

        const words = fin_data.map(item => ({ question: item.month, answer: item.offerings }));

        console.log(words);
        setStats(words);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }
    fetchData();
  }, []);

  function sentenceCase(str) {
    return str
      .toLowerCase()
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  }

  return (
    <AnimationRevealPage>
      <StyledHeader links={navLinks} logoIcon={icon} />
      <Container>
        <ContentWithPaddingXl>
          <HeadingRow>
            <Heading>{<><HighlightedText>Heavenly Inbox</HighlightedText></>}</Heading>
          </HeadingRow>
          <Text>
            <Watermark></Watermark>
            {/* <FAQ faqs={stats} heading="Months"/> */}
          </Text>
          <p tw="mt-8 text-sm text-gray-600 text-center">
            Please contact structure if there is any information that needs to be updated.
          </p>
        </ContentWithPaddingXl>
      </Container>
    </AnimationRevealPage>
  );
};
