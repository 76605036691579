import React, { useState, useEffect } from "react";
import InputMask from "react-input-mask";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container as ContainerBase } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import {css} from "styled-components/macro"; //eslint-disable-line
import logo from "images/lra_pink.svg";
import { ReactComponent as LoginIcon } from "feather-icons/dist/icons/log-in.svg";
import { ReactComponent as RegisterButtonIcon } from "feather-icons/dist/icons/book-open.svg";
import { RecoverPassword, ResetPassword } from "helpers/Auth";

const Container = tw(ContainerBase)`min-h-screen bg-primary-900 text-white font-medium flex justify-center -m-8`;
const Content = tw.div`max-w-screen-xl m-0 sm:mx-20 sm:my-16 bg-white text-gray-900 shadow sm:rounded-lg flex justify-center flex-1`;
const MainContainer = tw.div`lg:w-1/2 xl:w-5/12 p-6 sm:p-12`;
const LogoLink = tw.a``;
const LogoImage = tw.img`h-12 mx-auto`;
const MainContent = tw.div`mt-12 flex flex-col items-center`;
const Heading = tw.h1`text-2xl xl:text-3xl font-extrabold`;
const FormContainer = tw.div`w-full flex-1 mt-8`;

const SocialButtonsContainer = tw.div`flex flex-col items-center`;
const SocialButton = styled.a`
  ${tw`w-full max-w-xs font-semibold rounded-lg py-3 border text-gray-900 bg-gray-100 hocus:bg-gray-200 hocus:border-gray-400 flex items-center justify-center transition-all duration-300 focus:outline-none focus:shadow-outline text-sm mt-5 first:mt-0`}
  .iconContainer {
    ${tw`bg-white p-2 rounded-full`}
  }
  .icon {
    ${tw`w-4`}
  }
  .text {
    ${tw`ml-4`}
  }
`;

const DividerTextContainer = tw.div`my-12 border-b text-center relative`;
const DividerText = tw.div`leading-none px-2 inline-block text-sm text-gray-600 tracking-wide font-medium bg-white transform -translate-y-1/2 absolute inset-x-0 top-1/2 bg-transparent`;

const Form = tw.form`mx-auto max-w-xs`;
const Input = tw.input`w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-5 first:mt-0`;
const SubmitButton = styled.button`
  ${tw`mt-5 tracking-wide font-semibold bg-primary-500 text-gray-100 w-full py-4 rounded-lg hover:bg-primary-900 transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none`}
  .icon {
    ${tw`w-6 h-6 -ml-2`}
  }
  .text {
    ${tw`ml-3`}
  }
`;
const IllustrationContainer = tw.div`sm:rounded-r-lg flex-1 bg-purple-100 text-center hidden lg:flex justify-center`;
const IllustrationImage = styled.div`
  ${props => `background-image: url("${props.imageSrc}");`}
  ${tw`m-12 xl:m-16 w-full max-w-sm bg-contain bg-center bg-no-repeat`}
`;

export default ({
  mode="",
  logoLinkUrl = "#",
  headingText = (mode === "reset") ? "Reset Password" : "Forgot Password",
  submitButtonText = (mode === "reset") ? "Reset Password" : "Send Reset Email",
  SubmitButtonIcon = LoginIcon,
  signupUrl = "/register",

}) => {
  const [id, setId] = useState("");
  const [error, setErrors] = useState("");
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showValidation, setShowValidation] = useState(false);
  const [isLengthValid, setLengthValid] = useState(false);
  const [hasNumber, setHasNumber] = useState(false);
  const [hasUppercase, setHasUppercase] = useState(false);
  const [hasLowercase, setHasLowercase] = useState(false);
  const [passwordsMatch, setPasswordsMatch] = useState(true);
  const [resetPasswordEmailSent, setResetPassowordEmailSent] = useState('');
  const [waitingForEmailResponse, setWaitingForEmailResponse] = useState(false);
  const [resetPasswordMessage, setResetPasswordMessage] = useState({});

  const handlePasswordChange = (event) => {
    const newPassword = event.target.value;
    setPassword(newPassword);
    setLengthValid(newPassword.length >= 8);
    setHasNumber(/\d/.test(newPassword));
    setHasUppercase(/[A-Z]/.test(newPassword));
    setHasLowercase(/[a-z]/.test(newPassword));
    setPasswordsMatch(newPassword === confirmPassword);
  };

  const handleConfirmPasswordChange = (event) => {
    const newConfirmPassword = event.target.value;
    setConfirmPassword(newConfirmPassword);
    setPasswordsMatch(password === newConfirmPassword);
  };

  const handlePasswordFocus = () => {
    setShowValidation(true);
  };

  const validateID = (scj_id) => {
    const regex = /^\d{8}-\d{5}$/;
    return regex.test(scj_id);
  }

  const handleReset = async (event) => {
    setWaitingForEmailResponse(true);
    event.preventDefault();
    if (validateID(id)){
      console.log("valid.");
      var res = await ResetPassword(id);
      console.log(ResetPassword);
      var resetEmailSent = res == true? 'success': 'failed'
      setResetPassowordEmailSent(resetEmailSent)
    } else {
      console.log("invalid");
    }
    setWaitingForEmailResponse(false);
  }

  const handlePasswordReset = async (event) => {
    setWaitingForEmailResponse(true);
    setResetPassowordEmailSent({});

    event.preventDefault();
    var res = await RecoverPassword(password);
    setResetPasswordMessage(res);
    console.log(res)

    if (res.state == true) {
      setTimeout(() => {
        window.location.href = '/login'
      }, 2000);
    }
    setWaitingForEmailResponse(false);

  }

  const isFormValid = passwordsMatch && isLengthValid && hasNumber && hasLowercase && hasUppercase; 

  return(
    <AnimationRevealPage>
      <Container>
        <Content>
          <MainContainer>
            <LogoLink href={logoLinkUrl}>
              <LogoImage src={logo} />
            </LogoLink>
            <MainContent>
              <Heading>{headingText}</Heading>
              <FormContainer>
                  { (mode === "reset") ? 
                    <Form onSubmit={handlePasswordReset}>
                      <Input 
                        type="password" 
                        placeholder="Password"
                        value={password}
                        onChange={handlePasswordChange} 
                        onFocus={handlePasswordFocus}
                        required
                      />
                      { showValidation && (
                        <div>
                          <span style={{ color: isLengthValid ? 'green' : 'red' }}> At least 8 characters</span>
                          <br />
                          <span style={{ color: hasNumber ? 'green' : 'red' }}> At least 1 number</span>
                          <br />
                          <span style={{ color: hasUppercase ? 'green' : 'red' }}> At least 1 uppercase letter</span>
                          <br />
                          <span style={{ color: hasLowercase ? 'green' : 'red' }}> At least 1 lowercase letter</span>
                        </div>
                      )}
                      <Input 
                        type="password" 
                        placeholder="Confirm Password"
                        value={confirmPassword}
                        onChange={handleConfirmPasswordChange} 
                        required
                      />
                      { showValidation && (
                        <div>
                          <span style={{ color: passwordsMatch ? 'green' : 'red' }}> Passwords Match</span>
                        </div>
                      )}
                    {
                      resetPasswordMessage.state == true && <span style={{ color: 'green' }}>Password Reset Successfully. Redirecting to Login.</span>
                    }

                    {
                      resetPasswordMessage.state == false && <span style={{ color: 'red' }}>Password Reset Failed. {resetPasswordMessage["error"]}</span>
                    }
                      <SubmitButton type="submit" disabled={!isFormValid}>
                        <SubmitButtonIcon className="icon" />
                        {/* <span className="text">{submitButtonText}</span> */}
                        <span className="text">{waitingForEmailResponse == true ? "Processing..." : submitButtonText}</span>
                      </SubmitButton>
                    </Form> : 
                    <Form onSubmit={handleReset}>
                      <InputMask mask={"99999999-99999"} type="text" value={id} onChange={(e) => setId(e.target.value)}  maskChar={null} maskPlaceholder={null}>
                        <Input type="scj_id" placeholder="ID Number (00400314-12345)" required/>
                      </InputMask>
                    {
                      resetPasswordEmailSent == 'success' && <span style={{ color: 'green', textAlign: "center"}}> Reset password email sent. Please check your mail.</span>
                    }

                    {
                      resetPasswordEmailSent == 'failed' && <span style={{ color: 'red', textAlign: "center" }}> Reset password email failed to send. Please wait a minute and try again.</span>
                    }
                      {resetPasswordEmailSent == 'success' && (submitButtonText = "Email Sent")}
                      <SubmitButton type="submit">
                        <SubmitButtonIcon className="icon" />
                        {console.log(submitButtonText)}
                        <span className="text">{waitingForEmailResponse == true ? "Processing..." : submitButtonText}</span>
                      </SubmitButton>
                    </Form> 
                  }

                <DividerTextContainer></DividerTextContainer>
                <p tw="mt-8 text-gray-600 text-center">
                  Don't have an account?{" "}
                  <a href={signupUrl}>
                    <SubmitButton>
                      <RegisterButtonIcon className="icon" />
                      <span className="text">{"Register"}</span>
                    </SubmitButton>
                  </a>
                </p>
              </FormContainer>
            </MainContent>
          </MainContainer>
        </Content>
      </Container>
    </AnimationRevealPage>
  );
}
