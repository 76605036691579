import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import Footer from "components/footers/MiniCenteredFooter.js";
import { SectionHeading } from "components/misc/Headings";
import Header, { NavLink, NavLinks, PrimaryLink as PrimaryLinkBase, LogoLink, NavToggle, DesktopNavLinks } from "../../components/headers/light.js";
import icon from "../../images/lra.svg"
import { PrimaryButton } from "../../components/misc/Buttons.js"
import Watermark from "helpers/Watermark.js";

const HeadingRow = tw.div`flex justify-between items-center sm:flex-col md:flex-row`;
const Heading = tw.h2`text-2xl sm:text-4xl font-black tracking-wide text-center text-gray-900`;
const Text = styled.div`
  ${tw`text-lg  text-gray-800`}
  p {
    ${tw`mt-2 leading-loose`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ol {
    ${tw`list-decimal list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3 list-item`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
  cursor: grab;
  user-select: none;
`;

const ButtonContainer = tw.div`flex justify-end`
const LoadMoreButton = tw(PrimaryButton)``
const StyledHeader = styled(Header)`
  ${tw`p-0 max-w-none w-full`}
  ${LogoLink} {
    ${tw`text-black hover:border-gray-300 hover:text-gray-300`}
  }
`;

const Testimonial = tw.div`flex! flex-col items-center md:items-stretch md:flex-row md:justify-center outline-none`;
const ImageContainer = styled.div`
  ${tw`md:mx-3 lg:mx-6 w-2/3 md:w-4/12 rounded flex items-center max-w-xs md:max-w-none`}
  img {
    ${tw`rounded`}
  }
`;
const TextContainer = tw.div`md:mx-3 lg:mx-6 md:w-6/12 py-4 flex flex-col justify-between`;
const QuoteContainer = tw.div`relative p-6 md:p-8 lg:p-10 mt-4 md:mt-0`;
const Quote = tw.blockquote`text-center md:text-left font-medium text-xl lg:text-2xl xl:text-3xl`;
const CustomerInfo = tw.div`px-5 lg:px-10 text-center md:text-left mt-4 md:mt-0`;
const CustomerName = tw.h5`font-bold text-lg lg:text-xl xl:text-2xl text-primary-500`;
const CustomerTitle = tw.p`font-medium text-sm`;
const HighlightedText = tw.span`bg-primary-500 text-gray-100 px-4 transform -skew-x-12 inline-block`;

export default ({ 
  headingText = "Chapter 2",
  navLinks = [],
}) => {
  return (
    <AnimationRevealPage>
      <StyledHeader links={navLinks} logoIcon={icon} />
      <Container>
        <ContentWithPaddingXl>
          <HeadingRow>
            <Heading>{<><HighlightedText>{headingText}</HighlightedText></>}</Heading>
          </HeadingRow>
          <Text>
            <Watermark></Watermark>
            <h1>The 12 Tribes</h1>
            <section id="tableOfContents">
              <h2>Table of Contents</h2>
              <br></br>
              <ol>
                <li><a href="#tribeLeader">The Duty of 12 Tribe Leaders</a></li>
                <li><a href="#churchHead">The Duty of Church Head</a></li>
                <li><a href="#generalManager">The Duty of Tribe(Church) General Manager</a></li>
                <li>
                  <a href="#missionCenter">The Duty of Workers of Duty of Mission Center</a>
                  <ul>
                    <li><a href="#missionCenter_1">4-1. The Duty of Center Director</a></li>
                    <li><a href="#missionCenter_2">4-2. The Duty of (Head) Instructor</a></li>
                    <li><a href="#missionCenter_3">4-3. The Duty of (Head) Evangelist</a></li>
                  </ul>
                </li>
                <li>
                  <a href="#group">The Duty of Workers of Duty of Group</a>
                  <ul>
                    <li><a href="#group_1">5-1. The Duty of Group Leader</a></li>
                    <li><a href="#group_2">5-2. The Duty of the General Manager in each Group</a></li>
                    <li><a href="#group_3">5-3. The Duty of the Head of Ministry of Education in each Group</a></li>
                    <li><a href="#group_4">5-4. The Duty of the Head of Ministry of Evangelism in each Group</a></li>
                    <li><a href="#group_5">5-5. The Duty of the Head of Ministry of Culture in each Group</a></li>
                    <li><a href="#group_6">5-6. The Duty of the Head of Ministry of Liaison in each Group</a></li>
                    <li><a href="#group_7">5-7. The Duty of Secretary and Accounting Secretary in each Group</a></li>
                    <li><a href="#group_8">5-8. The Duty of Evangelism Supervisor</a></li>
                    <li><a href="#group_9">5-9. The Duty of Team Leader</a></li>
                    <li><a href="#group_10">5-10. The Duty of Cell Group Leader</a></li>
                    <li><a href="#group_11">5-11. The Duty of the Head of Student’s Group</a></li>
                    <li><a href="#group_12">5-12. The Duty of the Head of Children’s Group</a></li>
                  </ul>
                </li>
              </ol>
            </section>
              
              <section id="tribeLeader">
                <h2>1. The Duty of 12 Tribe Leaders</h2>
                <br></br>
                  <p>1. 12 tribe leaders of 12 tribes which are created according to the Bible on this earth should follow the will of CHJN, and obey to the promised pastor CHJN, the same as 12 disciples who became the foundation stones of holy city, the temple of Heaven obey to Jesus.</p>
                  <p>2. 12 tribe leaders should protect and rule over the tribe, people and assets with their lives same as the 12 disciples of Heaven who martyred.</p>
                  <p>3. 12 tribe leaders must flow the instruction to each church and group according to the working instruction of HQ and must report the events to CHJN through HQ and establish the structure of the tribe as the army of Heaven that will conquer all nations.</p>
                  <p>4. Tribe leaders should establish church, missionary center and BB, and must appoint instructor, evangelist and group leaders to govern the people, nurture and educate workers of duty, exercise the right of approval in all tribe tasks.</p>
                  <p>5. The duty of each tribe leader (church head) is to seal saints who are harvested 100%. Each tribe must teach the prophecy of the Revelation of New Testament and the fulfilled reality. It is said that we have to each like finding the answer in Math. This is the work of sealing and there should be over 12,000 people who are sealed (over 90 points), not the number of members. This is for the saints not to add or subtract from Revelation. If one adds or subtracts, one cannot enter into Heaven (Word from the JSS article)</p>
                  <p>6. ‘I’m tribe leader, I’m church head’ doesn’t make you go to Heaven. Each tribe leader (Church head) must seal and be sealed as well (Word from JSS article).</p>
                  <p>7. Each tribe leader should spread the eternal heavenly culture which is the embodiment of the word so that the tribe can show the glory of God and Jesus.</p>
                  <p>8. Same as the Lord loves CHJN, CHJN loves 12 tribe leaders, so 12 tribe leaders also have to love and protect each other.</p>
              </section>
              <section id="churchHead">
                <h2>2. The Duty of Church Head</h2>
                <br></br>
                  <p>1. The duty of church head is to follow the command of the tribe leader, protect the church, saints and assets, rule over, and to develop</p>
                  <p>2. Church head should educate the public hierarchy for completing the duty as the army of Heaven, notify the instruction from tribe HQ to saints and report the result to tribe HQ.</p>
                  <p>3. Church head should establish the missionary center, BB, appoint instructor, evangelist, core workers of the church and group leaders to protect and govern the saints, educate the groups with the material from HQ, and exercise the right of approval in all tasks</p>
                  <p>4. Church head should educate the head of center, instructor, evangelists about management, governing of missionary center so that they can fulfill their duty, and make all the workers of duty run at the front line.</p>
                  <p>5. Church head should establish an organization for harvesting, create education and environment so that all the saints can evangelize. Church head should educate the saints with the word that is needed for the harvest and run in the front line (Mt 5:19).</p>
                  <p>6. Church head should train the BB teachers systematically, increase the number of BB teachers so that the BB teachers can fulfill the duty of harvest by educating fruits who are networked to BB at eye level with the material from HQ.</p>
                  <p>7. Church head should not raise him or herself but be lowered. (refer to Ez 28). Duty is life. Shincheonji is a worldwide organization where the Heaven and the earth became one. It is a place that is stronger than the army in the world that lives by orders and dies by orders, so they should obey absolutely and become one. (38.10.24 CHJN’s word for Church head)</p>
                  <p>8. Same as the tribe leader loves church head, church head also has to love those who belong to each church and make them love each other.</p>
              </section>
              <section id="generalManager">
                <h2>3. The Duty of Tribe(Church) General Manager</h2>
                <br></br>
                  <p>1. The General manager of the Tribe(church) needs to take up the will of the Tribe leader (church head) and flow down the direction to the respective Tribe(church) workers of duty and manage all work of the Tribe. Also, the General manager protects the saints and property of the Tribe(church) and reports issues of it to the Tribe leader (church head)</p>
                  <p>2. The Tribe General manager always needs to pay attention to the direction from the guideline of the General assembly and be obedient to it and need to fulfil the will of the Chairman.</p>
                  <p>3. The General manager needs to discuss with the Tribe leader and core workers regularly to improve the operation and governance of the Tribe. And he or she needs to recommend an instructor, evangelist, centre director, group leader, supervisor, cell leader and each ministry leader and train them to take up their duty faithfully.</p>
                  <p>4. The General manager needs to check the workers of duty. If there is a worker of duty who is not taking up their duty well or is complacent, then the general manager needs to give feedback to that person. And even after that, if that person is not changed, report it to the Tribe leader to replace that person.</p>
                  <p>5. The General manager has to gather workers of duty often, prepare all the gatherings and events in advance and focus on faith-walk maintenance and evangelism.</p>
                  <p>6. The General manager has to work hard to win the heart of saints more than money and unite with love. Also, the General manager needs to discuss once or even twice to get to know what people want so there are no complaints and awakening them that we are the reality of the New kingdom New people of God.</p>
                  <p>7. The General manager needs to create a mission centre and BB from the church to improve evangelism and choose the leader for each to run it.</p>
                  <p>8. The General manager needs to gather the people who will evangelize the fruits for the centre and BB and educate them to participate in evangelism activity.</p>
              </section>
              <section id="missionCenter">
                <h2>4. The Duty of Workers of Duty of Mission Center</h2>
                <section id="missionCenter_1">
                  <h3>4-1. The Duty of Center Director</h3>
                  <i>– Responsibility for mission centre operation –</i>
                  <br></br>
                    <p>1. The mission centre director needs to take up the will of the church head and be obedient to each Tribe (church) Theology leader. And the mission centre director needs to protect the mission centre, workers and property and take responsibility for the mission centre’s operation.</p>
                    <p>2. The church, like parents, made the Mission centre, like children, therefore the Mission centre must be obedient to the church. Also, the mission centre director needs to report the fact of all the current work of the Mission centre to the church head through each church Theology leader.</p>
                    <p>3. The Mission centre director needs to set up an instructor, evangelist, and receive supervisors, cell leader, and cell members from the group and make BB to gather students and approve all tasks of the Mission centre.</p>
                    <p>4. The Mission centre director needs to take responsibility for the transportation fee and life of all workers under the Mission centre as well as the Mission centre rent and operating expenses.</p>
                    <p>5. The Mission centre director needs to discuss and practice with each group leader regarding making BB to gather the students.</p>
                    <p>6. The group needs to discuss with the centre to make a BB hall (BB hall: Place of gathering for BB, the Ministry in charge of BB hall operation: Ministry of Evangelism) and put each cell in each BB hall and help the workers to take responsibility for the evangelism.</p>
                    <p>7. The BB hall director is in charge of the operational cost of BB hall and if it’s needed, the mission centre director and BB hall director can work together to run the BB hall.</p>
                    <p>8. The Mission centre director needs to stand before the students and gives words of encouragement, hope, patience, and importance of the word for about 10-20 minutes. It is to ensure 100 percenter Passover, cultivate perseverance, and prevent dropouts.</p>
                    <p>9. Just as the Lord loves the Mission centre director, the Mission centre director needs to love all the workers and students under the Mission centre and help them love one another.</p>
                </section>
                <section id="missionCenter_2">
                  <h3>4-2. The Duty of (Head) Instructor</h3>
                  <i>– Responsibility on educating in missionary center</i>
                  <br></br>
                    <p>1. The duty of head instructor of missionary center is to follow the will of church head, protect those who belong to center, assets, rule over them and seal the students perfectly and make them passover.</p>
                    <p>2. Instructor must put effort for administrative work of center and gathering students with evangelist. For gathering students, head of missionary center should discuss with group leaders, and put effort to establish BB in the allocated region. Instructor should always be at the front line (Mt 5:19)</p>
                    <p>3. Instructor should educate students to their eye level. For the education, visitation, passover, there should be 1 evangelist for every 10 students of introductory, intermediate and advanced class.</p>
                    <p>4. Instructor should make evangelist do checking and review after the lecture, visit students who needs visitation, do supplementary education if needed.</p>
                    <p>5. Instructor should discuss with groups regarding new comers and maintain their faithwalk thoroughly by visiting them 1 or 2 times a month and meeting.</p>
                    <p>6. Establish organization for cell leader and member’s effective evangelism, and do evangelism education every time.</p>
                    <p>7. Head instructor should educate and train head of center, instructor, evangelist, BB teacher, Jiyeok-jang (*JJ), cell leader and cell members (Cooperate with center family, support co-workers for their growth)</p>
                    <p>8. Head instructor should promote positively about their center workers, educate them with the word of shepherd to awaken their spirit and put effort first.</p>
                    <p>9. Same as church head loves instructor, instructor should love those who belong to center, students and make them love each other.</p>
                </section>
                <section id="missionCenter_3">
                  <h3>4-3. The Duty of (Head) Evangelist</h3>
                  <i>– Managing center students and responsibility on passover –</i>
                  <br></br>
                    <p>1. Evangelist (JDS) is the teacher who convey the truth. Center evangelist should follow the will of church head, obey to head of center and instructor, be in charge of administration of missionary center, visitation, BB, review for students, creating the spirit and training them.</p>
                    <p>2. Center evangelist should consider about gathering students, and do review, consultation for passover, visiting absentees, supplementary education, visiting online student, education through visiting.</p>
                    <p>3. (Head) evangelist should discuss about gathering students with Jiyeok-jang (JJ) and cell leader, and evangelize by making 2 on 1 group evangelism.</p>
                    <p>4. (Head) evangelist should always discuss with Jiyeok-jang (JJ) and cell leader, record it and report it to instructor and head of center</p>
                    <p>5. (Head) evangelist should discuss with IWNs in each group and establish BB for the introductory education of the Bible, network and put best effort for the increase of the number of quality BB. This is because the more BB can lead to stable center opening.</p>
                    <p>6. (Head) evangelist should guide new comers with instructor, visit them if needed, educate them with the life in each group and cell, administration, hierarchy of Shincheonji so that they can start their faithwalk in Shincheonji smoothly.</p>
                    <p>7. Same as instructor loves evangelist, (head) evangelist should love family and make them love each other.</p>
                </section>

              </section>
              <section id="group">
                <h2>5. The Duty of Workers of Duty of Group</h2>
                <section id="group_1">
                  <h3>5-1. The Duty of Group Leader</h3>
                  <i>※ Group Leader: Senior’s Group, Men’s Group, Women’s Group, Youth Group</i>
                  <br></br>
                    <p>1. Obeying the will of the head of the church, the group leader must protect and govern over his/her group, the saints who belong to the group, and the property, and must develop the group through faithwalk maintenance, harvesting and evangelism. The group leaders should establish more than 6 IWNs to govern for the sake of admin work in the group, faithwalk maintenance of the saints, and evangelism.</p>
                    <p>2. The group leader must put his/her best effort to master the Bible because the group leader must be better equipped with the word than the head of the church. Furthermore, group leader must discuss and work together with the heads of various ministries in the church. Regarding the absentee, for the sake of the faithwalk maintenance, the group leader must make IWN or the ministry of liaison of the department in charge of the attendance visit the absentee to understand his/her state of faith. The group leader must make sure that the dropped out, be reported immediately and must take initiative to supervise. </p>
                    <p>3. Just as how Jesus and CHJN love the workers of duty of Shincheonji, the group leader must love the new comers, visit them as occasion demands, and understand their state of faith. Furthermore, the group leader must educate the members on how the church and the group operate, the structure we have, the administration work of the church, and the duty of the saints. The group leader must greet the members with bright facial expressions, and lead the members with good intention, not with coercive commands.</p>
                    <p>4. Just as how the head of the church love the group leaders, the group leader must love IWNs and members, and educate them to unite in one and love one another.</p>
                    <p>5. For effective evangelism, the group leader must organize 2 by 2 teams, provide an evangelism education on harvest, and make each group select and practice evangelism methods and report the results of their evangelism activities. The group leaders must establish BB working with JJNs and GYJNs, and open group BBs so that BB lesson can be conducted.</p>
                    <p>6. The group leader must not emphasize the group meeting excessively. Because new comers need the Word to evangelize and settle down in their life of faith. The group leader must send the new comers to the mission center so that they can listen to the lesson again, and the workers of the mission center can accommodate them to do the evangelism activities.</p>
                </section>
                <section id="group_2">
                  <h3>5-2. The Duty of the General Manager in each Group</h3>
                  <i>– Overseeing the administration works and general tasks of the group –</i>
                  <br></br>
                    <p>1. Obeying the will of the group leader, the general manager of the group must oversee and supervise the tasks that each IWN is in charge of. The general manger must convey the command of the group leaders, and report what IWNs have done to the group leader.</p>
                    <p>2. The general manager of the group must frequently have a meeting with IWNs and do his/her best to fulfill the duty working in harmony with the IWNs. The general manger must apprehend all the matters of the group in advance and report this to the group leader. Furthermore, the general manager must be clearly aware of the situation of IWNs, GYJNs, and members.  </p>
                    <p>3. The general manager of the group must inspect the duty of and reports from each IWN to see if there are any abnormalities, then have them correct their mistakes, and report any abnormalities to the group leader.</p>
                    <p>4. The general manager of the group should discuss with the general manager of the church as occasion demands, and must evangelize along with IWNs to establish BB, and to find fruits for mission center. The general manager must strengthen and solidify the structure for the work of harvest for the sake of an effective work of evangelism. The general manager must educate various evangelism methods to the members, have them practice the methods, and have IWNs and GYJNs check whether the members have practiced or not.   </p>
                    <p>5. If the general manager becomes complacent and indifferent to his/her own duty, the group will go bankrupt, the work of evangelism will fail, BB, mission center, and church will all fall to ruin. Therefore, the general manager must take initiative and work with IWNs and GYJNs</p>
                    <p>6. The general manager of the group must have all the members unite in one as the group leaders centered. Just as the group leader loves the general manger, the general manager must love IWNs and have all the members love one another.</p>
                </section>
                <section id="group_3">
                  <h3>5-3. The Duty of the Head of Ministry of Education in each Group</h3>
                  <i>– Carries out various trainings/educations for the advancement and the progress of the Groups -</i>
                  <br></br>
                    <p>1. The head of Education within the Groups is in charge of various educations or trainings that take place within the Groups. Thus he/she must be filled with the knowledge of the Revealed Word above any other member. He/she themselves must put in every effort to become a walking Bible.</p>
                    <p>2. The head of Education’s first and foremost duty is to seal the members within their Group. If there is a member who is not properly sealed, it is the responsibility of the head of Education. The head of Education should discuss with the head of the Group (HJN) and the department leaders (IWNs) in the Group to plan, schedule, establish, and carry out various educations for the prevention of member dropouts. They must also carry out educations for the people of duty within the Groups; how to maintain members’ faithwalk, manage cell groups or departments, and how to revive the faithwalk of the members who are weak and/or struggling.</p>
                    <p>3. The Ministry of Education within the Groups should constantly conduct trainings to raise workers within the Newcomers’ Department and the members within the Groups. They should plan and carry out evangelism educations, BB teacher trainings, cell leader and department leader trainings. They should be working closely with and be in solidarity with the church’s Ministry of Education so as to raise future Shincheonji educators and Theology workers.</p>
                    <p>4. The head of Education follows the regulations and instructions of the Group leader (HJN), but is connected with the church’s Ministry of Education—meaning he/she must participate in various meetings and carry out various duties of the Ministry of Education overall. The Ministry of Education within the Groups receives instructions and flow from the General Assembly’s and the Tribe’s Ministry of Education via the church’s Ministry Education. As such, they are all connected as one branch as the Ministry of Education operates within the Group. On a monthly basis, the Ministry of Education within the Group is to report the plans for future educations and the outcome(s) of the educations had to the church’s Ministry of Education.</p>
                    <p>5. When carrying out educations within the Groups, the materials and the contents must be first reported to and approved by the church’s Ministry of Education. If there are certain questions pertaining to doctrinal information, it is be to verified by the Ministry of Education at the General Assembly through the church’s Ministry of Education before relaying the information. No unverified doctrinal information is to be taught.</p>
                    <p>6. One must keep track of the attendance before and after all educations and trainings as it is to be reported. All of the materials (i.e. planning reports, contents, attendance etc.) of the various educations that take place must be archived for future reference when the next training is to take place. There must also be security education for the members so as to preserve and keep all SCJ education material safe and secure</p>
                </section>
                <section id="group_4">
                  <h3>5-4. The Duty of the Head of Ministry of Evangelism in each Group</h3>
                  <i>– The responsibilities of evangelism in the Groups -</i>
                  <br></br>
                    <p>1. The Head of the Ministry of Evangelism in the Groups upholds the will of the head of the Group (HJN) and the general manager (CMN) of the Groups while constantly being in good communication with the church’s Ministry of Evangelism and standing in solidarity with the other ministries of Evangelism in each of the other Groups. As such, he/she works together with all the leaders and the members of the Group and puts in all their strength to develop and improve his/her Group.</p>
                    <p>2. Life is in the Word for our faithwalk and life for the church and the mission centers is in evangelism. If the head of evangelism in the Groups becomes lazy or negligent, all the members of his/her Group become paralyzed. There can be no improvement or development in such a Group which is why the head of Evangelism in the Groups must give their utmost to complete his/her duty.</p>
                    <p>3. It would not be an exaggeration to say that the progress of the church and the mission centers is up to the Ministry of Evangelism. As such, the Ministry of Evangelism should make sure there is a constant influx of new BB students and get all the congregation members to harvest.</p>
                    <p>4. The Ministry of Evangelism must get all the members to unite in heart, mind, and strength, and love one another for the sake of evangelism so as to seal the 12,000 per tribe as soon as possible, and complete the creation of the multitude in white. </p>
                    <p>5. The head of the Ministry of Evangelism in the Groups must lead the members to evangelize through the supervisors (JJNs), cell leaders (GYJNs) and all the other leaders in the Groups. He/she is also responsible for evangelism education and the faithwalk maintenance of the members in his/her Group.</p>
                    <p>6. The head of Evangelism in the Groups must organize and mobilize the members for evangelism, and aptly give sufficient evangelism educations. He/she is to collect different ways to evangelize in the Group, and constantly collect and share successful case evangelism stories so as to run together with all the members. The head of Evangelism must take the members’ environment and circumstances into consideration as he/she arms them for spiritual warfare and helps them to evangelize in any and every circumstance the member may be in. Above all else, the head of Evangelism must fan the desire to evangelize into flame in all the members of his/her Group.</p>
                    <p>7. The head of Evangelism in the Groups must constantly investigate into and have a clear and real picture of the members’ evangelism progress—or lack thereof—to report it to the head (HJN) and/or the general manager (CMN) of the Group.</p>
                </section>
                <section id="group_5">
                  <h3>5-5. The Duty of the Head of Ministry of Culture in each Group</h3>
                  <i>– Making the group change with the heavenly culture -</i>
                  <br></br>
                    <p>1. The duty of the Ministry of Culture leader of a group is to newly change the saints with the culture of the heavenly kingdom by communicating with the Ministry of Culture of the church at all times in compliance with the will of the general secretary and the group leader and by solidarity with the Ministry of Culture of each group.</p>
                    <p>2. The duty of the Ministry of Culture of a group is to make the heavenly culture of Shincheonji known to people through the members of the group. There is nothing above this culture. In one mind, let us love and bring honor to Shincheonji, and let us complete the duty of making Shincheonji known to the world.</p>
                    <p>3. The meaning of culture is ‘change by writing’. Writing is to show words visually. There are many cultures in the world, but those merely belong to the world, not to heaven.</p>
                    <p>4. Like Jesus was the kingdom of heaven as the kingdom of heaven came to Jesus, today, Shincheonji is the kingdom of heaven on earth that is the place with the kingdom of heaven, God, and Jesus. The culture of Shincheonji is the eternal gospel of heaven, furthermore, it is to introduce Shincheonji. And also, it is the culture that makes all over the world born again with Shincheonji’s writing and word.</p>
                    <p>5. The culture of Shincheonji belongs to heaven (the kingdom of heaven), and it is to fulfill on this earth as it is in heaven. The kingdom of heaven in Mt 13 and Lk 13 was founded on this earth, and it is the culture that changes the saints with the word as if yeast is mixed into the flour and changes.</p>
                    <p>6. The Ministry of Culture should identify and employ the best technician in each field, including photos, videos, movies, publishing and etc., and should have equipment and environment necessary for the culture. In all this work, the mind must be connected to heaven firstly.</p>
                </section>
                <section id="group_6">
                  <h3>5-6. The Duty of the Head of Ministry of Liaison in each Group</h3>
                  <i>– Group Diplomacy and Liaison Work -</i>
                  <br></br>
                    <p>1. The duty of the Ministry of Liaison leader of a group is to manage and develop the faith of the saints by solidarity with all executives in compliance with the will of the general secretary and the group leader and by communicating with the Ministry of Liaison of the church at all times and taking care of external affairs.</p>
                    <p>2. The Ministry of Liaison leader of a group should visit each Mission Center and cell to grasp its present state, and should grasp the church attendance of the group members and visit the absentees to make them attend. Also, [he/she] should liaise with a prospect by various ways of evangelization and lead [her/him] to BB.</p>
                    <p>3. The Ministry of Liaison of a group should be in solidarity with all leaders of organizations and with other groups to found the Mission Centers and BBs, and should do its best for development of evangelization by liaising with personages from gentile churches.</p>
                    <p>4. The Ministry of Liaison leader should grasp gentile churches nearby and investigate their actual states, then appoint a place to harvest, liaise with a prospect and recommend to the BB teacher.</p>
                    <p>5. The Ministry of Liaison should grasp information of people who are led and registered in at the time of Mission Center opening, also grasp their faith and environments in their churches to make known to the Mission Center.</p>
                    <p>6. The Ministry of Liaison should make all the saints become one with the Lord’s work, and make them cherish, protect and love each other.</p>
                    <p>7. To make the saints take pride in being Shincheonji’s kingdom and priest, to make themselves qualified to heal all the nations as they are citizens of the holy city new Jerusalem, and to make them become the best of the world, become the light and become the glory of God, the Ministry of Liaison leader of a group must complete his duty.</p>
                </section>
                <section id="group_7">
                  <h3>5-7. The Duty of Secretary and Accounting Secretary in each Group</h3>
                  <br></br>
                    <p>1. Workers of duty above should serve the will of group leader (HJN) and general manager (CMN), cooperate with the core department of church, fulfil the duty by developing the group.</p>
                    <p>2. All the tasks of the workers of duty above is for the church and saints, moreover, it is for the evangelism of group, which is for the development of the church. It becomes meaningless if we don’t have good result in evangelism after using lots of effort and money. So, workers of duty above should be faithful to their duty and cooperate on making evangelism strategy. Also, they must cooperate, unite for the evangelism and be at the front line.</p>
                    <p>3. Each SMN & HGN should cooperate and unite as one, establish BB and be at the front line for the evangelism activity. If we lay the foundation and not able to finish it, everyone who sees it will ridicule us (Lk 14, Mt 5). We should repay the grace of receiving the heavenly manna that was hidden for 6,000 years and choosing us. And let’s love and cooperate each other.</p>
                    <p>4. Same as the group leader loves SMN & HGN, SMN & HGN should love group members, make them unite as one and love each other.</p>
                </section>
                <section id="group_8">
                  <h3>5-8. The Duty of Evangelism Supervisor</h3>
                  <i>– In charge of evangelism in each department -</i>
                  <br></br>
                    <p>1. Ji-yeok-jang (JJ) is the worker of duty who was specially sent to each department for gathering students in missionary center. JJN should serve the will of group leader (HJN) and Won-jang (WJN – centre director), be obedient, find out the current status of the department, protect and develop the department together with cell leader. So, its’ value is really important</p>
                    <p>2. If JJN don’t fulfill the duty that is entrusted, cells and missionary center that is related to the department will be paralyzed, stopped and ruined. So, they should not have any kinds of negligence and laziness in doing the duty.</p>
                    <p>3. JJN should put best effort to fulfill the goal of the department. If JJN becomes lazy, the department will be collapsed. So, JJN should make everyone work and run.</p>
                    <p>4. JJN should find the current status with Won-jang (WJN – centre director), instructor, evangelist, group leader and IWNs and make a strategy for establishing BB, evangelism with department members.</p>
                    <p>5. JJN should become one with the evangelist (JDSN), also become one with cell leader (GYJN), check the department thoroughly, establish enough BB and make sure to run it well. JJN should run with best effort so that the fruit who finished the introductory education at BB stage can lead to the missionary center for the center opening.</p>
                    <p>6. JJN should organize evangelism team in the department and educate them, choose the place for the evangelism activity, share the venue once it is confirmed, sent those who are prepared to evangelize, check the report and support them.</p>
                    <p>7. Same as group leader and instructor love JJN and evangelist (JDSN), JJN should love cell leader (GYJN) and members in the department, and make cell leader (GYJN) and members love each other.</p>
                </section>
                <section id="group_9">
                  <h3>5-9. The Duty of Team Leader</h3>
                  <i>– In charge of supervising cell leaders, and taking care of evangelization and faith –</i>
                  <br></br>
                    <p>1. A team leader (TJN) of each department should convey directions of the department leader (HJN) to the affiliated cell leaders (GYJNs), and review reports from the cell leaders on the matters conveyed and report it to the department leader.</p>
                    <p>2. A team leader (TJN) should check the report of the cell leaders (GYJNs) then report it to the department leader (HJN), and urgent matters should be reported immediately to the head of church (DGSN). And the cell member’s state of faith and attendance should be checked without missing a single week.</p>
                    <p>3. A team leader (TJN) should immediately report to the department leader (HJN) to replace the cell leader (GYJN) when he fails to perform his mission to the cell.</p>
                    <p>4. A team leader (TJN) takes charge of the cell which is allocated among the entire cells from the department and should discuss with the cell leader (GYJN) to look after the cell.</p>
                    <p>5. A team leader (TJN) should always check for management and evangelization of the cell.</p>
                    <i>(※ Use the Checklist)</i>
                    <p>6. A team leader (TJN) should have the skills of the word and leadership more than a center instructor (GSN). He should teach the affiliated cell leaders (GYJNs) and cell members so that there is no one who does not have the word, but he should make them become walking Bibles and those who have fulfilled the New Covenant.</p>
                    <p>7. To Lose the life sent from the mission center is to abandon his own life. Thus, a team leader (TJN) should teach himself constantly first, then teach the cell leader (GYJN).</p>
                    <p>8. A department leader (HJN) and a team leader (TJN) are those who have a significant duty to teach cell leaders (GYJNs) and protect the cells. If one does not keep this duty, he cannot enter the kingdom of heaven. One should not think of going to the kingdom of heaven as a joke. One must think of the time of Noah and the time Ruth. If one does not believe in this fact, he also does not believe in God and the Bible. One must keep the duty with his life on the line.</p>
                </section>
                <section id="group_10">
                  <h3>5-10. The Duty of Cell Group Leader</h3>
                  <i>– A font-line worker of duty -</i>
                  <br></br>
                    <p>1. The duty of a cell leader (GYJN) is to guard, protect, and develop the cell in accordance with the will of the department leader (HJN).</p>
                    <p>2. A cell leader (GYJN) leads cell members. Cell members are like soldiers of a squad fighting at the front line of a country’s army and a cell leader is like a squad leader. If the front-line collapse, both the army and country will be destroyed. It is the cell leader who has received a more basic and important duty than any other duty of workers. Therefore, God, Jesus, and CHJN consider a cell leader important and love him specially.</p>
                    <p>3. A cell leader should inform cell members of the task details commanded by the executive (IWN) and the team leader. Accomplished tasks should be reported by the cell leader to the department leader through the executive (IWN) and the team leader, the department leader should report it to the head of the tribe through the head and general secretary of the church. The cell leader should ensure that Righteous and Just is realized in the handling of affairs of the cell.</p>
                    <p>4. The duty of a cell leader is the same as the evangelist of the mission center. In order to bear the given work, a cell leader need to order the cell members to do necessary tasks and supervise them, and then report the result to the superior. If a member of the cell is dropped out, it is the responsibility of the executive (IWN), team leader, and the cell leader.</p>
                    <p>5. A cell leader should always work together with the cell member. If necessary, he should frequently do visitations with her and accurately grasp her circumstances, situations, conditions, and state of faith as a family.</p>
                    <p>6. As Jesus and CHJN love cell leaders, a cell leader should love his members much, and make them love each other with truth and peace.</p>
                    <p>7. A cell leader should not try to walk on eggshells around the superiors and not try to be only good to them, but above all, he should share his heart with his members and be good to them. Ultimately, this is the true way to be good to the superiors. The voice of the people is the voice of God.</p>
                    <p>8. A cell leader should frequently run cell services and meetings to take care of the members’ faith, and endeavor to open a BB that is run independently by the cell through inviting each department leader.</p>
                    <p>9. Just as the kingdom of God cannot compromise with the world, a cell also must not compromise with the world. A cell leader must keep away from a private fund or money transaction among members, suggestion for Ponzi Scheme [multilevel marketing], talk about the world, slander on people around, and others.</p>
                    <p>10. With the strong and development of a cell, the BB, the mission center and the church can develop, furthermore, purpose of healing all nations can be fulfilled. With this mind, all members of the cell should be loyal to their duty.</p>
                    <p>11. In order to develop a cell into an exemplary cell in all aspects including the mission of evangelization and the others, All the members should actively participate in the education of liaison, of BB, and of evangelization, then acquaint with the educated evangelization methods. According to the activity areas for evangelization selected by the head of the region, each cell should unite with each other, then liaise and lead the subject person, who has been liaised, to the BB and educate him.</p>
                    <p>12. If there is a cell that is failed to carry out its mission, the department leader should incorporate the cell into other cell that has been carrying out the mission well and make both bring together to run the cell.</p>
                    <p><i>※ As above, the cell leader (GYJN) who fails to carry out his duty well is the same as failing to keep the covenant, and is the same as the dead.</i></p>
                </section>
                <section id="group_11">
                  <h3>5-11. The Duty of the Head of Student’s Group</h3>
                  <br></br>
                    <p>1. A head of Student’s Group generally manages all works of the Student’s Group, protects and takes care of student members, and develop the group.</p>
                    <p>2. A head of Student’s Group should work with teachers for running and governing the Student’s Group, have gathering frequently, and do the best for faith management. With the guidance teachers, he should make every effort to prevent the student members from dropping out.</p>
                    <p>3. Students should be taught the elementary-level course completely (CHJN’s word of instruction). To fully study the elementary-level, the student members should be perfectly educated the true meaning of the biblical parables through the mission center’s elementary-level course, the explanation booklet for parables and so on, then they should be given a speech.</p>
                    <p>4. A head of Student’s Group should teach the students about religion, invigorate the group by growing them step by step. He should guide the student members to become the light of the world through school study support and cultural activities. And the student members should gain enlightenment by receiving religious education well and doing the study of the world hard.</p>
                    <p>5. A head of Student’s Group should check the state of faith of a subject student led to the Student’s Group. Also, he should teach the value of evangelizing to the student members, recommend an appropriate method of evangelism with consideration for the student member’s circumstances and situation, and train the members as enthusiastic evangelists.</p>
                    <p>6. A head of Student’s Group should unite parents and the Student’s Group through parent meetings so that student members can grow properly.</p>
                    <p>7. Student’s Group should teach student members so that they serve the Creator God as Father and be obedient to God, and also do filial piety[duty] in both physically and spiritually to their physical parents.</p>
                    <p>8. A head of Student’s Group should make all the teachers and student members cherish, love and forgive each other and make them unite in one.</p>
                </section>
                <section id="group_12">
                  <h3>5-12. The Duty of the Head of Children’s Group</h3>
                  <br></br>
                    <p>1. A head of Children’s Group generally manages all works of the Children’s Group, protects and takes care of child members, and develop the group.</p>
                    <p>2. A head of Children’s Group should do overall operation, education of guidance teacher in Children’s Group, parent education, and counselling for parents and the weak in Children’s Group.</p>
                    <p>3. Saints of Shincheonji, even a child, master the Bible of the New Testament, that is the word of the New Covenant (referring to 38. 8. 31. JSS). Children’s group teaches the history of the Bible, the basic knowledge of the New Testament and Revelation, service and prayer, loyalty and filial piety with the Bible to the child members.</p>
                    <p>4. A head of Children’s Group should work together with the guidance teachers. He should accurately grasp the child members’ circumstances, situations, conditions, and state of faith as a family, also should manage and supervise the current state of the child members.</p>
                    <p>5. A head of Children’s Group should unite parents and the Children’s Group through parent meetings so that child members can grow properly.</p>
                    <p>6. Children’s Group should teach the spiritual Father, who is the Father of all creation and created us, also the way of being obedient to and doing filial piety to the physical parents.</p>
                    <p>7. A head of Children’s Group should make all the guidance teachers and child members cherish and love each other and make them unite in one.</p>
                </section>
              </section>
            <br></br>
          </Text>
        </ContentWithPaddingXl>
      </Container>
    </AnimationRevealPage>
  );
};