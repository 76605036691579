import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { ReactComponent as PlusIcon } from "feather-icons/dist/icons/plus.svg";
import { ReactComponent as MinusIcon } from "feather-icons/dist/icons/minus.svg";
import { ReactComponent as DownloadIcon } from "feather-icons/dist/icons/arrow-down-circle.svg";
import { ReactComponent as LinkIcon } from "feather-icons/dist/icons/link.svg";
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import clipboardCopy from 'clipboard-copy';
import { htmlToText } from "html-to-text";

const illustration = "https://images.unsplash.com/photo-1719937206098-236a481a2b6d?q=80&w=2670&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDF8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D";
const Container = tw.div`relative`;
const Content = tw.div`max-w-screen-xl mx-auto py-2 justify-center`;

const TwoColumn = tw.div`flex flex-col md:flex-row`;
const Column = tw.div``;

const Image = styled.img`
  height: auto;
  max-height: 500px;
  display: flex;
  justify-content: center;
  box-shadow: 0 4px 8px rgba(0,0,0,0.4);
`

const FAQContent = tw.div`lg:ml-12`;
const Subheading = tw(SubheadingBase)`mb-4 mt-4 text-center lg:text-left`;
const Description = tw.p`max-w-xl text-center mx-auto lg:mx-0 lg:text-left lg:max-w-none leading-relaxed text-sm sm:text-base lg:text-lg font-medium mt-4 text-secondary-100`;

const FAQSContainer = tw.dl`mt-4`;
const FAQ = tw.div`cursor-pointer mt-2 select-none border lg:border-0 px-8 py-4 lg:p-0 rounded-lg lg:rounded-none`;
const Question = tw.dt`flex justify-between items-center`;
const QuestionText = tw.span`font-semibold`;
const QuestionToggleIcon = styled.span`
  ${tw`ml-2 bg-primary-500 text-gray-100 p-1 rounded-full group-hover:bg-primary-700 group-hover:text-gray-200 transition duration-300`}
  svg {
    ${tw`w-4 h-4`}
  }
`;
const Answer = motion(tw.dd`pointer-events-none text-sm sm:text-base leading-relaxed`);
const SubmitButton = styled.button`
  ${tw`mt-2 tracking-wide font-semibold bg-primary-500 text-gray-100 md:w-[48%] w-full py-4 rounded-lg hover:bg-primary-900 transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none`}
  .icon {
    ${tw`w-6 h-6 -ml-2`}
  }
  .text {
    ${tw`ml-3`}
  }
`;
const HeadingRow = tw.div`flex justify-between items-center sm:flex-col md:flex-row mb-2`;
const Heading = tw.h2`text-2xl sm:text-4xl font-black tracking-wide text-center text-gray-900`;
const HighlightedText = tw.span`bg-primary-500 text-gray-100 px-4 transform -skew-x-12 inline-block`;


export default ({
  subheading = "Hook",
  heading = "Questions",
  buttonText = "Download Flyer",
  imageSrc = "https://zegleedmcpswaxynxnta.supabase.co/storage/v1/object/public/assets/",
  imageContain = false,
  imageShadow = true,
  faqs = null,
  tool = null
}) => {  /*
   * You can modify FAQs either by modifying the below defaultFaqs array or by passing a custom array of FAQs using
   * the faqs prop
   */
  const defaultFaqs = [
    {
      question: "Is lunch provided free of cost ?",
      answer:
        "Yes, it is, if you have a membership with us. Otherwise it is charged as per the menu. Some limits do apply as to how much items can be included in your lunch. This limit is enough for any one person and merely exists to discourage abusal of the system."
    },
    {
      question: "Do you have 2 Bedroom suites ?",
      answer:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
    },
  ];

  if (!faqs || faqs.length === 0) faqs = defaultFaqs;

  const [activeQuestionIndex, setActiveQuestionIndex] = useState(null);
  const [isDownloading, setDownloadState] = useState(false);
  const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth >= 768); // Assuming 768px as the breakpoint for large screens

  // Function to check screen size and update state
  const checkScreenSize = () => {
    setIsLargeScreen(window.innerWidth >= 768);
  };


  useEffect(() => {
    // Set the initial state based on current screen size
    checkScreenSize();
    // Add event listener for window resize
    window.addEventListener('resize', checkScreenSize);
    // Cleanup event listener on component unmount
    return () => window.removeEventListener('resize', checkScreenSize);
  }, []);

  const toggleQuestion = questionIndex => {
    if (!isLargeScreen) { // Allow toggling only on small screens
      if (activeQuestionIndex === questionIndex) setActiveQuestionIndex(null);
      else setActiveQuestionIndex(questionIndex);
    }
  };

  const copyContent = (event) => {
    setDownloadState(true);
    event.preventDefault();
    fetch(`${imageSrc}${tool.flyer ? `${tool.flyer}.jpg` : tool.flyer_uploaded}`)
      .then(response => response.blob())
      .then(blob => {
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `${tool.name} flyer.jpg`;
        a.click();
      })
      .catch(error => console.error("Error"))
    clipboardCopy(htmlToText(tool.hook, {
      wordwrap: false,
      ignoreHref: true,
      ignoreImage: false
    }))
    setDownloadState(false);
  };

  const goToLink = (event) => {
    window.open(tool.link, "_blank");
  }

  return (
    <Container>
      <Content>
        <HeadingRow>
          <Heading>{<><HighlightedText>{tool ? tool.name : "Loading..."}</HighlightedText></>}</Heading>
        </HeadingRow>
        <TwoColumn>
          <Column tw="flex md:w-1/2 w-full justify-center items-center">
            {tool && (<Image src={`${imageSrc}${tool.flyer != null ? `${tool.flyer}.jpg` : tool.flyer_uploaded}`} />)}
          </Column>
          <Column>
            <FAQContent>
              <FAQSContainer>
                {faqs.map((faq, index) => (
                  <FAQ
                    key={index}
                    onClick={() => {
                      toggleQuestion(index);
                    }}
                    className="group"
                  >
                    <Question>
                      <QuestionText>{faq.question}</QuestionText>
                      <QuestionToggleIcon>
                        {isLargeScreen || activeQuestionIndex === index ? <MinusIcon /> : <PlusIcon />}
                      </QuestionToggleIcon>
                    </Question>
                    <Answer
                      variants={{
                        open: { opacity: 1, height: "auto", marginTop: "16px" },
                        collapsed: { opacity: 0, height: 0, marginTop: "0px" }
                      }}
                      initial="collapsed"
                      animate={isLargeScreen || activeQuestionIndex === index ? "open" : "collapsed"}
                      transition={{ duration: 0.3, ease: [0.04, 0.62, 0.23, 0.98] }}
                    >
                      <ReactMarkdown rehypePlugins={[rehypeRaw]}>{faq.question === "Link" ? `<a href="${faq.answer}">${faq.answer}</a>` : faq.answer}</ReactMarkdown>
                    </Answer>
                  </FAQ>
                ))}
              </FAQSContainer>
            </FAQContent>
          </Column>
        </TwoColumn>        
        <div tw="flex md:flex-row flex-col justify-between">
          <SubmitButton onClick={(e) => goToLink(e)}>
            <LinkIcon className="icon" />
            <span className="text">{"Link"}</span>
          </SubmitButton>
          <SubmitButton onClick={(e) => copyContent(e)}>
            <DownloadIcon className="icon" />
            <span className="text">{isDownloading ? "Downloading..." : buttonText + (tool ? (tool.hook ? " and Copy Hook" : "") : null)}</span>
          </SubmitButton>
        </div>
      </Content>
    </Container>
  );
};