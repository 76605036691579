import React, { useEffect, useState } from "react";
import tw from "twin.macro";
import styled from "styled-components";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Header, { NavLink, NavLinks, PrimaryLink as PrimaryLinkBase, LogoLink, NavToggle, DesktopNavLinks } from "../../components/headers/light.js";
import icon from "../../images/lra.svg"
import DataTable from "components/table/DataTable";

const StyledHeader = styled(Header)`
  ${tw`p-0 max-w-none w-full`}
  ${LogoLink} {
    ${tw`text-black hover:border-gray-300 hover:text-gray-300`}
  }
`;

export default () => {

  return (
    <AnimationRevealPage>
      <StyledHeader links={[]} logoIcon={icon} />
      <DataTable />
    </AnimationRevealPage>
  )
}
