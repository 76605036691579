import React from 'react';
import Card from '../../components/table/LeaderboardTable';
import { secondRegistrationData } from '../../components/table/dataObject';
import styled from 'styled-components';
import tw from 'twin.macro';
import AnimationRevealPage from 'helpers/AnimationRevealPage';
import Header, { LogoLink } from 'components/headers/light';
import GradContainer from './GradContainer';
import icon from '../../images/lra.svg';
import { Link } from 'react-router-dom';

const StyledHeader = styled(Header)`
  ${tw`p-0 max-w-none w-full`}
  ${LogoLink} {
    ${tw`text-black hover:border-gray-300 hover:text-gray-300`}
  }
`;

const ButtonContainer = styled.div`
  ${tw`flex flex-col items-center mt-8`}
`;

const getCardStyle = (index) => {
    switch (index) {
        case 1:
        case 2:
        case 3:
            return tw`bg-pink-600 text-white`;
        default:
            return tw`border-2 border-pink-600 text-pink-600`;
    }
};
const Button = styled(Link)`
    ${tw`rounded-lg p-3 my-4 w-1/2 text-center relative flex items-center font-bold`}
    ${({ index }) => getCardStyle(index)}
`;

const IndexNumber = styled.span`
  ${tw`shadow inline-block p-2 text-2xl absolute left-0 bg-white w-12 border-2 font-bold`}
  ${({ index }) => getCardStyle(index)}
  box-shadow: 0 0 0 3px #fff, 0 0 0 5px #ed64a6; 
`;

const Content = styled.div`
  ${tw`flex justify-between items-center w-full px-8 font-bold`}
`;

const Region = styled.div`
  ${tw`text-center flex-grow`}
`;

export default () => {
    return (
        <AnimationRevealPage>
            <StyledHeader links={[]} logoIcon={icon} />
            <GradContainer heading="2nd Registration"/>
            <ButtonContainer>
                {secondRegistrationData.map((item) => (
                    <Button key={item.index} index={item.index}>
                        <IndexNumber>{item.index}</IndexNumber>
                        <Content>
                            <Region>{item.region}</Region>
                            <div>{item.percentage}%</div>
                        </Content>
                    </Button>
                ))}
            </ButtonContainer>
        </AnimationRevealPage>
    );
};