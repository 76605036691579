import React, { useEffect, useState } from "react";
import styled from 'styled-components';
import tw from 'twin.macro';
import trophy from '../../images/Trophy.svg';
import Header, { LogoLink } from 'components/headers/light';
import GradContainer from './GradContainer';
import AnimationRevealPage from 'helpers/AnimationRevealPage';
import icon from '../../images/lra.svg';
import trophy_icon from '../../images/trophy_icon.svg'
import supabase from "helpers/SupabaseClient";
import { dummyTableData } from "components/table/dataObject";
import fruitWhispererData from "components/table/dataObject";

const Card = tw.div`flex bg-white w-full md:w-3/4 justify-center m-auto rounded-lg p-4 m-2 overflow-hidden`;
const ThumbnailContainer = tw.div`hidden md:block flex justify-center w-[130px] h-[130px] bg-primary-500 mt-4`;
const Thumbnail = tw.p`flex justify-center items-center text-center text-white text-3xl font-bold transform -skew-x-12 pt-10`;
const InfoContainer = tw.div`w-full md:w-3/4 p-4`;
const InfoContainerItem = tw.p`flex flex-row justify-center text-center mt-1 mb-1`
const InfoContainerItemCell = tw.span`w-1/3 text-lg font-medium`
const Heading = tw.h3`md:hidden flex text-xl font-semibold text-center justify-center box-decoration-clone text-gray-100 bg-primary-100 inline-block`;


const StyledHeader = styled(Header)`
  ${tw`p-0 max-w-none w-full`}
  ${LogoLink} {
    ${tw`text-black hover:border-gray-300 hover:text-gray-300`}
  }
`;

const ButtonContainer = styled.div`
  ${tw`flex flex-col items-center mt-8`}
`;


const WinnerIcon = styled.img`
    ${tw`h-8 m-auto bg-primary-500`}
`

const ToggleContainer = styled.div`
  ${tw`flex flex-row justify-center inline items-center mt-8 w-full md:w-1/2 m-auto`}
`;

const ToggleButton = styled.button`
  ${tw`p-3 text-center text-primary-100 w-1/2 transform -skew-x-12 
    focus:bg-primary-500 focus:text-white border justify-between `}
`

const SplitCard = tw.div`flex flex-wrap justify-center bg-primary-500 h-[5px] w-[90%] md:w-3/4 items-center m-auto`

export default ({title, table}) => {
    const [selected, setSelected] = useState("conventional")
    const [info, setInfo] = useState(null); 
    const [resultData, setData] = useState(null); 

    useEffect(() => {
      async function fetchData() {
        console.log(`${selected}_${table}`)
        try {
          const { data, error } = await supabase
            .from(`${selected}_${table}`)
            .select('*')
            .order('rank', {ascending: true})
          
          var val = prepData(data)
          console.log(val)
          setData(val);
        } catch (error) {
          console.error('Error fetching data:', error)
        }
      }
      fetchData();
      console.log(resultData);
    }, [selected])

    function prepData(data){
      console.log(data)
      if (data.length > 0){
        var final_data = {}
        const groups = ["MG", "WG", "YG", "SG"]
        
        groups.forEach((group) => {
          console.log(final_data)
          final_data[group] = data.filter((item) => item.group === group);
        })
        data = final_data;
      }
      console.log(data)
      return data;
    }
    const toggleData = (dataOption) => {
        if (dataOption === "conventional") {
            setSelected("conventional")
            // setData(info.conventional)
        }
        else{
            setSelected("pastors")
            console.log("pastors")
            // setData(info.pastors)
        }
    }

    const toggleStyle = {backgroundColor: "#de3163", color: "white"}
    return (

        <AnimationRevealPage>
        <StyledHeader links={[]} logoIcon={icon} />
        <GradContainer heading={title}/>

        <ToggleContainer>
            <ToggleButton 
              style={(selected==="conventional") ? toggleStyle : null} 
              onClick={() => toggleData("conventional")}
            >
              Conventional
            </ToggleButton>
            <ToggleButton 
              style={(selected==="pastor") ? toggleStyle : null} 
              onClick={() => toggleData("pastor")} 
            >
              Pastors
            </ToggleButton>
        </ToggleContainer>

        {console.log((resultData != null) ? `data: ${Object.values(resultData).forEach((item) => console.log(item))}` : "No data")}
        {Object.keys(resultData || {}).length > 0 && Object.values(resultData).map((table) => (
                <>
                {console.log("table: " + table)}
                {Object.keys(table).length > 0 && (<>
                <Card>
                    <ThumbnailContainer>
                        <Thumbnail>{table[0].group}</Thumbnail>
                    </ThumbnailContainer>
                    <InfoContainer>
                      <Heading>{table[0].group}</Heading>
                        {
                            table.map((result) => 
                            {
                                return result.rank === 1 ? 
                                <InfoContainerItem>
                                {console.log(result)}
                                <InfoContainerItemCell><WinnerIcon src={trophy_icon}/></InfoContainerItemCell>
                                <InfoContainerItemCell>{result.region}</InfoContainerItemCell>
                                <InfoContainerItemCell>{result.fruits} fruits</InfoContainerItemCell>
                                </InfoContainerItem>
                                :
                                <InfoContainerItem>
                                <InfoContainerItemCell>{result.rank}</InfoContainerItemCell>
                                <InfoContainerItemCell>{result.region}</InfoContainerItemCell>
                                <InfoContainerItemCell>{result.fruits} fruits</InfoContainerItemCell>
                                </InfoContainerItem>
                            }

                            )
                        }
                    </InfoContainer>
                </Card>
                <SplitCard/>
                      </>)}
                </>
    ))}
        </AnimationRevealPage>
    )
}