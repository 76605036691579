import React, { useEffect, useState } from 'react'
import AnimationRevealPage from 'helpers/AnimationRevealPage.js'
import { Container, ContentWithPaddingXl } from 'components/misc/Layouts'
import tw from 'twin.macro'
import styled from 'styled-components'
import { css } from 'styled-components/macro'
import Footer from 'components/footers/MiniCenteredFooter.js'
import { SectionHeading } from 'components/misc/Headings'
import { PrimaryButton } from 'components/misc/Buttons'
import Header, { NavLink, NavLinks, PrimaryLink as PrimaryLinkBase, LogoLink, NavToggle, DesktopNavLinks } from "../../../components/headers/light.js";
import icon from "../../../images/lra.svg"
import supabase from "helpers/SupabaseClient.js";
import SpecialEdu from "components/cards/Videos.js";

const HeadingRow = tw.div`flex justify-between items-center sm:flex-col md:flex-row`;
const Heading = tw.h2`text-2xl sm:text-4xl font-black tracking-wide text-center text-gray-900`;
const Posts = tw.div`mt-6 sm:-mr-8 flex flex-wrap justify-center`
const PostContainer = styled.div`
  ${tw`mt-2 w-3/4 sm:w-1/2 lg:w-1/3 sm:pr-8`}
  ${(props) =>
    props.featured &&
    css`
      ${tw`w-full!`}
      ${Post} {
        ${tw`sm:flex-row! h-full sm:pr-4`}
      }
      ${Image} {
        ${tw`sm:h-96 sm:min-h-full sm:w-1/2 lg:w-2/3 sm:rounded-t-none sm:rounded-l-lg`}
      }
      ${Info} {
        ${tw`sm:-mr-4 sm:pl-8 sm:flex-1 sm:rounded-none sm:rounded-r-lg sm:border-t-2 sm:border-l-2`}
      }
      ${Description} {
        ${tw`text-sm mt-3 leading-loose text-gray-600 font-medium`}
      }
    `}
`
const Post = tw.div`cursor-pointer flex flex-col bg-gray-100`
const Image = styled.div`
  ${(props) =>
    css`
      background-image: url('${props.imageSrc}');
    `}
  ${tw`h-64 w-full bg-cover bg-center rounded-t-lg`}
`
const Info = tw.div`p-2 border-2 rounded-t-lg rounded-l-lg`
const Category = tw.div`uppercase text-primary-500 text-xs font-bold tracking-widest leading-loose after:content after:block after:border-b-2 after:border-primary-500 after:w-8`
const CreationDate = tw.div`mt-4 uppercase text-gray-600 italic font-semibold text-xs`
const Title = tw.div`mt-1 font-bold text-xl text-gray-900 group-hover:text-primary-500 transition duration-300`
const Description = tw.div``

const PaginationContainer = tw.div`flex justify-center`
const ButtonContainer = tw.div`flex justify-end`
const LoadMoreButton = tw(PrimaryButton)``
const StyledHeader = styled(Header)`
  ${tw`p-0 max-w-none w-full`}
  ${LogoLink} {
    ${tw`text-black hover:border-gray-300 hover:text-gray-300`}
  }
`;
const PrimaryLink = tw(PrimaryLinkBase)`rounded-full`
const HighlightedText = tw.span`bg-primary-500 text-gray-100 px-4 transform -skew-x-12 inline-block`;
export default ({
  navLinks = [],
  logoLink = (
    <LogoLink href="/home">
      <img src={icon} alt="logo" />
      LightRainAir
    </LogoLink>
  ),
  headingText = "Internal Affairs",
}) => {
  const [visible, setVisible] = useState(6)
  const onLoadMoreClick = () => {
    setVisible((v) => v + 6)
  }
  const [material, setMaterial] = useState([]);

  useEffect(() => {
    async function fetchData() {
      setMaterial([
        "12 Tribes Faith Maintenance Counselling",
        "Cell Leaders Duty",
        "Faith Management and Counselling Case Study",
        "The Importance of Reporting and Announcements",
        "Consultation Guideline for WOD : The Acts of Jesus Christ"
      ]);
    }
    fetchData();
  }, []);

  const formattedDate = (date) => {
    const parts = date.split("-");
    return `${parseInt(parts[0]) - 1983}${parts[1]}${parts[2]}`;
  };

  return (
    <AnimationRevealPage>
      <StyledHeader links={navLinks}  logoLink={logoLink}/>
{/*      <ButtonContainer>
        <LoadMoreButton onClick={(e) => window.history.back()}>Back</LoadMoreButton>
      </ButtonContainer> */}
      <Container>
        <ContentWithPaddingXl>
          <HeadingRow>
            <Heading>{<><HighlightedText>{headingText}</HighlightedText></>}</Heading>
          </HeadingRow>
          <Posts>
            {material.slice(0, visible).map((post, index) => (
              <PostContainer key={index} featured={true}>
                <Post className='group' as='a' href={`/internal-affairs/${post.toLowerCase().replaceAll(" ", "-")}`}>
                  <Info>
                    {/* <Category>{'Internal Affairs'}</Category> */}
                    <Title>{post}</Title>
                  </Info>
                </Post>
              </PostContainer>
            ))}
          </Posts>
          {/* <SpecialEdu heading='Training Videos' table=''/> */}
        </ContentWithPaddingXl>
      </Container>
    </AnimationRevealPage>
  )
}