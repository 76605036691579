import React, { useEffect, useState } from 'react'
import AnimationRevealPage from 'helpers/AnimationRevealPage.js'
import { Container, ContentWithPaddingXl } from 'components/misc/Layouts'
import tw from 'twin.macro'
import styled from 'styled-components'
import { css } from 'styled-components/macro'
import Footer from 'components/footers/MiniCenteredFooter.js'
import { SectionHeading } from 'components/misc/Headings'
import { PrimaryButton } from 'components/misc/Buttons'
import Header, { NavLink, NavLinks, PrimaryLink as PrimaryLinkBase, LogoLink, NavToggle, DesktopNavLinks } from "../../../components/headers/light.js";
import icon from "../../../images/lra.svg"
import supabase from "helpers/SupabaseClient.js";
import EduVideo from "components/cards/Videos.js";
import MaterialList from 'components/cards/MaterialList.js'

const HeadingRow = tw.div`flex`
const Heading = tw(SectionHeading)`text-primary-900`
const Posts = tw.div`mt-6 sm:-mr-8 flex flex-wrap`
const PostContainer = styled.div`
  ${tw`mt-10 w-full sm:w-1/2 lg:w-1/3 sm:pr-8`}
  ${(props) =>
    props.featured &&
    css`
      ${tw`w-full!`}
      ${Post} {
        ${tw`sm:flex-row! h-full sm:pr-4`}
      }
      ${Image} {
        ${tw`sm:h-96 sm:min-h-full sm:w-1/2 lg:w-2/3 sm:rounded-t-none sm:rounded-l-lg`}
      }
      ${Info} {
        ${tw`sm:-mr-4 sm:pl-8 sm:flex-1 sm:rounded-none sm:rounded-r-lg sm:border-t-2 sm:border-l-0`}
      }
      ${Description} {
        ${tw`text-sm mt-3 leading-loose text-gray-600 font-medium`}
      }
    `}
`
const Post = tw.div`cursor-pointer flex flex-col bg-gray-100 rounded-lg`
const Image = styled.div`
  ${(props) =>
    css`
      background-image: url('${props.imageSrc}');
    `}
  ${tw`h-12 w-12 bg-cover bg-center rounded-t-lg`}
`
const Info = tw.div`p-8 border-2 border-t-0 rounded-lg rounded-t-none`
const Category = tw.div`uppercase text-primary-500 text-xs font-bold tracking-widest leading-loose after:content after:block after:border-b-2 after:border-primary-500 after:w-8`
const CreationDate = tw.div`mt-4 uppercase text-gray-600 italic font-semibold text-xs`
const Title = tw.div`mt-1 font-black text-2xl text-gray-900 group-hover:text-primary-500 transition duration-300`
const Description = tw.div``

const ButtonContainer = tw.div`flex justify-end`
const LoadMoreButton = tw(PrimaryButton)``
const StyledHeader = styled(Header)`
  ${tw`p-0 max-w-none w-full`}
  ${LogoLink} {
    ${tw`text-black hover:border-gray-300 hover:text-gray-300`}
  }
`;
const PrimaryLink = tw(PrimaryLinkBase)`rounded-full`
const HighlightedText = tw.span`bg-primary-500 text-gray-100 px-4 transform -skew-x-12 inline-block`;

export default ({
  navLinks = [],
  logoLink = (
    <LogoLink href="/home">
      <img src={icon} alt="logo" />
      LightRainAir
    </LogoLink>
  ),
  year = "",
  mat_type = "",
  headingText = "Revelation 10 Minutes Speech Education",
}) => {
  const [visible, setVisible] = useState(6)
  const onLoadMoreClick = () => {
    setVisible((v) => v + 6)
  }
  const [speeches, setSpeeches] = useState([]);

  useEffect(() => {
    async function fetchData() {
      try {
        const table = (year == '40') ? 'revelation_speech_education_texts' : 'revelation_speech_education_texts_41';
        const { data, error } = await supabase
          .from(table)
          .select('*')
          .order('date', {ascending: true})
        console.log(data);
        const words = data.filter(item => item.status === 'published');

        console.log(words);
        setSpeeches(words);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }
    fetchData();
  }, []);

  const formattedDate = (date) => {
    const parts = date.split("-");
    return `${parseInt(parts[0]) - 1983}${parts[1]}${parts[2]}`;
  };

  const thumbnail = (illustration) => {
      return `https://zegleedmcpswaxynxnta.supabase.co/storage/v1/object/public/assets/${illustration}.jpg`;
  }

  return (
    (mat_type === 'video') ? 
    <>
    <AnimationRevealPage>
      <StyledHeader links={navLinks}  logoLink={logoLink}/>
      <EduVideo heading='Revelation Speech Videos' table={(year === '40') ? 'revelation_speech_education_videos' : 'revelation_speech_education_videos_41'} /> 
    </AnimationRevealPage>
    </>
    :
      <>
      <MaterialList headingText={headingText} table={(year === '40') ? 'revelation_speech_education_texts' : 'revelation_speech_education_texts_41'}/>
      </>
    // <AnimationRevealPage>
    //   <StyledHeader links={navLinks}  logoLink={logoLink}/>
    //   <Container>
    //     <ContentWithPaddingXl>
    //       <HeadingRow>
    //         <Heading>{<><HighlightedText>{headingText}</HighlightedText></>}</Heading>
    //       </HeadingRow>
    //       <ButtonContainer>
    //         <LoadMoreButton onClick={(e) => window.history.back()}>Back</LoadMoreButton>
    //       </ButtonContainer>
    //       { (mat_type === 'video') ? <EduVideo heading='Speech Videos' table={(year === '40') ? 'revelation_speech_education_videos' : 'revelation_speech_education_videos_41'} /> :
    //       <>
    //       <Posts>
    //         {speeches.slice(0, visible).map((post, index) => (
    //           <PostContainer key={index} featured={true}>
    //             <Post className='group' as='a' href={`scj${year}/${post.url}`}>
    //               <Image imageSrc={thumbnail(post.illus)} />
    //               <Info>
    //                 <Category>{formattedDate(post.date)}</Category>
    //                 {/* <CreationDate>{formattedDate(post.date)}</CreationDate> */}
    //                 <Title>{post.title}</Title>
    //               </Info>
    //             </Post>
    //           </PostContainer>
    //         ))}
    //       </Posts>
    //       <ButtonContainer>
    //       {visible < speeches.length && (
    //           <LoadMoreButton onClick={onLoadMoreClick}>
    //             Load More
    //           </LoadMoreButton>
    //       )}
    //       </ButtonContainer>
    //       </>
    //       }  
    //     </ContentWithPaddingXl>
    //   </Container>
    // </AnimationRevealPage>
  )
}