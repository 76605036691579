import { Navigate } from 'react-router-dom';
import { useAuth } from './Auth.js';

const ActiveRoute = ({ children, page }) => {
  const { user } = useAuth();

  if (user) {
    return <Navigate to="/home" />;
  }
  else {
    // user is not authenticated
    return <Navigate to="/login" />;
  }
};

export default ActiveRoute;
