import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import tw from 'twin.macro';
import AnimationRevealPage from 'helpers/AnimationRevealPage';
import Header, { LogoLink } from 'components/headers/light';
import GradContainer from '../GradContainer';
import icon from '../../../images/lra.svg';
import trophy_icon from '../../../images/trophy_icon.svg'
import trophy_white_icon from '../../../images/white_trophy.svg'
import NumberComponent from './NumberComponent'; // Make sure to provide the correct path to NumberComponent file
import HeadingWithNumber from './HeadingWithNumber'; // Make sure to provide the correct path to NumberComponent file


const StyledHeader = styled(Header)`
  ${tw`p-0 max-w-none w-full`}
  ${LogoLink} {
    ${tw`text-black hover:border-gray-300 hover:text-gray-300`}
  }
`;

const ButtonContainer = styled.div`
  ${tw`items-center justify-center mt-2`}
`;

const Button = styled(Link)`
  ${tw`flex gap-6 flex-row w-[95%] p-0 bg-primary-500 border-primary-500 border-4 text-gray-100 p-4 transform inline-block text-4xl font-bold m-4`}
`;

const Heading = styled.div`
  ${tw`p-0 gap-4 bg-primary-500 text-gray-100 px-4 justify-center transform -skew-x-12 inline-block w-full text-2xl md:text-4xl font-bold m-3 md:m-20 text-center`}
`;

const IconButton = styled.div`
   ${tw`flex flex-row w-full justify-center`}

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    gap: 0; // remove extra space between items
    margin-bottom: 0; // remove extra space at the bottom
  }
`;

const ButtonIcon = styled.img`
    ${tw`h-8 md:h-16`}
`
const ButtonHeading = styled.p`
  ${tw`text-2xl md:text-4xl`}
`
const ButtonSubheading = styled.p`
  ${tw`text-sm md:text-lg text-opacity-25`}

  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;

const HeadingWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export default () => {

  return (
    <AnimationRevealPage>
      <StyledHeader links={[]} logoIcon={icon} />
      <GradContainer heading="Evangelism Competition" />
    
      
      <HeadingWithNumber number={1} heading="Individual Prizes"/>
      <ButtonContainer>
        <IconButton>
          <Button to="/united-grad-competition/evangelism/fruit-whisperer">
            <ButtonIcon src={trophy_white_icon} />
            <div>
              <ButtonHeading>Fruit Whisperer</ButtonHeading>
              <ButtonSubheading>Most Fruit Networked In Year 41</ButtonSubheading>
            </div>
          </Button>
        </IconButton>

        <IconButton>
          <Button to="/united-grad-competition/evangelism/fruit-captivator">
            <ButtonIcon src={trophy_white_icon} />
            <div>
              <ButtonHeading>Fruit Captivator</ButtonHeading>
              <ButtonSubheading>Most BB Students Taught In Year 41</ButtonSubheading>
            </div>
          </Button>
        </IconButton>

      </ButtonContainer>


      <br />
      <HeadingWithNumber number={2} heading="National Prizes"/>
      <ButtonContainer>

      <IconButton>
          <Button to="/united-grad-competition/evangelism/first-registration">
            <ButtonIcon src={trophy_white_icon} />
            <div>
              <ButtonHeading>Center Opening Registration</ButtonHeading>
              {/* <ButtonSubheading>Regional Goal Achievement Percentage</ButtonSubheading> */}
            </div>
          </Button>
        </IconButton>

        <IconButton>
          <Button to="/united-grad-competition/evangelism/second-registration">
            <ButtonIcon src={trophy_white_icon} />
            <div>
              <ButtonHeading>Intermediate Registration</ButtonHeading>
              {/* <ButtonSubheading>Regional Goal Achievement Percentage</ButtonSubheading> */}
            </div>
          </Button>
        </IconButton>

        <IconButton>
          <Button to="/united-grad-competition/evangelism/ebbt-qualified">
            <ButtonIcon src={trophy_white_icon} />
            <div>
              <ButtonHeading>EBBT Qualified</ButtonHeading>
              <ButtonSubheading>Expert BBTs Qualified in Year 41</ButtonSubheading>
            </div>
          </Button>
        </IconButton>
      </ButtonContainer>

    </AnimationRevealPage>
  )
}
