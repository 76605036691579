import React, { useEffect, useState } from "react";
import InputMask from "react-input-mask";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container as ContainerBase } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import {css} from "styled-components/macro"; //eslint-disable-line
import bg_illustration from "images/laptop_sky.png";
import illustration from "images/Mascots.png";
import logo from "images/lra_pink.svg";
import { ReactComponent as LoginIcon } from "feather-icons/dist/icons/log-in.svg";
import { ReactComponent as CloseIcon } from "feather-icons/dist/icons/x.svg";
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import supabaseClient from "helpers/SupabaseClient.js";
import { Login } from "helpers/Auth.js";
import ReactModalAdapter from "../helpers/ReactModalAdapter.js";
import { PrimaryButton } from "../components/misc/Buttons.js"

const Container = tw(ContainerBase)`min-h-screen bg-primary-900 text-white font-medium flex justify-center -m-8`;
// const Content = tw.div`max-w-screen-xl m-0 sm:mx-20 sm:my-16 bg-white text-gray-900 shadow sm:rounded-lg flex-col justify-center flex-1`;
const MainContainer = tw.div`lg:w-full p-2 justify-center`;
const LogoLink = tw.a`lg:hidden`;
const LogoImage = tw.img`h-12 mx-auto m-8`;
const MainContent = tw.div`mt-4 flex flex-col items-center`;
const Heading = tw.h1`text-2xl xl:text-3xl font-extrabold`;
const FormContainer = tw.div`w-full flex-1 mt-4`;

const Content = styled.div`
  ${props => `background-image: url("${props.imageSrc}");`}
  ${tw`max-w-screen-xl m-0 sm:mx-20 sm:my-16 bg-white text-gray-900 shadow sm:rounded-lg flex-col justify-center flex-1`}
  ${tw`sm:rounded-r-lg sm:rounded-l-lg flex flex-col text-center lg:flex justify-center bg-contain bg-center bg-cover bg-no-repeat`}
`;

const DividerTextContainer = tw.div`my-12 border-b text-center relative`;
const DividerText = tw.div`leading-none px-2 inline-block text-sm text-gray-600 tracking-wide font-medium bg-white transform -translate-y-1/2 absolute inset-x-0 top-1/2 bg-transparent`;

const Form = tw.form`mx-auto max-w-xs`;
const Input = tw.input`w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-5 first:mt-0`;
const SubmitButton = styled.button`
  ${tw`mt-5 tracking-wide font-semibold bg-primary-500 text-gray-100 w-full py-4 rounded-lg hover:bg-primary-900 transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none`}
  .icon {
    ${tw`w-6 h-6 -ml-2`}
  }
  .text {
    ${tw`ml-3`}
  }
`;
const OptionButton = styled.button`
  ${tw`mt-5 tracking-wide font-semibold bg-primary-500 text-gray-100 w-full py-3 rounded-lg hover:bg-primary-900 transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none`}
  .icon {
    ${tw`w-3 h-3 -ml-2`}
  }
  .text {
    ${tw`ml-1 text-sm`}
  }
`;
const IllustrationContainer = styled.div`
  ${props => `background-image: url("${props.imageSrc}");`}
  ${tw`sm:rounded-r-lg sm:rounded-l-lg flex flex-col text-center hidden lg:flex justify-center bg-contain bg-center bg-cover bg-no-repeat`}
`;
const IllustrationImage = styled.img`
  ${props => `background-image: url("${props.imageSrc}");`}
  ${tw`w-full bg-no-repeat`}
`;
const MascotImage = tw.img`h-[180px] mx-auto mt-4`;
const HighlightedText = tw.span`bg-primary-500 text-gray-100 px-4 transform -skew-x-12 inline-block`;
const StyledModal = styled(ReactModalAdapter)`
  &.mainHeroModal__overlay {
    ${tw`fixed inset-0 z-50`}
  }
  &.mainHeroModal__content {
    ${tw`xl:mx-auto m-4 sm:m-16 max-w-screen-xl absolute inset-0 flex justify-center items-center rounded-lg bg-gray-200 outline-none`}
  }
  .content {
    ${tw`w-full lg:p-16`}
  }
`;
const CloseModalButton = tw.button`absolute top-0 right-0 mt-8 mr-8 hocus:text-primary-500`;
const HeaderContainer = styled.div`
  display: flex-column;
  justify-content: center;
  align-items: center;
  gap: 20px;
`;
const HeroHeading = styled.h1`
  ${tw`text-6xl text-center sm:text-4xl lg:text-5xl xl:text-6xl font-black text-white leading-snug mb-4 mr-4`}
  text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.9);
  span {
    ${tw`inline-block mt-2`}
`;

const OptionsContainer = tw.div`mx-auto max-w-xs flex`;
const OptionButtonWrapper = styled.div`
  width: 50%;
`;

export default ({
  logoLinkUrl = "#",
  illustrationImageSrc = illustration,
  headingText = "LightRainAir",
  submitButtonText = "Sign In",
  SubmitButtonIcon = LoginIcon,
  forgotPasswordUrl = "/forgot-password",
  signupUrl = "/register",

}) => {
  const [scj_id, setScjId] = useState('')
  const [password, setPassword] = useState('')
  const [showPassword, setShowPassword] = useState(false)
  const [session, setSession] = useState(null)
  const [result, setResult] = useState("");
  const [message, setMessage] = useState("");
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [loggingIn, setLoggingIn] = useState(false);

  const toggleModal = () => setModalIsOpen(!modalIsOpen);
  
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  
  useEffect(() => {
    supabaseClient.auth.getSession().then(({ data: { session } }) => {
      setSession(session);
    })

    supabaseClient.auth.onAuthStateChange((_event, session) => {
      setSession(session)
    })
  }, []);

  const handleLogin = async (event) => {
    setLoggingIn(true);
    event.preventDefault();

    var login_result = await Login(scj_id, password);


    setResult(login_result[0].result);
    setMessage(login_result[0].message);

    if (login_result.length > 0 && login_result[0].result !== "Success") {
      setModalIsOpen(!modalIsOpen);
    }

    setLoggingIn(false);

    if (login_result[0].result === "Success"){
      setTimeout(() => {
        window.location.href = "/home"
      }, 1000)
      
    }

  } 

  return (
    <AnimationRevealPage>
      <Container>
        <Content imageSrc={bg_illustration}>
          {/* <IllustrationContainer imageSrc={bg_illustration}> */}
          <IllustrationContainer>
              <MascotImage src={illustrationImageSrc} />
              <HeroHeading>Welcome to LightRainAir</HeroHeading>
          </IllustrationContainer>
          <MainContainer>
            <LogoLink href={logoLinkUrl}>
              <LogoImage src={logo} />
            </LogoLink>
            <MainContent>
              <Heading>{<>Sign in to <HighlightedText>{headingText}</HighlightedText></>}</Heading>
              <FormContainer>
                <Form onSubmit={handleLogin}>
                  <InputMask mask={"99999999-99999"} type="text" value={scj_id} onChange={(e) => setScjId(e.target.value)}  maskChar={null} maskPlaceholder={null}>
                    <Input type="scj_id" pattern="^\d{8}-\d{5}$" placeholder="ID Number (00400314-12345)" value={scj_id} required/>
                  </InputMask>
                  <div tw="mt-6 relative">
                    <Input type={showPassword ? "text" : "password"} placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} required tw="pr-10"/>
                    <button type="button" onClick={togglePasswordVisibility} tw="absolute top-1/2 transform -translate-y-1/2 -translate-x-8 text-primary-500 bg-transparent border-none cursor-pointer">{showPassword ? <FaEyeSlash/> : <FaEye/>}</button>
                  </div>
                  <SubmitButton type="submit" >
                    <SubmitButtonIcon className="icon" />
                    <span className="text">{loggingIn == true ? "Signing in..." : submitButtonText}</span>
                  </SubmitButton>
                </Form>
                  {/* <DividerTextContainer></DividerTextContainer> */}
                <OptionsContainer>
                  <OptionButtonWrapper style={{ marginRight: '5px'}}>
                  <a href={forgotPasswordUrl}>
                    <OptionButton>
                      {/* <ForgotButtonIcon className="icon" /> */}
                      <span className="text">{"Forgot Password?"}</span>
                    </OptionButton>
                  </a>
                  </OptionButtonWrapper>
                  {"   "}
                  <OptionButtonWrapper style={{ marginLeft: '5px'}}>
                  <a href={signupUrl}>
                    <OptionButton>
                      {/* <RegisterButtonIcon className="icon" /> */}
                      <span className="text">{"Register"}</span>
                    </OptionButton>
                  </a>
                  </OptionButtonWrapper>
                </OptionsContainer>
                  <div tw="w-full mx-auto max-w-xs">
                    <p tw="mt-2 text-sm text-gray-600 text-right"> 
                      Don't have an account?
                    </p>
                  </div>
              </FormContainer>
              <StyledModal
                closeTimeoutMS={300}
                className="mainHeroModal"
                isOpen={modalIsOpen}
                onRequestClose={toggleModal}
                shouldCloseOnOverlayClick={true}
              >
                <CloseModalButton onClick={toggleModal}>
                  <CloseIcon tw="w-6 h-6" />
                </CloseModalButton>
                <MainContent>
                  <LogoLink href={logoLinkUrl}>
                    <LogoImage src={logo} />
                  </LogoLink>
                  <Heading>{`${result}`}</Heading>
                  <br/><br/>
                  <p tw="text-center">{message}</p>
                  <br/><br/>
                  { (result !== "Success") ?
                    <p tw="text-center">
                      <PrimaryButton onClick={toggleModal}>Back</PrimaryButton>
                    </p> : ""
                  }
                </MainContent>
              </StyledModal>
            </MainContent>
          </MainContainer>
        </Content>
      </Container>
    </AnimationRevealPage>
  )
};
