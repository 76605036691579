import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import tw from "twin.macro";
import styled from "styled-components";
import supabase from "helpers/SupabaseClient";
import * as SupabaseAPI from "helpers/SupabaseAPI";
import { DayPicker } from "react-day-picker";
import "react-day-picker/style.css";
import "./styles.css"


const Container = tw.div`relative`;
const Content = tw.div`max-w-screen-xl mx-auto`;
const HeadingRow = tw.div`flex justify-between items-center sm:flex-col md:flex-row`;
const Heading = tw.h2`text-2xl sm:text-4xl font-black tracking-wide text-center text-gray-900`;
const HighlightedText = tw.span`bg-primary-500 text-gray-100 px-4 transform -skew-x-12 inline-block`;
const SearchContainer = tw.div`flex justify-start`
const Input = tw.input`w-full px-6 py-3 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-5 first:mt-0`;
const SearchFilterColumn = tw.span`w-full md:w-1/12`
const ExportButton = styled.button`
  ${tw`mt-5 tracking-wide font-semibold bg-primary-500 text-gray-100 w-full py-4 rounded-lg hover:bg-primary-900 transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none`}
  .icon {
    ${tw`w-6 h-6 -ml-2`}
  }
  .text {
    ${tw`ml-3`}
  }
`;

export default ({
     columns = [
        {
            name: 'Registration Number',
            search_value: 'scj_id',
            selector: row => row.scj_id,
            sortable: true,
        },
        {
            name: "Name",
            search_value: 'name',
            selector: row => row.name,
            sortable: true,
        },
        {
            name: "Email",
            search_value: 'email',
            selector: row => row.email,
            sortable: true,
        },
        {
            name: "Region of Registration",
            search_value: 'region',
            selector: row => row.region,
            sortable: true,
        },
        {
            name: "Country of Access",
            search_value: 'country_of_access',
            selector: row => row.country_of_access,
            sortable: true,
        },
        {
            name: "City of Access",
            search_value: 'city_of_access',
            selector: row => row.city_of_access,
            sortable: true,
        },
        {
            name: "DateTime",
            search_value: 'created_at',
            selector: row => new Date(row.created_at).toString(),
            sortable: true,
        }
    ],

    data = [
        {
            registration_number: "00400120-00089",
            country: "South Africa",
            datetime: "2024-05-25 16:05:00"
        },
        {
            registration_number: "00400120-00083",
            country: "Namibia",
            datetime: "2024-05-25 16:05:00"
        },
        {
            registration_number: "00390120-00189",
            country: "South Africa",
            datetime: "2024-05-25 16:05:00"
        },
        {
            registration_number: "00400327-00122",
            country: "Kenya",
            datetime: "2024-05-25 16:05:00"
        },
    ],   
    heading = "Logins Log Data"
}) => {

    const [logs, setLogs] = useState([])
    const [searchQuery, setSearchQuery] = useState('');
    const [debounceTimeout, setDebounceTimeout] = useState(null);
    const [showingSearchResults, setShowingSearchResults] = useState(false);

    const [table_ord, setTableOrd] = useState("created_at");
    const [searchColumn, setSearchColumn] = useState("name|Name")
    const [dateSelected, setDateSelected] = useState(new Date())
    const [downloadingLogs, setDownloadingLogs] = useState(false);

    const exportLogs = async () => {

        var whole_table = '';

        var offset = 0;
        var limitSize = 1000;
        var retrievedCount = 0;
        
        
        setDownloadingLogs(true)

        const { _data, _error, count } = await supabase
        .from('user_login_logs')
        .select("*", { count: "exact", head: true });

        console.log(count)

        const a = document.createElement('a');

        while (retrievedCount < count) {

            var data = await getTableRows(limitSize, offset)

            whole_table += data;
            offset += 1000;
            retrievedCount += 1000;

            console.log(offset)
            console.log(retrievedCount)

            const blob = new Blob([data], { type: "text/csv;charset=utf-8" });
            const url = window.URL.createObjectURL(blob);
            
            a.href = url;
            a.download = `light_rain_air_user_login_logs-${new Date().toString()}.csv`;
            a.click();
        }
        


        setDownloadingLogs(false)
    }

    const getTableRows = async (limit, offset) => {

        const { data, error } = await supabase
        .from('user_login_logs')
        .select('*')
        .order('created_at', { ascending: false })
        .range(offset, offset + limit)
        .csv()

        return data
    }

    const handleSearchChange = (event) => {
        const { value } = event.target;
        setSearchQuery(value);
        if (debounceTimeout) {
          clearTimeout(debounceTimeout);
        }
    
        const newTimeout = setTimeout(() => {
            searchByColumn(value);
        }, 200);  // 100 ms delay for debouncing
    
        setDebounceTimeout(newTimeout);
      };

    const searchByColumn = async (value) => {
        try {
            let data;
            if (!value.trim()) {
            // Fetch all entries if the search query is empty
            data = await SupabaseAPI.getAllEntries('user_login_logs', table_ord);
            } 
            else if (value.trim() == "DateTime") {
            data = await SupabaseAPI.searchByDate('user_login_logs', "some_date", table_ord)
            }
            else {
            // Perform a full-text search if there is a query
            data = await SupabaseAPI.searchByColumn('user_login_logs', searchColumn, value, table_ord);
            }
            setLogs(data);
            setShowingSearchResults(true);
        } catch (error) {
            console.error('Error fetching data:', error);
            setLogs([]); // Handle error by clearing results
            setShowingSearchResults(false);
        }
    }


    const searchByDate = async (value) => {
        try {
            let data;
            data = await SupabaseAPI.searchByDate('user_login_logs', 'created_at', value, table_ord)

            setLogs(data);
            setShowingSearchResults(true);
        } catch (error) {
            console.error('Error fetching data:', error);
            setLogs([]); // Handle error by clearing results
            setShowingSearchResults(false);
        }
    }

    const handleCheckChange = (event) => {
        const { value } = event.target;
        console.log(value)
        setSearchColumn(value);
    }

    const handleDateChange = (date) => {
        setDateSelected(date)

        console.log((new Date(date)).toISOString())

        searchByDate(date)
        
    }

    useEffect(() => {
        async function fetchLogs(){
            try {
                const { data, error } = await supabase
                    .from('user_login_logs')
                    .select('*')
                    .order('created_at', { ascending: false });
                setLogs(data);
            } catch (error) {
                console.error('Error fetching logs:', error); 
            }
        }
        fetchLogs();
    }, [])

    return (
        <Container>
            <Content>
                <HeadingRow>
                  <Heading>
                    <HighlightedText>{heading}</HighlightedText>
                  </Heading>

                </HeadingRow>
                {searchColumn != 'created_at|DateTime' && <SearchContainer style={{ margin: '20px' }}>
                  <Input
                    type="text"
                    style={{
                      fontSize: '18px',  // Larger font size
                      border: '2px solid pink',  // Pink border
                      padding: '8px',
                      width: '100%'
                    }}
                    placeholder={`Search by ${searchColumn.split("|")[1]}`}
                    value={searchQuery}
                    onChange={handleSearchChange}
                  />
                </SearchContainer>}
                <p style={{marginLeft: '40px', textAlign: "center"}}>Search by column:</p>
                {columns.map((col) => {

                    return <>
                    <SearchFilterColumn>
                    <Input
                        type="checkbox"
                        style={{
                        fontSize: '18px',  // Larger font size
                        border: '2px solid pink',  // Pink border
                        padding: '8px',
                        width: '10%',
                        marginLeft: '20px'
                        }}
                        value={`${col.search_value.toLowerCase()}|${col.name}`}
                        id={col.search_value.toLowerCase()}
                        checked={searchColumn == `${col.search_value.toLowerCase()}|${col.name}`}
                        onChange={handleCheckChange}
                    />
                    <label for={col.search_value.toLowerCase()}>{col.name}</label>
                    </SearchFilterColumn>
                    </>
                  })}
                <br />
                <br />
                <br />

                {searchColumn == 'created_at|DateTime' && <DayPicker
                    mode="single"
                    selected={dateSelected}
                    onSelect={handleDateChange}
                />
                }
                <ExportButton onClick={() => exportLogs()} disabled={downloadingLogs == true} >
                    {downloadingLogs == true? 'Busy downloading logs...' : 'Export Logs' }
                </ExportButton>
                <br />
                <br />
                <DataTable
                    // title="Logins Log Data"
                    columns={columns}
                    data={logs}
                    selectableRows
                    pagination
                />
            </Content>
        </Container>
    )
}