import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import tw from 'twin.macro';
import AnimationRevealPage from 'helpers/AnimationRevealPage';
import Header, { LogoLink } from 'components/headers/light';
import icon from '../../images/lra.svg';
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import EvangelismScoreboardContainer from './EvangelismScoreboardContainer';

const HeadingRow = tw.div`flex justify-between items-center sm:flex-col md:flex-row`;
const Heading = tw.h2`text-2xl sm:text-4xl font-black tracking-wide text-center text-gray-900`;
const StyledHeader = styled(Header)`
  ${tw`p-0 max-w-none w-full`}
  ${LogoLink} {
    ${tw`text-black hover:border-gray-300 hover:text-gray-300`}
  }
`;

const ButtonContainer = styled.div`
  ${tw`flex flex-col items-center mt-0 justify-center`}
`;

const ButtonContent = styled.div`
  ${tw`border-primary-500 border-4 border-t-2 border-l-2 border-double border-b-8 border-r-8 text-center p-4`}
  width: 250px; /* Example fixed width */
  height: 70px; /* Example fixed height */
  box-sizing: border-box; /* Include padding and border in element's total width and height */
`;

const TextContent = styled.div`
  ${tw`block text-lg font-bold text-center`}
`

const IconButton = styled.div`
  ${tw`flex flex-row w-auto -ml-4 md:m-auto h-24`}
`

const ButtonIcon = styled.img`
    ${tw`h-[4.5rem] -mr-[7rem] mt-[3rem]`} 
`



const Button = styled(Link)`
  ${tw`p-0 text-primary-500 px-4 transform -skew-x-12 inline-block text-4xl font-bold m-12 mb-16`} 
`;

const HighlightedText = tw.span`bg-primary-500 text-gray-100 px-4 transform -skew-x-12 inline-block`;

export default ({
  headingText="Evangelism Noticeboard"
}) => {

  return (
    <AnimationRevealPage>
      <StyledHeader links={[]} logoIcon={icon} />
      {/* <EvangelismScoreboardContainer heading="Noticeboard"/> */}
      <ContentWithPaddingXl>
        <HeadingRow>
          <Heading>{<><HighlightedText>{headingText}</HighlightedText></>}</Heading>
        </HeadingRow>

      <ButtonContainer>
        
        <IconButton>
          {/* <ButtonIcon src={trophy} /> */}
          <Button to="/noticeboard/cpt">
          <ButtonContent>
            <TextContent>CPT</TextContent>
          </ButtonContent>
          </Button>
        </IconButton>

        <IconButton>
        {/* <ButtonIcon src={trophy} /> */}
        <Button to="/noticeboard/nam">
          <ButtonContent>
            <TextContent>NAM</TextContent>
          </ButtonContent>
        </Button>
        </IconButton>

        <IconButton>
        {/* <ButtonIcon src={trophy} /> */}
        <Button to="/noticeboard/rtb">
          <ButtonContent>
            <TextContent>RTB</TextContent>
          </ButtonContent>
        </Button>
        </IconButton>

        <IconButton>
        {/* <ButtonIcon src={trophy} /> */}
        <Button to="/noticeboard/pe">
          <ButtonContent>
            <TextContent>PE</TextContent>
          </ButtonContent>
        </Button>
        </IconButton>

        <IconButton>
        {/* <ButtonIcon src={trophy} /> */}
        <Button to="/noticeboard/pmb">
          <ButtonContent>
            <TextContent>PMB</TextContent>
          </ButtonContent>
        </Button>
        </IconButton>

        <IconButton>
        {/* <ButtonIcon src={trophy} /> */}
        <Button to="/noticeboard/dbn">
          <ButtonContent>
            <TextContent>DBN</TextContent>
          </ButtonContent>
        </Button>
        </IconButton>

        <IconButton>
        {/* <ButtonIcon src={trophy} /> */}
        <Button to="/noticeboard/zim">
          <ButtonContent>
            <TextContent>ZIM</TextContent>
          </ButtonContent>
        </Button>
        </IconButton>

        <IconButton>
        {/* <ButtonIcon src={trophy} /> */}
        <Button to="/noticeboard/jhb">
          <ButtonContent>
            <TextContent>JHB</TextContent>
          </ButtonContent>
        </Button>
        </IconButton>
      </ButtonContainer>
      </ContentWithPaddingXl>
    </AnimationRevealPage>
  )
}
