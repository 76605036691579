import React, { useEffect, useState } from 'react';
import supabase from './SupabaseClient';
import { useAuth } from './Auth.js';


export default () => {
  const { user } = useAuth();
  const [userId, setUserId] = useState();

  useEffect(() => {
    async function setUser() {
      try {
        const { data, error } = await supabase
          .from("access_report")
          .select("*")
          .eq("email", user.email);
        console.log(data);
        setUserId(data[0].registration_number);
      } catch (error) { 
        console.error(error);
      }
    };
    setUser();
  }, []);

  return (
    <div
      style={{
        position: 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%) rotate(-45deg)',
        zIndex: 9999,
        opacity: 0.4,
        pointerEvents: 'none',
        fontSize: 'calc(1rem + 5vw)',
        color: '#d3d3d3',
        fontWeight: 'bold',
        whiteSpace: 'nowrap'
      }}
    >
      { userId }
    </div>
  );
};


export function watermarkImageWithText(userId) {
  const canvas = document.createElement("canvas")
  const context = canvas.getContext("2d")

  const image = document.createElement("img")
  image.src = "../images/transparent.png"

    //Smaller window size, scale down watermark
  let is_smaller_window = window.matchMedia("(max-width: 750px)");

  console.log(is_smaller_window)
  
  var canvasWidth = 0
  var canvasHeight = 0

  if (is_smaller_window.matches == true) {
    canvasWidth = window.innerWidth + 2000
    canvasHeight = window.innerHeight + 2000
  } else {
    canvasWidth = window.innerWidth + 1000
    canvasHeight = window.innerHeight + 1000
  }
  

  canvas.width = canvasWidth
  canvas.height = canvasHeight

  const watermarkText = userId

  const centerX = canvas.width / 2;
  const centerY = canvas.height / 2;

  // Set text properties
  context.font = "33em Arial"
  context.fillStyle = "rgba(83,83,83,0.6)"
  
  context.textBaseline = "middle"

  const textWidth = context.measureText(watermarkText).width;
  context.rotate(-Math.PI*2);

  // Clear the canvas
  context.clearRect(0, 0, canvas.width, canvas.height);

  // Translate to the center of the canvas
  context.translate(centerX, centerY);

  // Rotate the text by 45 degrees counterclockwise
  context.rotate(-Math.PI / 6);

  // Draw the rotated text
  context.fillText(watermarkText, -textWidth/2, 0);
  // context.fillText(watermarkText, 0, canvasHeight)

  // Restore the previous transformation matrix
  context.restore();
  // initializing the canvas with the original image
  context.drawImage(image, 0, 0, canvasWidth, canvasHeight)

  console.log(canvas.toDataURL());
  return canvas.toDataURL()
}