import { ReactComponent as ChevronLeftIcon } from "feather-icons/dist/icons/chevron-left.svg";
import { ReactComponent as ChevronRightIcon } from "feather-icons/dist/icons/chevron-right.svg";
import { ReactComponent as SearchIcon } from "feather-icons/dist/icons/search.svg";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons";
import { useEffect, useState, useMemo } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import tw from 'twin.macro';
import styled from "styled-components";
import ReactModalAdapter from "../helpers/ReactModalAdapter.js";
import { ReactComponent as CloseIcon } from "feather-icons/dist/icons/x.svg";
import logo from "images/lra_pink.svg";
import Contents from "./ContentsDialog.js";
import ReactAudioPlayer from "react-audio-player";


const ControlButton = styled(PrimaryButtonBase)`
  ${tw`mt-4 sm:mt-0 first:ml-0 ml-6 rounded-full p-2`}
  svg {
    ${tw`w-6 h-6`}
  }
`;
const PrevButton = tw(ControlButton)``;
const NextButton = tw(ControlButton)``;
const SearchButton = tw(ControlButton)``;
const TableOfContentsButton = tw(ControlButton)``;
const NavPaginationContainer = tw.div`flex justify-center items-center space-x-4`;
const InputContainer = tw.div`flex justify-center items-center mx-auto space-x-4`;
const PDFContainer = tw.div`flex justify-center mx-auto my-auto h-full w-full max-w-full max-h-full shadow-2xl`;
const ContentsContainer = tw.div`flex justify-center items-center mx-auto`;
const Input = tw.input`w-full max-w-full px-12 py-3 rounded-full font-medium bg-gray-100 border border-primary-200 
placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-5 first:mt-0 justify-center`;

const NavContainer = tw.div`flex justify-between items-center w-full`;

const StyledModal = styled(ReactModalAdapter)`
  &.mainHeroModal__overlay {
    ${tw`fixed inset-0 z-50`}
  }
  &.mainHeroModal__content {
    ${tw`xl:mx-auto m-4 sm:m-16 max-w-screen-xl absolute inset-0 flex justify-center items-center rounded-lg bg-gray-200 outline-none`}
  }
  .content {
    ${tw`w-full lg:p-16`}
  }
`;
const CloseModalButton = tw.button`absolute top-0 right-0 mt-8 mr-8 hocus:text-primary-500`;
const LogoLink = tw.a``;
const LogoImage = tw.img`h-12 mx-auto`;
const MainContent = tw.div`mt-12 flex flex-col items-center`;
const Heading = tw.h1`text-2xl xl:text-3xl font-extrabold`;

pdfjs.GlobalWorkerOptions.workerSrc = `https://unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;
export default function PdfViewer(props) {
  const [numPages, setNumPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [inputNumber, setInputNumber] = useState(1);
  const [loading, setLoading] = useState(true);
  const [pageWidth, setPageWidth] = useState(0);
  const [message, setMessage] = useState("");
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [dialogIsOpen, setDialogIsOpen] = useState(false);
  const [content, setContent] = useState([]);
  const [renderPage, setRenderPage] = useState(false);

  useEffect(() => {
    setContent(props.pdf.contents);
    console.log(content);
    // prepContent();
  }, []);

  const toggleModal = () => setModalIsOpen(!modalIsOpen);
  const toggleDialog = () => {
    setContent(props.pdf.contents)
    setDialogIsOpen(true)
  };

  function onDocumentLoadSuccess({ numPages: nextNumPages }) {
    setNumPages(nextNumPages);
    setRenderPage(true);
  }

  function onPageLoadSuccess() {
    setPageWidth(window.innerWidth);
    setLoading(false);
  }

  const handlePdfLoadError = (error) => {
    console.error('PDF load error:', error);
    // Handle the error (e.g., display an error message to the user)
  }

  const options = useMemo(() => {
    return {
      cMapUrl: "cmaps/",
      cMapPacked: true,
      standardFontDataUrl: "standard_fonts/"
    }
  }, []);

  function goToNextPage() {
    setPageNumber((prevPageNumber) => prevPageNumber + 1);
  }

  function goToPreviousPage() {
    setPageNumber((prevPageNumber) => prevPageNumber - 1);
  }

  function searchPage() {
    goToPage(inputNumber);
  }

  function goToPage(newPage) {
    if (newPage >= 1 && newPage <= numPages) {
      setPageNumber(newPage);
    }
    // setTimeout(() => {
    //   const pageElement = document.querySelector(`[data-page-number="${pageNumber}"]`);
    //   if (pageElement) {
    //     pageElement.scrollIntoView({ behavior: 'smooth' });
    //   }
    // }, 1000);
  }
  
  console.log(props);
  // console.log(audio)
  return (
    <>
      <div hidden={loading} tw="relative">
        <NavContainer>
          { props.audio === true ? 
            <>
              <InputContainer>
              { props.pdf.contents != null ?
                <ReactAudioPlayer
                  src={`https://zegleedmcpswaxynxnta.supabase.co/storage/v1/object/public/assets/${props.pdf.contents}.mp3`}
                  controls
                  controlsList="nodownload"
                /> : ""
              }
              </InputContainer>
            </>
          : <>
            <ContentsContainer>
              { props.pdf.contents != null ?
              <>
                <Contents 
                  open={dialogIsOpen} 
                  handleClose={() => setDialogIsOpen(false)} 
                  contents={props.pdf.contents} 
                  onSelect={(page) => setPageNumber(page)} 
                  render={!props.audio}
                />
                <TableOfContentsButton onClick={toggleDialog}>
                  Contents
                </TableOfContentsButton>
              </> : ""
              }
            </ContentsContainer>

            <InputContainer>
              <Input type="number" placeholder="Page Number" min={1} max={numPages} onChange={(e) => setInputNumber(parseInt(e.target.value, 10))} />
              <SearchButton onClick={searchPage}>
                <SearchIcon tw="h-10 w-10" aria-hidden="true" />
              </SearchButton>
            </InputContainer>
          </>
            }
        </NavContainer>

        <br></br>
        <PDFContainer>
          <Document
            file={props.pdf.file}
            onLoadSuccess={onDocumentLoadSuccess}
            options={options}
            renderMode="canvas"
            onLoadError={handlePdfLoadError}
          >
            { renderPage ? 
              <>
              {/* {Array.from({ length: numPages }, (_, i) => ( */}
                  <Page
                    tw=""
                    key={pageNumber}
                    pageNumber={pageNumber}
                    renderAnnotationLayer={false}
                    renderTextLayer={false}
                    onLoadSuccess={onPageLoadSuccess}
                    onRenderError={() => setLoading(false)}
                    width={Math.max(pageWidth * 0.6, 340)}
                    // height={window.innerHeight * 0.5}
                  /> 
                {/* ))}  */}
              </>
              : ""
            }
          </Document>
        </PDFContainer>

        <br></br>

        <NavPaginationContainer>
          <PrevButton onClick={goToPreviousPage} disabled={pageNumber <= 1}>
            <ChevronLeftIcon tw="h-10 w-10" aria-hidden="true" />
          </PrevButton>
          <span> {pageNumber} / {numPages}</span>
          <NextButton onClick={goToNextPage} disabled={pageNumber >= numPages}>
            <ChevronRightIcon tw="h-10 w-10" aria-hidden="true" />
          </NextButton>
        </NavPaginationContainer>
      </div>
    </>
  );
}