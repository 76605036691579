import React, { useEffect, useState } from 'react';
import supabase from 'helpers/SupabaseClient';
import { secondRegistrationData } from '../../../components/table/dataObject';
import styled from 'styled-components';
import tw from 'twin.macro';
import AnimationRevealPage from 'helpers/AnimationRevealPage';
import Header, { LogoLink } from 'components/headers/light';
import GradContainer from '../GradContainer';
import icon from '../../../images/lra.svg';
import { Link } from 'react-router-dom';
import LogsFooter from "components/footers/LogsFooter";


const StyledHeader = styled(Header)`
  ${tw`p-0 max-w-none w-full`}
  ${LogoLink} {
    ${tw`text-black hover:border-gray-300 hover:text-gray-300`}
  }
`;

const ButtonContainer = styled.div`
  ${tw`flex flex-col items-center mt-8 w-full`}
`;

const getCardStyle = (index) => {
    switch (index) {
        case 1:
        case 2:
        case 3:
            return tw`bg-primary-500 text-white`;
        default:
            return tw`border-2 border-primary-500 text-primary-500 `;
    }
};

const getIndexStyle = (index) => {
  switch (index) {
      case 1:
      case 2:
      case 3:
          return tw`bg-primary-500 text-white`;
      default:
          return tw`border-2 border-primary-500 text-primary-500 `;
  }
};

const Button = styled(Link)`
    ${tw`rounded p-3 my-4 w-[90%] md:w-1/2 text-center relative flex items-center font-bold `}
    ${({ index }) => getCardStyle(index)}
`;

const IndexNumber = styled.span`
  ${tw`shadow inline-block p-2 text-2xl absolute left-0 bg-white w-12 border-2 font-bold -skew-x-12`}
  ${({ index }) => getIndexStyle(index)}
  box-shadow: 1px 1px 1px 3px #fff, -1px 0px 0 5px #ed64a6; 
`;

const Content = styled.div`
  ${tw`flex justify-between items-center w-full px-8 font-bold`}
`;

const Region = styled.div`
  ${tw`text-center flex-grow`}
`;

export default ({title, table, filter=null}) => {
    const [info, setInfo] = useState(null)
    const [isPercent, setPercent] = useState("")

    useEffect(() => {
      async function fetchData() {
        try {
          const { data, error } = await supabase
            .from(table)
            .select('*')
          console.log(data);
          
          setInfo(prepData(data));
        } catch (error) {
          console.error('Error fetching data:', error)
        }
      }
      if (table !== ""){
        fetchData();
      } else {
        setInfo(null)
      }
    }, [])

    function prepData(data){
      console.log(data);
      if (data.length > 0){
        if (data[0].average || data[0].lessons_taught){
          data.forEach((item, index) => {
            item.value = item.average
            if (!item.average) {
              item.value = filter === "lessons" ? item.lessons_taught : item.pastors_referred;
            }
          });
          console.log(data);
          if (data[0].average){
            setPercent(true);
          }
          // sort based on value
          data.sort((a, b) => b.value - a.value);
        }
        if (!data[0].rank){
          var prevValue = -1;
          data.forEach((item, index) => {
            var position = index + 1;
            prevValue = index > 0 ? data[index-1].value : prevValue;
            if (item.value === prevValue) {
              item.rank = data[index-1].rank;
            } else {
              item.rank = position;
            }
          });
        }
      }
      return data;
    }

    return (
      <>
      <AnimationRevealPage className="flex flex-col min-h-screen h-auto">
            <StyledHeader links={[]} logoIcon={icon} />
            <GradContainer heading={title}/>
            <ButtonContainer>
            {info !== null && info.map((item) => (
              <Button key={item.rank} index={item.rank}>
                  <IndexNumber index={item.rank}>{item.rank}</IndexNumber>
                    <Content>
                        <Region>{item.region}</Region>
                        <div>{item.value}{isPercent ? "%" : ""}</div>
                    </Content>
                </Button>
            ))}
            { info === null && (<>
              <img src={icon} width={"75px"} />
              <br></br>
              <p>Page under Construction</p>
            </>)}
            </ButtonContainer>
            {info !== null && <LogsFooter tw="mt-16"/>}
        </AnimationRevealPage>
      </>
        
    );
};