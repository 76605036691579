import React, { useState } from 'react';
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line

const HighlightedText = tw.span`bg-primary-500 text-gray-100 px-4 transform -skew-x-12 inline-block`;
const HeadingRow = tw.div`flex justify-between items-center sm:flex-col md:flex-row`;
const Heading = tw.h2`text-2xl sm:text-4xl font-black tracking-wide text-center text-gray-900`;

const Accordion = ({ items, customClasses }) => {
  const [activeIndex, setActiveIndex] = useState(null);

  const onTitleClick = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  return (
    <>
    { console.log(items) }
    <HeadingRow>
      <Heading>{<><HighlightedText>{items.name}</HighlightedText></>}</Heading>
    </HeadingRow>
    <div className={`flex flex-col ${customClasses?.container || ''}`}>
      { Object.keys(items).map((item, index) => (
        <div key={index} className="mb-2">
          { console.log(item) }
          <button
            onClick={() => onTitleClick(index)}
            className="py-2 px-4 w-full text-left bg-gray-200 hover:bg-gray-300 focus:outline-none focus:shadow-outline"
          >
            {item} - {index}
          </button>
          <div
            className={`p-4 bg-gray-100 ${index === activeIndex ? 'block' : 'hidden'}`}
          >
            {items[item]}
          </div>
        </div>
      ))}
    </div>

    
    </>
  );
};

export default Accordion;