import React, { useState, useEffect, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import supabase from "../../helpers/SupabaseClient"
import tw from 'twin.macro';
import styled from 'styled-components';
import { css } from 'styled-components/macro';
import { Container as ContainerBase } from "../../components/misc/Layouts";
import AnimationRevealPage from "../../helpers/AnimationRevealPage.js";
import { PrimaryButton } from "../../components/misc/Buttons.js";
import { ReactComponent as EditIcon } from 'feather-icons/dist/icons/edit.svg';
import { ReactComponent as DeleteIcon } from 'feather-icons/dist/icons/trash-2.svg';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const Container = tw(ContainerBase)`min-h-screen bg-primary-900 text-white font-medium flex justify-center -m-8`;
const Content = tw.div`max-w-screen-xl m-0 sm:mx-20 sm:my-16 bg-white text-gray-900 shadow sm:rounded-lg flex justify-center flex-1`;
const MainContainer = tw.div`lg:w-1/2 xl:w-7/12 p-6 sm:p-12`;
const Heading = tw.h1`text-2xl xl:text-3xl font-extrabold`;
const Form = tw.form`mt-8`;
const Input = tw.input`w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-5 first:mt-0`;
const Textarea = tw.textarea`w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-5 first:mt-0`;
const Select = tw.select`w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-5 first:mt-0`;
const Checkbox = tw.input`mr-2`;
const SubmitButton = tw(PrimaryButton)`mt-5 tracking-wide font-semibold bg-primary-500 text-gray-100 w-full py-4 rounded-lg hover:bg-primary-900 transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none`;
const Table = tw.table`w-full mt-8`;
const TableHead = tw.thead`bg-gray-200`;
const TableBody = tw.tbody``;
const TableRow = tw.tr``;
const TableHeader = tw.th`py-2 px-4 text-left`;
const TableCell = tw.td`py-2 px-4`;
const ActionButton = tw.button`p-2 rounded-full hover:bg-gray-200`;
const DropzoneContainer = styled.div`
  ${tw`mt-5 p-4 border-dashed border-2 border-gray-300 rounded-lg text-center cursor-pointer bg-gray-100`}
  &:hover {
    ${tw`bg-gray-200`}
  }
`;
const ToggleContainer = styled.div`
  ${tw`flex flex-row justify-center inline items-center mt-6 mb-6 w-full md:w-1/2 m-auto`}
`;
const ToggleButton = styled.button`
  ${tw`p-3 text-center text-primary-100 w-1/2 transform -skew-x-12 
    focus:bg-primary-500 focus:text-white border justify-between `}
`

const regions = ['CPT', 'NAM', 'RTB', 'PE', 'PMB', 'DBN', 'ZIM', 'JHB'];

export default () => {
  const [tools, setTools] = useState([]);
  const [currentTool, setCurrentTool] = useState({
    id: '',
    region: '',
    name: '',
    link: '',
    flyer: '',
    flyer_uploaded: '',
    hook: '',
    what: '',
    why: '',
    who: '',
    when: '',
    where: '',
    how: '',
    published: false
  });
  const [isEditing, setIsEditing] = useState(false);
  const [mode, setMode] = useState("Create");
  const [heading, setHeading] = useState("Create New EV Tool")

  useEffect(() => {
    // Fetch tools data from your API
    fetchTools();
  }, []);

  const fetchTools = async () => {
    // Replace with your actual API call
    try {
      const { data, error } = await supabase
        .from("tools")
        .select("*")
      setTools(data);
      console.log(data)
    } catch (error) {
      console.error(error)
    }
    window.scrollTo(0, 0);
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setCurrentTool(prev => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const handleDrop = useCallback(async (acceptedFiles) => {
    const file = acceptedFiles[0];
    // Handle file upload logic here, e.g., upload to a server and get a file ID/URL
    // Upload the file to Supabase
    const { data, error } = await supabase
      .storage
      .from('assets')
      .upload(`${file.name}`, file);
    
    if (error) {
      console.error('Error uploading file:', error.message);
      return;
    }

    // Extract the UUID from the uploaded file's path
    const uuid = data.path.split('/').pop(); // This assumes the file path contains the UUID as the file name

    setCurrentTool(prev => ({
      ...prev,
      flyer_uploaded: uuid // Store the UUID in the flyer field
    }));
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: handleDrop,
    accept: 'image/*'
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isEditing) {
      // Update existing tool
      await updateTool(currentTool);
    } else {
      // Create new tool
      await createTool(currentTool);
    }
    resetForm();
    fetchTools();
  };

  const createTool = async (tool) => {    
    setHeading("Create New EV Tool")
    try {
      const { data, error } = await supabase
        .from('tools')
        .insert([
          {
            region: tool.region,
            name: tool.name,
            link: tool.link,
            flyer_uploaded: tool.flyer_uploaded,
            hook: textToHtml(tool.hook),
            what: textToHtml(tool.what),
            why: textToHtml(tool.why),
            who: textToHtml(tool.who),
            when: textToHtml(tool.when),
            where: textToHtml(tool.where),
            how: textToHtml(tool.how),
            published: tool.published
          }
        ])
        .select()    
    } catch (error) {
      console.error(error)
    }
    console.log(`Added tool: ${tool.name}`);
    toast(`Added tool: ${tool.name}`);
  };

  const updateTool = async (tool) => {
    try {
      const { data, error } = await supabase
        .from('tools')
        .update([
          {
            region: tool.region,
            name: tool.name,
            link: tool.link,
            flyer_uploaded: tool.flyer_uploaded,
            hook: textToHtml(tool.hook),
            what: textToHtml(tool.what),
            why: textToHtml(tool.why),
            who: textToHtml(tool.who),
            when: textToHtml(tool.when),
            where: textToHtml(tool.where),
            how: textToHtml(tool.how),
            published: tool.published
          }
        ])
        .eq("id", tool.id)
        .select()
    } catch (error) {
      console.error(error)
    }
    console.log(`Updated tool: ${tool.name}`);
    toast(`Updated tool: ${tool.name}`);
  };

  const deleteTool = async (id) => {
    try {
      const { error } = await supabase
        .from('tools')
        .delete()
        .eq('id', id)        
      } catch (error) {
        console.error(error)
      }
    console.log(`Deleted tool with id: ${id}`);
    toast(`Deleted tool with id: ${id}`);
    fetchTools();
  };

  const editTool = (tool) => {
    setCurrentTool(tool);
    setIsEditing(true);
    setMode("Create");
    setHeading("Editing EV Tool")
    window.scrollTo(0, 0);
  };

  const resetForm = () => {
    setCurrentTool({
      id: '',
      region: '',
      name: '',
      link: '',
      flyer: '',
      flyer_uploaded: '',
      hook: '',
      what: '',
      why: '',
      who: '',
      when: '',
      where: '',
      how: '',
      published: false
    });
    setIsEditing(false);
    setHeading("Create New EV Tool")
  };

  function htmlToPlainText(html) {
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = html;
    return tempDiv.textContent || tempDiv.innerText || '';
  }

  function textToHtml(text) {
    console.log(text)
    if (text !== ''){
      const paragraphs = text.split('\n').filter(para => para.trim() !== ''); // Split by new line and filter out empty paragraphs
      const wrappedParagraphs = paragraphs.map(para => `<p>${para.trim()}</p>`);
      return wrappedParagraphs.join('');
    }
    return null
  }

  const toggleStyle = {backgroundColor: "#de3163", color: "white"}

  return (
    <AnimationRevealPage>
      <Container>
        <Content>
          <MainContainer>
            <ToggleContainer>
              <ToggleButton 
                style={(mode==="Create") ? toggleStyle : null} 
                onClick={() => {
                  setMode("Create")
                  setHeading("Create New EV Tool")
                  resetForm()
                }}
              >
                Create
              </ToggleButton>
              <ToggleButton 
                style={(mode==="Update") ? toggleStyle : null} 
                onClick={() => {
                  setMode("Update")
                  setHeading("Update EV Tools")
                }} 
              >
                Update
              </ToggleButton>
            </ToggleContainer>
            <Heading>{heading}</Heading>
            { mode === "Create" && (
              <Form onSubmit={handleSubmit}>
                <Select
                  name="region"
                  value={currentTool.region}
                  onChange={handleInputChange}
                  required
                >
                  <option value="">Select Region</option>
                  {regions.map(region => (
                    <option key={region} value={region}>{region}</option>
                  ))}
                </Select>
                <Input
                  type="text"
                  name="name"
                  placeholder="Name"
                  value={currentTool.name}
                  onChange={handleInputChange}
                  required
                />
                <Input
                  type="url"
                  name="link"
                  placeholder="Link"
                  value={currentTool.link}
                  onChange={handleInputChange}
                  required={false}
                />
                <DropzoneContainer {...getRootProps()}>
                  <input {...getInputProps()} />
                  {isDragActive ? (
                    <p>Drop the files here ...</p>
                  ) : (
                    <p>Drag 'n' drop flyer here, or click to select files</p>
                  )}
                </DropzoneContainer>
                {/* <p>Selected file: {currentTool.flyer ? currentTool.flyer : currentTool.flyer_uploaded}</p> */}
                <Input
                  type="text"
                  name="flyer"
                  placeholder="Flyer File Name"
                  value={currentTool.flyer || currentTool.flyer_uploaded}
                  onChange={handleInputChange}
                  disabled
                />
                <Textarea
                  name="hook"
                  placeholder="Hook"
                  value={htmlToPlainText(currentTool.hook)}
                  onChange={handleInputChange}
                />
                <Textarea
                  name="what"
                  placeholder="What"
                  value={htmlToPlainText(currentTool.what)}
                  onChange={handleInputChange}
                />
                <Textarea
                  name="why"
                  placeholder="Why"
                  value={htmlToPlainText(currentTool.why)}
                  onChange={handleInputChange}
                />
                <Textarea
                  name="who"
                  placeholder="Who"
                  value={htmlToPlainText(currentTool.who)}
                  onChange={handleInputChange}
                />
                <Textarea
                  name="when"
                  placeholder="When"
                  value={htmlToPlainText(currentTool.when)}
                  onChange={handleInputChange}
                />
                <Textarea
                  name="where"
                  placeholder="Where"
                  value={htmlToPlainText(currentTool.where)}
                  onChange={handleInputChange}
                />
                <Textarea
                  name="how"
                  placeholder="How"
                  value={htmlToPlainText(currentTool.how)}
                  onChange={handleInputChange}
                />
                <label>
                  <Checkbox
                    type="checkbox"
                    name="published"
                    checked={currentTool.published}
                    onChange={handleInputChange}
                  />
                  Publish
                </label>
                <SubmitButton type="submit">
                  {isEditing ? 'Update Tool' : 'Create Tool'}
                </SubmitButton>
              </Form>
            )}

            { mode === "Update" && (
              <Table>
                <TableHead>
                  <TableRow>
                    <TableHeader>Name</TableHeader>
                    <TableHeader>Region</TableHeader>
                    <TableHeader>Published</TableHeader>
                    <TableHeader>Actions</TableHeader>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tools.map(tool => (
                    <TableRow key={tool.id}>
                      <TableCell>{tool.name}</TableCell>
                      <TableCell>{tool.region}</TableCell>
                      <TableCell>{tool.published ? 'Yes' : 'No'}</TableCell>
                      <TableCell>
                        <ActionButton onClick={() => editTool(tool)}>
                          <EditIcon tw="w-4 h-4" />
                        </ActionButton>
                        <ActionButton onClick={() => deleteTool(tool.id)}>
                          <DeleteIcon tw="w-4 h-4" />
                        </ActionButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            )}
          </MainContainer>
        </Content>
      </Container>
    </AnimationRevealPage>
  );
}