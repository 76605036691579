import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { Container as ContainerBase } from "components/misc/Layouts.js";
import logo from "../../images/lra.svg";
import { useLocation } from "react-router-dom";

const Container = tw(ContainerBase)`bg-primary-900 text-white -mx-8 -mb-8 min-w-full sticky top-[100svh] bottom-0 mt-16`;
const Content = tw.div`max-w-screen-xl mx-auto py-6`;

const Row = tw.div`flex items-center justify-center flex-col`;

const CopyrightText = tw.p`text-center m-4 font-medium text-sm text-white`;

const LogsFooter = () => {
  const location = useLocation(); // Use useLocation hook to get the current location

  const content = () => {
    if (location.pathname === '/united-grad-competition/evangelism/second-registration') {
      return "This ranking shows the percentage of students who advanced to the intermediate level through the combined efforts of the Center Workers of duty, maintainers, networkers, and BBTs.";
    } 
    else if (location.pathname === '/united-grad-competition/evangelism/first-registration') {
      return "This ranking shows the percentage based on the monthly distribution of 800 fruits, divided proportionally among regions according to the number of congregation members in each region.";
    }
  };

  return (
    <Container>
      <Content>
        <Row>
          <CopyrightText>
            {content()} 
          </CopyrightText>
        </Row>
      </Content>
    </Container>
  );
};

export default LogsFooter; 