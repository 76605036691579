import React, { useEffect, useState } from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import { useParams } from 'react-router-dom';
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading } from "components/misc/Headings";
import Header, { NavLink, NavLinks, PrimaryLink as PrimaryLinkBase, LogoLink, NavToggle, DesktopNavLinks } from "../../components/headers/light.js";
import icon from "../../images/lra.svg"
import { PrimaryButton } from "../../components/misc/Buttons.js"
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons";
import { ReactComponent as ChevronLeftIcon } from "feather-icons/dist/icons/chevron-left.svg";
import { ReactComponent as ChevronRightIcon } from "feather-icons/dist/icons/chevron-right.svg";
import Watermark from "helpers/Watermark.js";
import supabase from "helpers/SupabaseClient.js";
import Accordion from "./component/Accordion.js";
import FAQImage from "components/faqs/FAQImage.js";

const Text = styled.div`
  ${tw`text-lg  text-gray-800`}
  p {
    ${tw`mt-2 leading-loose`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
  cursor: grab;
  user-select: none;
`;

const NavPaginationContainer = tw.div`flex justify-center items-center space-x-4`;
const ButtonContainer = tw.div`flex justify-end`
const LoadMoreButton = tw(PrimaryButton)``
const ControlButton = styled(PrimaryButtonBase)`
  ${tw`mt-4 sm:mt-0 first:ml-0 ml-6 rounded-full p-2`}
  svg {
    ${tw`w-6 h-6`}
  }
`;
const PrevButton = tw(ControlButton)``;
const NextButton = tw(ControlButton)``;
const StyledHeader = styled(Header)`
  ${tw`p-0 max-w-none w-full`}
  ${LogoLink} {
    ${tw`text-black hover:border-gray-300 hover:text-gray-300`}
  }
`;
const PrimaryLink = tw(PrimaryLinkBase)`rounded-full`
const ToolGrid = tw.div`grid grid-cols-2 md:grid-cols-5 gap-4 pt-2`;
const ToolName = tw.p`font-black tracking-wide text-center text-gray-900 pt-2`
const Image = styled.img`
  width: 100%;
  height: 250px;
  object-fit: contain;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease, box-shadow 0.3s ease;

  &:hover {
    transform: scale(1.09);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
  }
`;
const HeadingRow = tw.div`flex justify-between items-center sm:flex-col md:flex-row mb-2`;
const Heading = tw.h2`text-2xl sm:text-4xl font-black tracking-wide text-center text-gray-900`;
const HighlightedText = tw.span`bg-primary-500 text-gray-100 px-4 transform -skew-x-12 inline-block`;


export default ({ 
  headingText = "*",
  navLinks = [],
  imageSrc = "https://zegleedmcpswaxynxnta.supabase.co/storage/v1/object/public/assets/",
  region
}) => {

  const { url } = useParams();
  const [regionTools, setRegionTools] = useState([])
  const [currentTool, setCurrentTool] = useState([])
  const [currentFAQ, setCurrentFAQ] = useState([])
  const [toolIndex, setIndex] = useState(1)
  const [numTools, setNumTools] = useState(0);

  useEffect(() => {
    async function fetchTools() {
      try {
        const { data, error } = await supabase
          .from("tools")
          .select("*")
          .eq("region", url.toUpperCase())
          .eq("published", true)
        setRegionTools(data);
        setNumTools(data.length)
        console.log(data)
      } catch (error) {
        console.error(error)
      }
    }
    fetchTools()   
  }, [url])



  return (
    <AnimationRevealPage>
      <StyledHeader links={navLinks} logoIcon={icon} />
      <Container>
        <ContentWithPaddingXl>
            <Watermark></Watermark>
            <HeadingRow>
              <Heading>{<><HighlightedText>{url.toUpperCase()}</HighlightedText></>}</Heading>
            </HeadingRow>
            { regionTools.length > 0 && (
              <ToolGrid>
                {regionTools.map((tool, index) => (
                  <div key={index}>
                    <Image 
                      src={`${imageSrc}${tool.flyer_uploaded ? tool.flyer_uploaded : `${tool.flyer}.jpg`}`} 
                      onClick={() => {
                        window.location.href = `/noticeboard/${url}/${url}-${index}`
                      }}
                    />
                    <ToolName>{tool ? tool.name : ""}</ToolName>
                  </div>
                ))}
              </ToolGrid>
            )}
        </ContentWithPaddingXl>
      </Container>
    </AnimationRevealPage>
  );
};
