import supabase from "helpers/SupabaseClient";

export const getCurrentDB = async () => {
    var db = [] 
    db = await fetchDB();
    var fromToday = db.filter(item => new Date(item.date).getDate() == new Date().getDate());
    if (fromToday.length > 0) {
      fromToday.forEach(item => item.date = formatDate(item.date));
      return fromToday[0];
    } else {
      const liveDB = await fetchLiveData();
      console.log(liveDB)
      fromToday = liveDB.filter(item => item.title.split(" ")[0].substring(4, 6) == new Date().getDate());
      return fromToday[0];
    }
};

const fetchDB = async () => {
    try {
      const { data, error } = await supabase
        .from('daily_bread')
        .select()
      const db = data.filter(item => item.status === 'published');
      return db;
    } catch (error) {
      console.error('Error fetching data:', error);
    }
}

const fetchLiveData = async () => {
  try {
    const db_playlist = "PLOZKPu82muDyAGZNHEqpLjXEQ6QyfbrSe";
    const playlistApi = `https://www.googleapis.com/youtube/v3/playlistItems?part=snippet&maxResults=1&playlistId=${db_playlist}&key=${process.env.REACT_APP_YOUTUBE_API_KEY}`
    var liveDB = [];
    await fetch(playlistApi)
      .then(response => response.json())
      .then(data => {
        if (data?.items) {
          if (data?.items.length > 0) {
            const content = data.items[0].snippet
            liveDB = [
              {
                "title": content.title,
                "video_key": content.resourceId.videoId,
                "date": content.title.split(" ")[0]
              }
            ]
          } else {
                liveDB = [
                {
                    "title": "",
                    "video_key": "",
                    "date": ""
                }
            ];
          }
        }
      })
      .catch(error => {
        console.error(error)
      })
      return liveDB;
  } catch (error) {
    console.error('Error fetching data:', error);
  }
}

export const formatDate = (datetime) => {
    const parts = datetime.split("-");
    return `${parseInt(parts[0]) - 1983}${parts[1]}${parts[2]}`
};
