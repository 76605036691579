import React, { useEffect, useState } from "react";
import InputMask from "react-input-mask";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container as ContainerBase } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import logo from "images/lra_pink.svg";
import { ReactComponent as CloseIcon } from "feather-icons/dist/icons/x.svg";
import { ReactComponent as SignUpIcon } from "feather-icons/dist/icons/user-plus.svg";
import { ReactComponent as LoginIcon } from "feather-icons/dist/icons/log-in.svg";
import ReactModalAdapter from "../helpers/ReactModalAdapter.js";
import { PrimaryButton } from "../components/misc/Buttons.js"
import { getUserData, Register } from "helpers/Auth.js";

const Container = tw(ContainerBase)`min-h-screen bg-primary-900 text-white font-medium flex justify-center -m-8`;
const Content = tw.div`max-w-screen-xl m-0 sm:mx-20 sm:my-16 bg-white text-gray-900 shadow sm:rounded-lg flex justify-center flex-1`;
const MainContainer = tw.div`lg:w-1/2 xl:w-5/12 p-6 sm:p-12`;
const LogoLink = tw.a``;
const LogoImage = tw.img`h-12 mx-auto`;
const MainContent = tw.div`mt-12 flex flex-col items-center`;
const Heading = tw.h2`text-2xl sm:text-4xl font-black tracking-wide text-center text-gray-900`;
const FormContainer = tw.div`w-full flex-1 mt-8`;
const DividerTextContainer = tw.div`my-12 border-b text-center relative`;
const DividerText = tw.div`leading-none px-2 inline-block text-sm text-gray-600 tracking-wide font-medium bg-white transform -translate-y-1/2 absolute inset-x-0 top-1/2 bg-transparent`;
const Form = tw.form`mx-auto max-w-xs`;
const Input = tw.input`w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-5 first:mt-0`;
const Select = tw.select`w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-5 first:mt-0`;
const SubmitButton = styled.button`
  ${tw`mt-5 tracking-wide font-semibold bg-primary-500 text-gray-100 w-full py-4 rounded-lg hover:bg-primary-900 transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none`}
  .icon {
    ${tw`w-6 h-6 -ml-2`}
  }
  .text {
    ${tw`ml-3`}
  }
`;
const IllustrationContainer = styled.div`
  ${props => `background-image: url("${props.imageSrc}");`}
  ${tw`sm:rounded-r-lg flex-1 bg-purple-100 text-center hidden lg:flex justify-center bg-contain bg-center bg-cover bg-no-repeat`}
`;
const IllustrationImage = styled.div`
  ${props => `background-image: url("${props.imageSrc}");`}
  ${tw`m-12 xl:m-16 w-full max-w-lg bg-contain bg-center bg-no-repeat`}
`;
const HighlightedText = tw.span`bg-primary-500 text-gray-100 px-4 transform -skew-x-12 inline-block`;
const StyledModal = styled(ReactModalAdapter)`
  &.mainHeroModal__overlay {
    ${tw`fixed inset-0 z-50`}
  }
  &.mainHeroModal__content {
    ${tw`xl:mx-auto m-4 sm:m-16 max-w-screen-xl absolute inset-0 flex justify-center items-center rounded-lg bg-gray-200 outline-none`}
  }
  .content {
    ${tw`w-full lg:p-16`}
  }
`;
const CloseModalButton = tw.button`absolute top-0 right-0 mt-8 mr-8 hocus:text-primary-500`;

export default ({
  logoLinkUrl = "/home",
  headingText = "LightRainAir",
  submitButtonText = "Register",
  SubmitButtonIcon = SignUpIcon,
  tosUrl = "https://lightrainair.co.za/static/23102023_terms_of_use-184e4afbec2fcbac36b5a8ed19b12bab.pdf",
  privacyPolicyUrl = "https://lightrainair.co.za/static/23102023_privacy_policy-92f513a554437ab715fe6ddfc33f1506.pdf",
  signInUrl = "/login",
}) => {

  const [id, setId] = useState('');
  const [name, setName] = useState('');
  const [lastname, setLastname] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isLengthValid, setLengthValid] = useState(false);
  const [hasNumber, setHasNumber] = useState(false);
  const [hasUppercase, setHasUppercase] = useState(false);
  const [hasLowercase, setHasLowercase] = useState(false);
  const [showValidation, setShowValidation] = useState(false);
  const [passwordsMatch, setPasswordsMatch] = useState(true);
  const [email, setEmail] = useState('');
  const [confirmEmail, setConfirmEmail] = useState('');
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [emailsMatch, setEmailsMatch] = useState(true);
  const [showEmailValidation, setShowEmailValidation] = useState(false);
  const [userData, setUserdata] = useState({});
  const [group, setGroup] = useState('');
  const [region, setRegion] = useState('');
  const [result, setResult] = useState("");
  const [message, setMessage] = useState("");
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [registering, setRegistering] = useState(false);

  const toggleModal = () => setModalIsOpen(!modalIsOpen);

  const handlePasswordChange = (event) => {
    const newPassword = event.target.value;
    setPassword(newPassword);
    setLengthValid(newPassword.length >= 8);
    setHasNumber(/\d/.test(newPassword));
    setHasUppercase(/[A-Z]/.test(newPassword));
    setHasLowercase(/[a-z]/.test(newPassword));
    setPasswordsMatch(newPassword === confirmPassword);
  };

  const handleConfirmPasswordChange = (event) => {
    const newConfirmPassword = event.target.value;
    setConfirmPassword(newConfirmPassword);
    setPasswordsMatch(password === newConfirmPassword);
  };

  const handlePasswordFocus = () => {
    setShowValidation(true);
  };

  const handleEmailChange = (event) => {
    const newEmail = event.target.value;
    setEmail(newEmail);
    setIsValidEmail(validateEmail(newEmail));
    setEmailsMatch(newEmail === confirmEmail);
  };

  const handleConfirmEmailChange = (event) => {
    const newConfirmEmail = event.target.value;
    setConfirmEmail(newConfirmEmail);
    setEmailsMatch(email === newConfirmEmail);
  };

  const handleEmailFocus = () => {
    setShowEmailValidation(true);
  };

  const validateEmail = (email) => {
    const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zAZ]{2,}))$/;
    return regex.test(email);
  };

  const validateID = (scj_id) => {
    const regex = /^\d{8}-\d{5}$/;
    return regex.test(scj_id);
  }

  const populateFields = (data) => {
    setName(data.name);
    const region = document.getElementById("region");
    region.value = data.region;
    setRegion(data.region);
    const group = document.getElementById("group");
    group.value = data.group;
    setGroup(data.group);
  }

  const pullData = async (event) => {
    const scj_id = event.target.value;
    setId(scj_id);
    if (validateID(scj_id)) {
      try {
        const userdata = await getUserData(scj_id);
        console.log(userdata)
        const id_field = document.getElementById("reg_id");
        if (userdata === undefined) {
          id_field.value = "";
          id_field.focus = false;
          const result = {
            result: "Registering Error",
            message: `The Registration Number '${scj_id}' is Invalid.\n\nIf you believe this is an error, please inform your structure.`
          }
          setResult(result.result);
          setMessage(result.message);
          setModalIsOpen(!modalIsOpen);
        } else {
          if (userdata.Access === false){
            id_field.value = "";
            id_field.focus = false;
            const result = {
              result: "Technical Error",
              message: `The Registration Number '${scj_id}' is experiencing a Technical Error.\n\nPlease inform your structure for assistance.`
            }
            setResult(result.result);
            setMessage(result.message);
            setModalIsOpen(!modalIsOpen);
            id_field.value = "";
          } else {
            populateFields(userdata);
          }
        }
      } catch (error) { }
    }
  }

  const handleRegister = async (event) => {
    setRegistering(true);
    event.preventDefault();
    var Userdata = {
      scj_id: id,
      group: group,
      region: region,
      email: email.toLowerCase(),
      lastname: lastname,
      firstname: name,
      password: password
    };
    console.log(Userdata);
    var result = await Register(Userdata);
    setRegistering(false);
    setUserdata(Userdata);
    setResult(result[0].result);
    setMessage(result[0].message);
    if (result.length > 0) {
      console.log("toggling Modal");
      setModalIsOpen(!modalIsOpen);
    }
  }

  const isFormValid = isValidEmail && emailsMatch && isLengthValid && hasNumber && hasLowercase && hasUppercase; 

  return (
  <AnimationRevealPage>
    <Container>
      <Content>
        <MainContainer>
          <LogoLink href={logoLinkUrl}>
            <LogoImage src={logo} />
          </LogoLink>
          <MainContent>
            <Heading>{<>Register to <HighlightedText>{headingText}</HighlightedText></>}</Heading>
            <FormContainer>
              <Form onSubmit={(e) => handleRegister(e)}>
                <InputMask mask={"99999999-99999"} type="text" value={id} onChange={pullData} maskChar={null} maskPlaceholder={null}>
                  <Input id="reg_id" type="scj_id" pattern="^\d{8}-\d{5}$" placeholder="ID Number (00400314-12345)" value={id} onChange={pullData} required/>
                </InputMask>
                <Input type="text" placeholder="Full Name" value={name} onChange={(e) => setName(e.target.value)} required readOnly/>
                <Input 
                  type="email" 
                  placeholder="Email"
                  value={email}
                  onChange={(e) => handleEmailChange(e)}
                  onFocus={(e) => handleEmailFocus(e)}
                  required
                />
                { showEmailValidation && (
                  <div>
                    <span style={{ color: isValidEmail ? 'green' : 'red' }}>{isValidEmail ? 'Valid Email Format' : 'Invalid Email Format'}</span>
                  </div>
                )}
                <Input 
                  type="email" 
                  placeholder="Confirm Email"
                  value={confirmEmail}
                  onChange={(e) => handleConfirmEmailChange(e)}
                  required
                />
                { showEmailValidation && (
                  <div>
                    <span style={{ color: emailsMatch ? 'green' : 'red' }}>
                      { emailsMatch ? "Emails Match" : "Emails Do Not Match"}
                    </span>
                  </div>
                )}
                <Input id="region" name="Region" placeholder="Region" required readOnly />
                <Input id="group" name="group" placeholder="Group" required readOnly/>
                <Input 
                  type="password" 
                  placeholder="Password"
                  value={password}
                  onChange={(e) => handlePasswordChange(e)} 
                  onFocus={(e) => handlePasswordFocus(e)}
                  required
                />
                { showValidation && (
                  <div>
                    <span style={{ color: isLengthValid ? 'green' : 'red' }}> At least 8 characters</span>
                    <br />
                    <span style={{ color: hasNumber ? 'green' : 'red' }}> At least 1 number</span>
                    <br />
                    <span style={{ color: hasUppercase ? 'green' : 'red' }}> At least 1 uppercase letter</span>
                    <br />
                    <span style={{ color: hasLowercase ? 'green' : 'red' }}> At least 1 lowercase letter</span>
                  </div>
                )}
                <Input 
                  type="password" 
                  placeholder="Confirm Password"
                  value={confirmPassword}
                  onChange={(e) => handleConfirmPasswordChange(e)} 
                  required
                />
                { showValidation && (
                  <div>
                    <span style={{ color: passwordsMatch ? 'green' : 'red' }}>
                       {passwordsMatch ? "Passwords Match" : "Passwords Do Not Match"}
                    </span>
                  </div>
                )}
                <SubmitButton type="submit" disabled={!isFormValid}>
                  <SubmitButtonIcon className="icon" />
                  <span className="text">{registering == true ? "Registering..." : submitButtonText}</span>
                </SubmitButton>
                <p tw="mt-6 text-xs text-gray-600 text-center">
                  I agree to abide by LightRainAir's{" "}
                  <a href={tosUrl} tw="border-b border-gray-500 border-dotted">
                    Terms of Service
                  </a>{" "}
                  and its{" "}
                  <a href={privacyPolicyUrl} tw="border-b border-gray-500 border-dotted">
                    Privacy Policy
                  </a>
                </p>
              </Form>
                <DividerTextContainer></DividerTextContainer>
                <p tw="mt-8 text-gray-600 text-center">
                  Already have an account?{" "}
                  <a href={signInUrl}>
                    <SubmitButton>
                      <LoginIcon className="icon" />
                      <span className="text">{"Sign In"}</span>
                    </SubmitButton>
                  </a>
                </p>
            </FormContainer>
              <StyledModal
                closeTimeoutMS={300}
                className="mainHeroModal"
                isOpen={modalIsOpen}
                onRequestClose={toggleModal}
                shouldCloseOnOverlayClick={true}
              >
                <CloseModalButton onClick={toggleModal}>
                  <CloseIcon tw="w-6 h-6" />
                </CloseModalButton>
                <MainContent>
                  <LogoLink href={logoLinkUrl}>
                    <LogoImage src={logo} />
                  </LogoLink>
                  <Heading>{`${result}`}</Heading>
                  <br/><br/>
                  <p tw="text-center">{message}</p>
                  <br/><br/>
                  { (result !== "Success") ?
                    <p tw="text-center">
                      <PrimaryButton onClick={toggleModal}>Back</PrimaryButton>
                    </p> : ""
                  }
                </MainContent>
              </StyledModal>
          </MainContent>
        </MainContainer>
      </Content>
    </Container>
  </AnimationRevealPage>
)};
