import React, { useEffect, useState } from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import { useParams } from 'react-router-dom';
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading } from "components/misc/Headings";
import Header, { NavLink, NavLinks, PrimaryLink as PrimaryLinkBase, LogoLink, NavToggle, DesktopNavLinks } from "../../components/headers/light.js";
import icon from "../../images/lra.svg"
import { PrimaryButton } from "../../components/misc/Buttons.js"
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons";
import { ReactComponent as ChevronLeftIcon } from "feather-icons/dist/icons/chevron-left.svg";
import { ReactComponent as ChevronRightIcon } from "feather-icons/dist/icons/chevron-right.svg";
import Watermark from "helpers/Watermark.js";
import supabase from "helpers/SupabaseClient.js";
import Accordion from "./component/Accordion.js";
import FAQImage from "components/faqs/FAQImage.js";

const Text = styled.div`
  ${tw`text-lg  text-gray-800`}
  p {
    ${tw`mt-2 leading-loose`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
  cursor: grab;
  user-select: none;
`;

const NavPaginationContainer = tw.div`flex justify-center items-center space-x-4`;
const ButtonContainer = tw.div`flex justify-end`
const LoadMoreButton = tw(PrimaryButton)``
const ControlButton = styled(PrimaryButtonBase)`
  ${tw`mt-4 sm:mt-0 first:ml-0 ml-6 rounded-full p-2`}
  svg {
    ${tw`w-6 h-6`}
  }
`;
const PrevButton = tw(ControlButton)``;
const NextButton = tw(ControlButton)``;
const StyledHeader = styled(Header)`
  ${tw`p-0 max-w-none w-full`}
  ${LogoLink} {
    ${tw`text-black hover:border-gray-300 hover:text-gray-300`}
  }
`;
const PrimaryLink = tw(PrimaryLinkBase)`rounded-full`


export default ({ 
  headingText = "*",
  navLinks = [],
  region
}) => {

  const { url } = useParams();
  const [regionTools, setRegionTools] = useState([])
  const [currentTool, setCurrentTool] = useState([])
  const [currentFAQ, setCurrentFAQ] = useState([])
  const [toolIndex, setIndex] = useState(Number(url.split("-")[1])+1)
  const [numTools, setNumTools] = useState(0);

  useEffect(() => {
    async function fetchTools() {
      try {
        const { data, error } = await supabase
          .from("tools")
          .select("*")
          .eq("region", url.split("-")[0].toUpperCase())
          .eq("published", true)
        setRegionTools(data);
        setNumTools(data.length)
        console.log(data)
      } catch (error) {
        console.error(error)
      }
    }
    fetchTools()
   
  }, [])

  useEffect(() => {
    const result = updateTool(toolIndex);
    if (result) {
      setCurrentFAQ(result);
    }
  }, [regionTools, toolIndex]);

  function updateTool(toolIndex){
    console.log(`Tools Count: ${regionTools.length}`)
    console.log(`Tool Index: ${toolIndex}`)
    const selected = regionTools[toolIndex-1];
    console.log(`selected: ${selected}`)
    setCurrentTool(selected)
    var result = [];
    if (selected !== undefined) {
      const excludeList = ["id", "region", "flyer", "flyer_uploaded", "name", "hook", "link", "published"]
      var finalStr = "<b>5W And 1H:</b><br>\n";
      for (const tool of Object.keys(selected)){
        if (!excludeList.includes(tool) && selected[tool] !== null) {
          finalStr += `<b>| ${tool.charAt(0).toUpperCase() + tool.slice(1)}:</b>\n${selected[tool]}`
        }
      }
      selected["hook"] !== null && (finalStr += `<br><b>Hook:</b><br>${selected["hook"]}</br>`)
      
      console.log(finalStr);
      result.push({
        question: "Tool Information",
        answer: finalStr
      })
      console.log(`result: ${result}`)
      return result
    } else {
      return null
    }
  }

  function goToNextTool() {
    setIndex((prevIndexNumber) => prevIndexNumber + 1);
    if (document.body.scrollTop !== 0){
      document.body.scrollTop = 0;
    } else {
      window.scrollTo({ top: 0, behavior: 'smooth'});
    }
  }

  function goToPreviousTool() {
    setIndex((prevIndexNumber) => prevIndexNumber - 1);
    if (document.body.scrollTop !== 0){
      document.body.scrollTop = 0;
    } else {
      window.scrollTo({ top: 0, behavior: 'smooth'});
    }
  }

  return (
    <AnimationRevealPage>
      <StyledHeader links={navLinks} logoIcon={icon} />
      <Container>
        <ContentWithPaddingXl>
            <Watermark></Watermark>
            {console.log(regionTools)}
            { regionTools.length > 0 && <FAQImage faqs={currentFAQ} tool={currentTool} /> }
            <NavPaginationContainer>
              <PrevButton onClick={goToPreviousTool} disabled={toolIndex <= 1}>
                <ChevronLeftIcon tw="h-10 w-10" aria-hidden="true" />
              </PrevButton>
              <span> {toolIndex} / {numTools}</span>
              <NextButton onClick={goToNextTool} disabled={toolIndex >= numTools}>
                <ChevronRightIcon tw="h-10 w-10" aria-hidden="true" />
              </NextButton>
            </NavPaginationContainer>
        </ContentWithPaddingXl>
      </Container>
    </AnimationRevealPage>
  );
};
