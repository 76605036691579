import React, { useState, useEffect } from "react";
import InputMask from "react-input-mask";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container as ContainerBase } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import {css} from "styled-components/macro"; //eslint-disable-line
import logo from "images/lra_pink.svg";
import { ReactComponent as LogoutIcon } from "feather-icons/dist/icons/log-out.svg";
import { ReactComponent as HomeIcon } from "feather-icons/dist/icons/home.svg";
import { ReactComponent as FinanceIcon } from "feather-icons/dist/icons/bar-chart-2.svg";
import { ReactComponent as inboxIcon } from "feather-icons/dist/icons/bar-chart-2.svg";
import { SignOut, Home, Finance,HeavenlyInbox, getUserInfo, useAuth } from "helpers/Auth";
import ReactModalAdapter from "../helpers/ReactModalAdapter.js";

const Container = tw(ContainerBase)`min-h-screen bg-primary-900 text-white font-medium flex justify-center -m-8`;
const Content = tw.div`max-w-screen-xl m-0 sm:mx-20 sm:my-16 bg-white text-gray-900 shadow sm:rounded-lg flex justify-center flex-1`;
const MainContainer = tw.div`lg:w-1/2 xl:w-5/12 p-6 sm:p-12`;
const LogoLink = tw.a``;
const LogoImage = tw.img`h-12 mx-auto`;
const MainContent = tw.div`mt-2 flex flex-col items-center`;
const Heading = tw.h1`text-2xl xl:text-3xl font-extrabold text-center`;
const FormContainer = tw.div`w-full flex-1 mt-4`;

const SocialButtonsContainer = tw.div`flex flex-col items-center`;
const SocialButton = styled.a`
  ${tw`w-full max-w-xs font-semibold rounded-lg py-3 border text-gray-900 bg-gray-100 hocus:bg-gray-200 hocus:border-gray-400 flex items-center justify-center transition-all duration-300 focus:outline-none focus:shadow-outline text-sm mt-5 first:mt-0`}
  .iconContainer {
    ${tw`bg-white p-2 rounded-full`}
  }
  .icon {
    ${tw`w-4`}
  }
  .text {
    ${tw`ml-4`}
  }
`;

const DividerTextContainer = tw.div`my-12 border-b text-center relative`;
const DividerText = tw.div`leading-none px-2 inline-block text-sm text-gray-600 tracking-wide font-medium bg-white transform -translate-y-1/2 absolute inset-x-0 top-1/2 bg-transparent`;

const Form = tw.form`mx-auto max-w-xs`;
const Input = tw.input`w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-5 first:mt-0`;
const SubmitButton = styled.button`
  ${tw`mt-5 tracking-wide font-semibold bg-primary-500 text-gray-100 w-full py-4 rounded-lg hover:bg-primary-900 transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none`}
  .icon {
    ${tw`w-6 h-6 -ml-2`}
  }
  .text {
    ${tw`ml-3`}
  }
`;
const IllustrationContainer = tw.div`sm:rounded-r-lg flex-1 bg-purple-100 text-center hidden lg:flex justify-center`;
const IllustrationImage = styled.div`
  ${props => `background-image: url("${props.imageSrc}");`}
  ${tw`m-12 xl:m-16 w-full max-w-sm bg-contain bg-center bg-no-repeat`}
`;
const HighlightedText = tw.span`bg-primary-500 text-gray-100 px-4 transform -skew-x-12 inline-block`;
const StyledModal = styled(ReactModalAdapter)`
  &.mainHeroModal__overlay {
    ${tw`fixed inset-0 z-50`}
  }
  &.mainHeroModal__content {
    ${tw`xl:mx-auto m-4 sm:m-16 max-w-screen-xl absolute inset-0 flex justify-center items-center rounded-lg bg-gray-200 outline-none`}
  }
  .content {
    ${tw`w-full lg:p-16`}
  }
`;
const CloseModalButton = tw.button`absolute top-0 right-0 mt-8 mr-8 hocus:text-primary-500`;
const Label = tw.label`text-sm text-gray-900 text-center`;
const ValueLabel = tw.label`text-lg text-black font-bold text-center`;

export default ({
  logoLinkUrl = "#",
}) => {
  const { user } = useAuth();

  useEffect(() => {
    pullData();
  }, []);

  const pullData = async () => {
    const userdata = await getUserInfo(user.email);
    setUserdata(userdata);
  }
  const [userData, setUserdata] = useState([]);

  return(
    <AnimationRevealPage>
      <Container>
        <Content>
          <MainContainer>
            <LogoLink href={logoLinkUrl}>
              <LogoImage src={logo} />
            </LogoLink>
            <MainContent>
              <Heading><HighlightedText>{ "Member Profile" }</HighlightedText></Heading>
              <FormContainer tw="text-center">
                <Label>
                  Registration Number:
                </Label><br/>
                <ValueLabel>
                  { userData.registration_number }
                </ValueLabel><br/>
                <Label>
                  Name:
                </Label><br/>
                <ValueLabel>
                  { userData.name }
                </ValueLabel><br/>
                <Label>
                  Email:
                </Label><br/>
                <ValueLabel>
                  { userData.email }
                </ValueLabel><br/>
                <Label>
                  Region:
                </Label><br/>
                <ValueLabel>
                  { userData.region }
                </ValueLabel><br/>
                <Label>
                  Group:
                </Label><br/>
                <ValueLabel>
                  { userData.group }
                </ValueLabel><br/>
                <Label>
                  Registration:
                </Label><br/>
                <ValueLabel>
                  { userData.registration }
                </ValueLabel><br/>
                <SubmitButton onClick={Home}>
                  <HomeIcon className="icon" />
                  <span className="text">{"Home"}</span>
                </SubmitButton>
                <SubmitButton onClick={Finance}>
                  <FinanceIcon className="icon" />
                  <span className="text">{"Tithes and Offerings"}</span>
                </SubmitButton>
                {/* <SubmitButton onClick={HeavenlyInbox}>
                  <inboxIcon className="icon" />
                  <span className="text">{"Heavenly Inbox"}</span>
                </SubmitButton> */}
                <SubmitButton onClick={SignOut}>
                  <LogoutIcon className="icon" />
                  <span className="text">{"Sign Out"}</span>
                </SubmitButton>
                <p tw="mt-8 text-sm text-gray-600 text-center">
                  Please contact structure if there is any information that needs to be updated.
                </p>
              </FormContainer>
            </MainContent>
          </MainContainer>
        </Content>
      </Container>
    </AnimationRevealPage>
  );
}
