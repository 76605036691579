import styled from 'styled-components';
import tw from 'twin.macro'; // Import the tw utility class from twin.macro

const Heading = styled.div`
  ${tw`p-0 pl-0 w-[80%]  bg-primary-500 text-gray-100 px-4 transform -skew-x-12 inline-flex text-xl md:text-2xl font-bold m-3 md:m-8 text-left relative`}
`;
//${tw`flex items-center text-2xl md:text-4xl font-bold m-3 md:m-20 text-left`}

const SquareContainer = styled.div`
    ${tw`shadow inline-block p-2 text-2xl left-0 border-2 border-primary-500 font-bold bg-white text-primary-500`}
    box-shadow: 0 0 0 3px #fff, 0 0 0 5px #ed64a6;
`;

const NumberText = styled.p`
  ${tw`text-4xl font-bold px-2`}
`;

const Content = styled.div`
    ${tw`flex justify-between items-center w-full pl-6 font-bold w-[90%]`}
`


const HeadingWithNumber = ({ heading, number }) => {
  return (
    <Heading>
      <SquareContainer>
        <NumberText>{number}</NumberText>
      </SquareContainer>
      <Content>{heading}</Content>
    </Heading>
  );
};

export default HeadingWithNumber;