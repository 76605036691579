import React, { useEffect, useState } from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import Footer from "components/footers/MiniCenteredFooter.js";
import { SectionHeading } from "components/misc/Headings";
import Header, { NavLink, NavLinks, PrimaryLink as PrimaryLinkBase, LogoLink, NavToggle, DesktopNavLinks } from "../../../components/headers/light.js";
import icon from "../../../images/lra.svg"
import { PrimaryButton } from "../../../components/misc/Buttons.js"
import Watermark from "helpers/Watermark.js";
import { useParams } from 'react-router-dom';
import supabase from "helpers/SupabaseClient";
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';

const HeadingRow = tw.div`flex justify-between items-center sm:flex-col md:flex-row`;
const Heading = tw.h2`text-2xl sm:text-4xl font-black tracking-wide text-center text-gray-900`;
const Text = styled.div`
  ${tw`text-lg  text-gray-800`}
  p {
    ${tw`mt-2 leading-loose`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;

const StyledHeader = styled(Header)`
  ${tw`p-0 max-w-none w-full`}
  ${LogoLink} {
    ${tw`text-black hover:border-gray-300 hover:text-gray-300`}
  }
`;
const PrimaryLink = tw(PrimaryLinkBase)`rounded-full`
const HighlightedText = tw.span`bg-primary-500 text-gray-100 px-4 transform -skew-x-12 inline-block`;
const ButtonContainer = tw.div`flex justify-end`
const LoadMoreButton = tw(PrimaryButton)``

export default ({ 
  navLinks = [],
}) => {
  const { url } = useParams();
  const [material, setMaterial] = useState([]);
  const [education, setEducation] = useState("");
  const [illustrations, setIllustrations] = useState([]);

  useEffect(() => {
    async function fetchData() {
      const table = url;
      console.log(table);
      try {
        const { data, error } = await supabase
          .from(table.replaceAll("-", "_"))
          .select('*');
        console.log(data);
        const words = data.filter(item => item.published_at !== null);

        console.log(words);
        setMaterial(words);

      } catch (err) {
        console.error('Error fetching data:', err);
      }
    }
    fetchData();
  }, []);

  var title, content;
  const formattedHeading = material.length > 0 ? (() => {
      title = material[0].title;
      content = material[0].content;
      return '';
    })() : '';


  return (
    <AnimationRevealPage>
      <StyledHeader links={navLinks} logoIcon={icon} />
{/*      <ButtonContainer>
        <LoadMoreButton onClick={(e) => window.history.back()}>Back</LoadMoreButton>
      </ButtonContainer> */}
      <Container>
        <ContentWithPaddingXl>
          <HeadingRow>
            <Heading>{<><HighlightedText>{title}</HighlightedText></>}</Heading>
          </HeadingRow>
          <Text>
            <Watermark></Watermark>
            <ReactMarkdown rehypePlugins={[rehypeRaw]}>
              {content}
            </ReactMarkdown>
          </Text>
        </ContentWithPaddingXl>
      </Container>
    </AnimationRevealPage>
  );
};
