import { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth, checkWodAccess, hasSession } from './Auth.js';

const ProtectedRoute = ({ children, page }) => {
  const { user } = useAuth();
  const [access, setAccess] = useState(null);

  useEffect(() => {
    if (user && page) {
      // TODO: WOD access based on page access... Removing access check for now...
      // checkWodAccess(user.email)
      //   .then((result) => {
      //     console.log(result);
      //     console.log(user);
      //     setAccess(result);
      //   })
      //   .catch((error) => {
      //     console.error('Error fetching access:', error);
      //     // Handle error if needed
      //   });
    
        setAccess({ "WOD_page_access": [
          'Administration and General Affairs', 'Ministry of Theology', 'Internal Affairs'
        ]})
    }
  }, [user, page]);

  if (!user && page !== 'home') {
    // user is not authenticated
    return <Navigate to="/" />;
  }

  if (!page) {
    return <>{children}</>;
  }

  if (access === null) {
    // Access data is still being fetched
    return null; // or loading indicator
  }

  console.log(access);

  if (page === 'home') {
    console.log('home');
    console.log(hasSession());
    if (hasSession()) {
      return <Navigate to="/home" />;
    }
    console.log('no session');
    return <>{children}</>;
  } else if (page === 'admin' && access.WOD_page_access.includes('Administration and General Affairs')){
    console.log('admin');
    return <>{children}</>;
  } else if (page === 'mot' && access.WOD_page_access.includes('Ministry of Theology')) {
    console.log('mot');
    console.log(access.mot);
    return <>{children}</>;
  } else if (page === 'ia' && access.WOD_page_access.includes('Administration and General Affairs')) {
    console.log('affairs');
    return <>{children}</>;
  }

  console.log('Go home');
  return <Navigate to="/home" />;
};

export default ProtectedRoute;
